import React from 'react';
import {
  ActivityTypeConfigMap,
  RemediationGoalType
} from './remediation-home.models';
import { RemediationActivityTypeDto } from '../../apis/florence-facade/florence-facade.dtos';

type RemediationCompleteBoxProps = {
  activityType: RemediationActivityTypeDto;
}

const RemediationCompleteBox = (props: RemediationCompleteBoxProps) => {

  const {
    activityType
  } = props;

  if (!activityType) {
    return null;
  }

  const isTopicGoal = ActivityTypeConfigMap[activityType].goalType === RemediationGoalType.TAXON;

  return (
    <div className="c-ssa-remediation-complete-box">
      {
        !isTopicGoal && (
          <h3 className="u-els-margin-bottom-1o2">
            Great work! You’ve completed all {ActivityTypeConfigMap[activityType].title.toLowerCase()} requirements.
          </h3>
        )
      }

      {
        isTopicGoal && (
          <h3 className="u-els-margin-bottom-1o2">
            Great work! You’ve completed all {ActivityTypeConfigMap[activityType].title.toLowerCase()} requirements in this topic.
          </h3>
        )
      }

      {
        activityType === RemediationActivityTypeDto.REVIEW_BY_TAXON && (
          <div>
            You can review any additional readings and videos as optional.
            But any additional work in this section will not count towards your remaining requirements.
          </div>
        )
      }

      {
        activityType === RemediationActivityTypeDto.APPLY_BY_CONTENT_TYPE && (
          <div>
            You can review or reattempt any of your completed Case Studies if you&apos;d like but this is not required.
          </div>
        )
      }

      {
        activityType === RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE && (
          <div>
            You can review or reattempt your quiz questions if you&apos;d like but this is not required.
          </div>
        )
      }

    </div>
  );
};

export default RemediationCompleteBox;
