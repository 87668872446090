import {
  defineMessages,
  MessageDescriptor
} from 'react-intl';

export enum LANGUAGE_KEY {
  ACTION = 'ACTION',
  ACTIONS = 'ACTIONS',
  ACTIONS_RESULTS_MESSAGE = 'ACTIONS_RESULTS_MESSAGE',
  ADD = 'ADD',
  ADD_A_FOLDER = 'ADD_A_FOLDER',
  ADD_A_RESOURCE = 'ADD_A_RESOURCE',
  ADD_DATE_RANGE_TO_EACH_UNIT = 'ADD_DATE_RANGE_TO_EACH_UNIT',
  ADD_RESOURCES = 'ADD_RESOURCES',
  ADD_MORE_RESOURCES = 'ADD_MORE_RESOURCES',
  ADD_SINGLE_RESOURCE = 'ADD_SINGLE_RESOURCE',
  ADDITIONAL_LESSONS_COMING_SOON = 'ADDITIONAL_LESSONS_COMING_SOON',
  ALL_ATTEMPTS_TITLE = 'ALL_ATTEMPTS_TITLE',
  ALL_ITEMS = 'ALL_ITEMS',
  ALL_VIEWS = 'ALL_VIEWS',
  ALREADY_UNASSIGNED_SKIPPED = 'ALREADY_UNASSIGNED_SKIPPED',
  APPLICATION = 'APPLICATION',
  ARE_YOU_SURE = 'ARE_YOU_SURE',
  AREA = 'AREA',
  ASSIGNMENT_CAN_NOT_BE_UNASSIGNED = 'ASSIGNMENT_CAN_NOT_BE_UNASSIGNED',
  ASSIGNMENT_PERFORMANCE = 'ASSIGNMENT_PERFORMANCE',
  ASSIGNMENT_REPORT_TITLE = 'ASSIGNMENT_REPORT_TITLE',
  ASSIGNMENT_SUBMISSIONS_TITLE = 'ASSIGNMENT_SUBMISSIONS_TITLE',
  ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_PASS_FAIL_GRADING = 'ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_PASS_FAIL_GRADING',
  ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_SCORE_GRADING = 'ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_SCORE_GRADING',
  ATTEMPT = 'ATTEMPT',
  AVERAGE_ACTION_SCORE_ON_BEST_ATTEMPT = 'AVERAGE_ACTION_SCORE_ON_BEST_ATTEMPT',
  AVERAGE_QUESTION_PERFORMANCE = 'AVERAGE_QUESTION_PERFORMANCE',
  AVERAGE_QUESTIONS_MESSAGE = 'AVERAGE_QUESTIONS_MESSAGE',
  AVERAGE_SCORE = 'AVERAGE_SCORE',
  BASED_ON_TOTAL_QUESTIONS = 'BASED_ON_TOTAL_QUESTIONS',
  BATCH_ACTION_BAR_EDIT_CLOSE = 'BATCH_ACTION_BAR_EDIT_CLOSE',
  BATCH_ACTION_BAR_EDIT_DATES_NO_ITEMS_SELECTED = 'BATCH_ACTION_BAR_EDIT_DATES_NO_ITEMS_SELECTED',
  BATCH_ACTIONS_BAR_DESELECT_ALL = 'BATCH_ACTIONS_BAR_DESELECT_ALL',
  BATCH_ACTIONS_BAR_EDIT_DATES = 'BATCH_ACTIONS_BAR_EDIT_DATES',
  BATCH_ACTIONS_BAR_MAKE_VISIBLE_NOW = 'BATCH_ACTIONS_BAR_MAKE_VISIBLE_NOW',
  BATCH_ACTIONS_BAR_MOVE_REORDER = 'BATCH_ACTIONS_BAR_MOVE_REORDER',
  BATCH_ACTIONS_BAR_REMOVE = 'BATCH_ACTIONS_BAR_REMOVE',
  BATCH_ACTIONS_BAR_SELECT_ALL = 'BATCH_ACTIONS_BAR_SELECT_ALL',
  BATCH_ACTIONS_BAR_UNASSIGN = 'BATCH_ACTIONS_BAR_UNASSIGN',
  BOOK_SECTION = 'BOOK_SECTION',
  BULK_ADD = 'BULK_ADD',
  BULK_EDIT_SETTINGS_PAGE_AVAILABLE_FROM_COL = 'BULK_EDIT_SETTINGS_PAGE_AVAILABLE_FROM_COL',
  BULK_EDIT_SETTINGS_PAGE_BY_DAYS = 'BULK_EDIT_SETTINGS_PAGE_BY_DAYS',
  BULK_EDIT_SETTINGS_PAGE_BY_HOURS = 'BULK_EDIT_SETTINGS_PAGE_BY_HOURS',
  BULK_EDIT_SETTINGS_PAGE_BY_MINUTES = 'BULK_EDIT_SETTINGS_PAGE_BY_MINUTES',
  BULK_EDIT_SETTINGS_PAGE_BY_WEEKS = 'BULK_EDIT_SETTINGS_PAGE_BY_WEEKS',
  BULK_EDIT_SETTINGS_PAGE_CANCEL_BTN = 'BULK_EDIT_SETTINGS_PAGE_CANCEL_BTN',
  BULK_EDIT_SETTINGS_PAGE_CHANGE_ALL_DATES_TAB = 'BULK_EDIT_SETTINGS_PAGE_CHANGE_ALL_DATES_TAB',
  BULK_EDIT_SETTINGS_PAGE_DUE_COL = 'BULK_EDIT_SETTINGS_PAGE_DUE_COL',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_EDIT_GRADING_STARTED_ASSIGNMENT_WARNING = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_EDIT_GRADING_STARTED_ASSIGNMENT_WARNING',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_AVAILABLE_DATE = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_AVAILABLE_DATE',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_AVAILABLE_DATE_NO_DATE = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_AVAILABLE_DATE_NO_DATE',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_DUE_DATE = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_DUE_DATE',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_DUE_DATE_NO_DATE = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_DUE_DATE_NO_DATE',
  // eslint-disable-next-line max-len
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_GRADING_STARTED_ASSIGNMENT_WARNING = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_GRADING_STARTED_ASSIGNMENT_WARNING',
  APPLY_TO_ALL = 'APPLY_TO_ALL',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_HEADING = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_HEADING',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_AVAILABLE_DATE = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_AVAILABLE_DATE',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_DUE_DATE = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_DUE_DATE',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_GRADING = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_GRADING',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_UNPUBLISHED_LESSON_WARNING = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_UNPUBLISHED_LESSON_WARNING',
  BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_UNPUBLISHED_LESSON_SKIPPED_WARNING = 'BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_UNPUBLISHED_LESSON_SKIPPED_WARNING',
  BULK_EDIT_SETTINGS_PAGE_EXTEND_ALL_DATES_TAB = 'BULK_EDIT_SETTINGS_PAGE_EXTEND_ALL_DATES_TAB',
  BULK_EDIT_SETTINGS_PAGE_GRADE_TYPE_UNCHANGED_MODAL = 'BULK_EDIT_SETTINGS_PAGE_GRADE_TYPE_UNCHANGED_MODAL',
  BULK_EDIT_SETTINGS_PAGE_HEADER = 'BULK_EDIT_SETTINGS_PAGE_HEADER',
  BULK_EDIT_SETTINGS_PAGE_INCLUDE_AVAILABLE_COL = 'BULK_EDIT_SETTINGS_PAGE_INCLUDE_AVAILABLE_COL',
  BULK_EDIT_SETTINGS_PAGE_INCLUDE_DUE_COL = 'BULK_EDIT_SETTINGS_PAGE_INCLUDE_DUE_COL',
  BULK_EDIT_SETTINGS_PAGE_PUSH_AVAILABILITY_DATES = 'BULK_EDIT_SETTINGS_PAGE_PUSH_AVAILABILITY_DATES',
  BULK_EDIT_SETTINGS_PAGE_PUSH_DUE_DATES = 'BULK_EDIT_SETTINGS_PAGE_PUSH_DUE_DATES',
  BULK_EDIT_SETTINGS_PAGE_RESOURCE_NAME_COL = 'BULK_EDIT_SETTINGS_PAGE_RESOURCE_NAME_COL',
  BULK_EDIT_SETTINGS_PAGE_RESOURCE_TYPE_COL = 'BULK_EDIT_SETTINGS_PAGE_RESOURCE_TYPE_COL',
  BULK_EDIT_SETTINGS_PAGE_SHIFT_DATES_TAB = 'BULK_EDIT_SETTINGS_PAGE_SHIFT_DATES_TAB',
  BULK_EDIT_SETTINGS_PAGE_SUBMIT_BTN = 'BULK_EDIT_SETTINGS_PAGE_SUBMIT_BTN',
  BULK_EDIT_SETTINGS_SUBMIT_TOAST_ERROR = 'BULK_EDIT_SETTINGS_SUBMIT_TOAST_ERROR',
  BULK_EDIT_SETTINGS_TEACHING_RESOURCE_WARNING = 'BULK_EDIT_SETTINGS_TEACHING_RESOURCE_WARNING',
  BULK_SELECT = 'BULK_SELECT',
  BUTTON_ADD = 'BUTTON_ADD',
  BUTTON_ADD_A_FOLDER = 'BUTTON_ADD_A_FOLDER',
  BUTTON_ADD_MORE_RESOURCES = 'BUTTON_ADD_MORE_RESOURCES',
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
  CANCEL_AND_RETURN_TO_PREVIOUS_PAGE = 'CANCEL_AND_RETURN_TO_PREVIOUS_PAGE',
  CANNOT_BE_UNASSIGNED = 'CANNOT_BE_UNASSIGNED',
  CASE_STUDY = 'CASE_STUDY',
  CATALOG = 'CATALOG',
  CATALOG_ADD_CONFIRM_MESSAGE = 'CATALOG_ADD_CONFIRM_MESSAGE',
  CATALOG_ITEM_STATUS = 'CATALOG_ITEM_STATUS',
  CATALOG_ITEM_STATUS_VIEW_LESS_BTN = 'CATALOG_ITEM_STATUS_VIEW_LESS_BTN',
  CATALOG_ITEM_STATUS_VIEW_MORE_BTN = 'CATALOG_ITEM_STATUS_VIEW_MORE_BTN',
  CHANGE_COURSE = 'CHANGE_COURSE',
  CLASS_AVERAGE_ON_ALL_SUBMISSIONS = 'CLASS_AVERAGE_ON_ALL_SUBMISSIONS',
  CLASS_AVERAGE_ON_VIDEO_COMPLETION_FOR_ALL_VIEWS = 'CLASS_AVERAGE_ON_VIDEO_COMPLETION_FOR_ALL_VIEWS',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
  CLICK_HERE = 'CLICK_HERE',
  COLLAPSE_ALL_FOLDERS = 'COLLAPSE_ALL_FOLDERS',
  COMING_SOON = 'COMING_SOON',
  COMPLETE_STUDENTS_TITLE = 'COMPLETE_STUDENTS_TITLE',
  COMPLETE = 'COMPLETE',
  COMPLETED = 'COMPLETED',
  COMPLETED_STUDENTS = 'COMPLETED_STUDENTS',
  CONFIRM_ACTION_ON_HIDDEN_SYLLABUS = 'CONFIRM_ACTION_ON_HIDDEN_SYLLABUS',
  CONFIRM_SELECTED_ITEMS = 'CONFIRM_SELECTED_ITEMS',
  CONFIRM_UNASSIGN_MODAL = 'CONFIRM_UNASSIGN_MODAL',
  CORRECT = 'CORRECT',
  COPY_COURSE_HAS_CONTENT_MESSAGE = 'COPY_COURSE_HAS_CONTENT_MESSAGE',
  COPY_COURSE_NO_CONTENT_MESSAGE = 'COPY_COURSE_NO_CONTENT_MESSAGE',
  COURSE_BUILDER_PREVIEW_DEFAULT_MESSAGE = 'COURSE_BUILDER_PREVIEW_DEFAULT_MESSAGE',
  COURSE_BUILDER_WHICH_CONTENT_TYPES = 'COURSE_BUILDER_WHICH_CONTENT_TYPES',
  COURSE_COPY_UNMAPPED_ITEMS_TITLE = 'COURSE_COPY_UNMAPPED_ITEMS_TITLE',
  COURSE_COPY_WARN_MSG_PART_1A = 'COURSE_COPY_WARN_MSG_PART_1A',
  COURSE_COPY_WARN_MSG_PART_2A = 'COURSE_COPY_WARN_MSG_PART_2A',
  COURSE_COPY_WARN_MSG_PART_2B = 'COURSE_COPY_WARN_MSG_PART_2B',
  COURSE_END_DATE = 'COURSE_END_DATE',
  COURSE_LOCKED_NOTIFICATION = 'COURSE_LOCKED_NOTIFICATION',
  COURSE_NAME = 'COURSE_NAME',
  COURSE_OWNER_LOCKED_NOTIFICATION = 'COURSE_OWNER_LOCKED_NOTIFICATION',
  COURSE_START_DATE = 'COURSE_START_DATE',
  CREATE_YOUR_COURSE = 'CREATE_YOUR_COURSE',
  DATE_CREATED = 'DATE_CREATED',
  DEFAULT_TAB = 'DEFAULT_TAB',
  DISMISS = 'DISMISS',
  DISMISS_ALL = 'DISMISS_ALL',
  DRAG_DROP_TOGGLE = 'DRAG_DROP_TOGGLE',
  DUE = 'DUE',
  DUE_BY = 'DUE_BY',
  DUE_DATE = 'DUE_DATE',
  DUE_DATE_BEFORE_AVAILABLE_DATE_ERROR_MESSAGE = 'DUE_DATE_BEFORE_AVAILABLE_DATE_ERROR_MESSAGE',
  DUE_DATE_IS_REQUIRED_FOR_ALL_EAQ_ASSIGNMENTS = 'DUE_DATE_IS_REQUIRED_FOR_ALL_EAQ_ASSIGNMENTS',
  DUE_DATE_RESULTS_MESSAGE = 'DUE_DATE_RESULTS_MESSAGE',
  DUE_DATE_RESULTS_MESSAGE_SIMULATION_LINE1 = 'DUE_DATE_RESULTS_MESSAGE_SIMULATION_LINE1',
  DUE_DATE_RESULTS_MESSAGE_SIMULATION_LINE2 = 'DUE_DATE_RESULTS_MESSAGE_SIMULATION_LINE2',
  DUE_ON_DATE_AT_TIME = 'DUE_ON_DATE_AT_TIME',
  DURATION = 'DURATION',
  E_G_NURSING_CARE = 'E_G_NURSING_CARE',
  E_G_SHERPATH_FOR_FUNDAMENTALS = 'E_G_SHERPATH_FOR_FUNDAMENTALS',
  EDIT_SETTINGS_NO_ITEMS_SELECTED = 'EDIT_SETTINGS_NO_ITEMS_SELECTED',
  ELSEVIER_ADAPTIVE_QUIZZING = 'ELSEVIER_ADAPTIVE_QUIZZING',
  EMAIL = 'EMAIL',
  ENTER_PAGE_NUMBER_AND_OR_PAGE_RANGES_SEPARATED_BY_COMMAS = 'ENTER_PAGE_NUMBER_AND_OR_PAGE_RANGES_SEPARATED_BY_COMMAS',
  EXPAND_ALL_FOLDERS = 'EXPAND_ALL_FOLDERS',
  FAIL = 'FAIL',
  FILTER_BY = 'FILTER_BY',
  FILTER_BY_APPLICATION = 'FILTER_BY_APPLICATION',
  FILTER_BY_STATUS = 'FILTER_BY_STATUS',
  FILTER_CONTENT_BY = 'FILTER_CONTENT_BY',
  FILTERS_ARE_ON = 'FILTERS_ARE_ON',
  FINAL_SCORE = 'FINAL_SCORE',
  FIRST_FOLDER_TITLE = 'FIRST_FOLDER_TITLE',
  GRADE = 'GRADE',
  GRADE_TYPE = 'GRADE_TYPE',
  GRADING = 'GRADING',
  GRADE_TYPE_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT = 'GRADE_TYPE_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT',
  GRADE_TYPE_AND_GRADE_POINTS_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT = 'GRADE_TYPE_AND_GRADE_POINTS_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT',
  GROUP_ACTIVITY_TITLE = 'GROUP_ACTIVITY_TITLE',
  HESI_FILTER_TOOLTIP = 'HESI_FILTER_TOOLTIP',
  HESI_FOCUS_CHAPTERS = 'HESI_FOCUS_CHAPTERS',
  HOW_DO_YOU_ORGANIZE_YOUR_SYLLABUS = 'HOW_DO_YOU_ORGANIZE_YOUR_SYLLABUS',
  HOW_DID_I_PERFORM_ON_THIS_QUIZ = 'HOW_DID_I_PERFORM_ON_THIS_QUIZ',
  HOW_MANY_UNIT_DO_YOU_NEED = 'HOW_MANY_UNIT_DO_YOU_NEED',
  INCORRECT = 'INCORRECT',
  ICON_NO_PAST_ASSIGNMENTS = 'ICON_NO_PAST_ASSIGNMENTS',
  ICON_NO_UPCOMING_ASSIGNMENTS = 'ICON_NO_UPCOMING_ASSIGNMENTS',
  // eslint-disable-next-line max-len
  IN_ORDER_TO_GET_STARTED_PLEASE_SELECT_HOW_YOU_WOULD_LIKE_TO_ORGANIZE_YOUR_COURSE_RESOURCE = 'IN_ORDER_TO_GET_STARTED_PLEASE_SELECT_HOW_YOU_WOULD_LIKE_TO_ORGANIZE_YOUR_COURSE_RESOURCE',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_PROGRESS_OR_COMPLETED_GRADED_SKIPPED = 'IN_PROGRESS_OR_COMPLETED_GRADED_SKIPPED',
  INCOMPLETE_STUDENTS_TITLE = 'INCOMPLETE_STUDENTS_TITLE',
  INDICATES_REQUIRED_FIELD = 'INDICATES_REQUIRED_FIELD',
  INFORMATION_TOOLTIP = 'INFORMATION_TOOLTIP',
  INVALID_GRADE_POINTS = 'INVALID_GRADE_POINTS',
  INVALID_PAGE_RANGE_ERROR = 'INVALID_PAGE_RANGE_ERROR',
  LAST_DATE_WATCHED = 'LAST_DATE_WATCHED',
  LATEST_DATE_WATCHED = 'LATEST_DATE_WATCHED',
  LATE = 'LATE',
  LATEST_ATTEMPT_SCORE_TITLE = 'LATEST_ATTEMPT_SCORE_TITLE',
  LATEST_SUBMISSION_TIME = 'LATEST_SUBMISSION_TIME',
  LEARNING_DURATION = 'LEARNING_DURATION',
  LOCKED = 'LOCKED',
  MAKE_VISIBLE_NOW_NO_ITEMS_SELECTED = 'MAKE_VISIBLE_NOW_NO_ITEMS_SELECTED',
  MAKE_VISIBLE_NOW_SUCCESS_TOAST = 'MAKE_VISIBLE_NOW_SUCCESS_TOAST',
  MAKE_VISIBLE_NOW_ALREADY_AVAILABLE_WARNING_TOAST = 'MAKE_VISIBLE_NOW_ALREADY_AVAILABLE_WARNING_TOAST',
  MISSED_ACTIONS = 'MISSED_ACTIONS',
  MOVE_REORDER_ALL_ITEM_CANNOT_BE_SELECTED_TO_BE_MOVED_AT_ONCE = 'MOVE_REORDER_ALL_ITEM_CANNOT_BE_SELECTED_TO_BE_MOVED_AT_ONCE',
  MOVE_REORDER_NO_ITEMS_SELECTED = 'MOVE_REORDER_NO_ITEMS_SELECTED',
  MY_BOOKS = 'MY_BOOKS',
  MY_COURSES = 'MY_COURSES',
  NEW_CONTENT = 'NEW_CONTENT',
  NEW_LESSONS = 'NEW_LESSONS',
  NEW_LESSONS_ARE_AVAILABLE = 'NEW_LESSONS_ARE_AVAILABLE',
  NEW_LESSONS_AVAILABLE_NOW = 'NEW_LESSONS_AVAILABLE_NOW',
  NO_ASSIGNMENTS = 'NO_ASSIGNMENTS',
  NO_ASSIGNMENTS_STU = 'NO_ASSIGNMENTS_STU',
  NO_DUE_DATE = 'NO_DUE_DATE',
  NO_ENROLL = 'NO_ENROLL',
  NO_ITEM_TO_DISPLAY = 'NO_ITEM_TO_DISPLAY',
  NO_SUBMISSIONS = 'NO_SUBMISSIONS',
  NO_SUBMISSIONS_BEFORE_DUE_DATE = 'NO_SUBMISSIONS_BEFORE_DUE_DATE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NOT_DUE = 'NOT_DUE',
  NOT_GRADED = 'NOT_GRADED',
  NOT_STARTED = 'NOT_STARTED',
  NOT_VISIBLE = 'NOT_VISIBLE',
  NUMBER_OF_ATTEMPTS = 'NUMBER_OF_ATTEMPTS',
  NUMBER_OF_STUDENTS_WITH_ASSIGNMENTS = 'NUMBER_OF_STUDENTS_WITH_ASSIGNMENTS',
  ONLY_FOLDERS_CAN_BE_DRAGGED_TO_THE_ROOT_LEVEL = 'ONLY_FOLDERS_CAN_BE_DRAGGED_TO_THE_ROOT_LEVEL',
  OPEN_COURSE_SETUP = 'OPEN_COURSE_SETUP',
  OPEN_EBOOK = 'OPEN_EBOOK',
  OR_A_FOLDER = 'OR_A_FOLDER',
  OSMOSIS_PILOT_SURVEY_PROMPT = 'OSMOSIS_PILOT_SURVEY_PROMPT',
  OVERVIEW = 'OVERVIEW',
  PAGE_TITLE = 'PAGE_TITLE',
  PASS = 'PASS',
  PASS_FAIL = 'PASS_FAIL',
  PAST_ASSIGNMENTS = 'PAST_ASSIGNMENTS',
  PAST_DUE_ASSIGNMENTS = 'PAST_DUE_ASSIGNMENTS',
  PERCENT_OF_CLASS_PERFORMED_CORRECTLY = 'PERCENT_OF_CLASS_PERFORMED_CORRECTLY',
  PLEASE_NOTE_THAT_UNASSIGNING = 'PLEASE_NOTE_THAT_UNASSIGNING',
  PLEASE_SELECT_AT_LEAST_ONE_CHAPTER_AND_PAGE = 'PLEASE_SELECT_AT_LEAST_ONE_CHAPTER_AND_PAGE',
  PRODUCT = 'PRODUCT',
  PURCHASE_BINDER_READY_VERSION = 'PURCHASE_BINDER_READY_VERSION',
  QUESTION_PREVIEW_MODAL_TITLE = 'QUESTION_PREVIEW_MODAL_TITLE',
  QUESTION_PREVIEW_MODAL_TYPE = 'QUESTION_PREVIEW_MODAL_TYPE',
  READING_SELECTION = 'READING_SELECTION',
  REMOVE_NO_ITEMS_SELECTED = 'REMOVE_NO_ITEMS_SELECTED',
  REPORT_CONTENT_ERROR = 'REPORT_CONTENT_ERROR',
  RESET = 'RESET',
  RESOURCE_LIBRARY = 'RESOURCE_LIBRARY',
  RESOURCE_TYPE = 'RESOURCE_TYPE',
  RESULTS_FILTERED = 'RESULTS_FILTERED',
  SAVE_AND_COMPLETE = 'SAVE_AND_COMPLETE',
  SAVE_AND_CONTINUE_TO_ADVANCED_SETTINGS = 'SAVE_AND_CONTINUE_TO_ADVANCED_SETTINGS',
  SCORE = 'SCORE',
  SCORED = 'SCORED',
  SCORES = 'SCORES',
  SEARCH_BY_KEYWORD_CHAPTER_OR_RESOURCE_TYPE = 'SEARCH_BY_KEYWORD_CHAPTER_OR_RESOURCE_TYPE',
  SEARCH_BY_COURSE_NAME_PRODUCT_OR_ISBN = 'SEARCH_BY_COURSE_NAME_PRODUCT_OR_ISBN',
  SEARCH_COURSE_SECTION = 'SEARCH_COURSE_SECTION',
  SEARCH_RESOURCES = 'SEARCH_RESOURCES',
  SECTIONS = 'SECTIONS',
  SELECT_A_COURSE_TO_COPY = 'SELECT_A_COURSE_TO_COPY',
  SELECT_ALL = 'SELECT_ALL',
  SELECT_A_MASTERY_GOAL = 'SELECT_A_MASTERY_GOAL',
  MASTERY_LEVEL_ALREADY_ACHIEVED_FOR_THIS_TOPIC = 'MASTERY_LEVEL_ALREADY_ACHIEVED_FOR_THIS_TOPIC',
  SELECT_EXISTING_COURSE_REQUIRED_ERROR = 'SELECT_EXISTING_COURSE_REQUIRED_ERROR',
  SET_AS_DEAFULT_TAB = 'SET_AS_DEAFULT_TAB',
  SIM_ACTIONS_TOOLTIP = 'SIM_ACTIONS_TOOLTIP',
  SIMULATION = 'SIMULATION',
  SIMULATION_IS_LOADING = 'SIMULATION_IS_LOADING',
  SKILL = 'SKILL',
  SKILL_ATTEMPT_RESULT_CORRECT_TAB = 'SKILL_ATTEMPT_RESULT_CORRECT_TAB',
  SKILL_ATTEMPT_RESULT_HEADER = 'SKILL_ATTEMPT_RESULT_HEADER',
  SKILL_ATTEMPT_RESULT_INCORRECT_TAB = 'SKILL_ATTEMPT_RESULT_INCORRECT_TAB',
  START = 'START',
  START_A_QUIZ = 'START_A_QUIZ',
  START_QUIZ = 'START_QUIZ',
  START_YOUR_COURSE_BUILDING_BY_PROVIDING_A_NAME_FOR_YOUR_FIRST_FOLDER = 'START_YOUR_COURSE_BUILDING_BY_PROVIDING_A_NAME_FOR_YOUR_FIRST_FOLDER',
  STATUS = 'STATUS',
  STANDARD = 'STANDARD',
  STUDENT = 'STUDENT',
  STUDENT_ENGAGEMENT = 'STUDENT_ENGAGEMENT',
  STUDENT_SCORES_TITLE = 'STUDENT_SCORES_TITLE',
  STUDENT_STUDY = 'STUDENT_STUDY',
  STUDENTS_WATCHED_THIS_VIDEO = 'STUDENTS_WATCHED_THIS_VIDEO',
  SUBMISSION_ATTEMPTS_TITLE = 'SUBMISSION_ATTEMPTS_TITLE',
  SUBMISSION_TIME = 'SUBMISSION_TIME',
  SYSTEM_REQUIREMENT_NOTE = 'SYSTEM_REQUIREMENT_NOTE',
  THE_FOLDER_IS_EMPTY = 'THE_FOLDER_IS_EMPTY',
  THE_PAGE_NUMBERS_OUT_OF_SCOPE_ERROR = 'THE_PAGE_NUMBERS_OUT_OF_SCOPE_ERROR',
  THE_PREVIOUS_CHANGES_YOU_MADE_ON_COURSE_PLAN_AND_THE_ASIGNMENTS_WILL_NOT_BE_PRESERVED = 'THE_PREVIOUS_CHANGES_YOU_MADE_ON_COURSE_PLAN_AND_THE_ASIGNMENTS_WILL_NOT_BE_PRESERVED',
  THIS_COURSE_INCLUDES_THE_FOLLOWING_PRODUCTS = 'THIS_COURSE_INCLUDES_THE_FOLLOWING_PRODUCTS',
  THIS_LESSON_HAS_NOT_RELEASE_YET = 'THIS_LESSON_HAS_NOT_RELEASE_YET',
  THIS_TYPICALLY_MATCHES_YOUR_SYLLABUS_STRUCTURE = 'THIS_TYPICALLY_MATCHES_YOUR_SYLLABUS_STRUCTURE',
  TIME_SPENT = 'TIME_SPENT',
  TOAST_SUCCESS_ADD_SYLLABUS = 'TOAST_SUCCESS_ADD_SYLLABUS',
  TOAST_SUCCESS_MOVE_SYLLABUS = 'TOAST_SUCCESS_MOVE_SYLLABUS',
  TOAST_SUCCESS_UPDATE_ASSIGNMENTS = 'TOAST_SUCCESS_UPDATE_ASSIGNMENTS',
  TOAST_SUCCESS_UPDATE_SYLLABUS = 'TOAST_SUCCESS_UPDATE_SYLLABUS',
  TO_BE_UNASSIGNED = 'TO_BE_UNASSIGNED',
  TOPICS = 'TOPICS',
  TOPIC_PERFORMANCE = 'TOPIC_PERFORMANCE',
  TYPE = 'TYPE',
  UNABLE_TO_DROP_EXCEED_LIMIT = 'UNABLE_TO_DROP_EXCEED_LIMIT',
  UNABLE_TO_DROP_INTO_ITS_CHILDREN = 'UNABLE_TO_DROP_INTO_ITS_CHILDREN',
  UNABLE_TO_DROP_ITSELF = 'UNABLE_TO_DROP_ITSELF',
  UNABLE_TO_UNASSIGN_BECAUSE_ALREADY_UNASSIGNED = 'UNABLE_TO_UNASSIGN_BECAUSE_ALREADY_UNASSIGNED',
  UNABLE_TO_UNASSIGN_BECAUSE_GRADED_STARTED = 'UNABLE_TO_UNASSIGN_BECAUSE_GRADED_STARTED',
  UNASSIGN_NO_ITEMS_SELECTED = 'UNASSIGN_NO_ITEMS_SELECTED',
  UPCOMING_ASSIGNMENTS = 'UPCOMING_ASSIGNMENTS',
  UPCOMING_TAB_MESSAGE = 'UPCOMING_TAB_MESSAGE',
  UPCOMING_LESSONS = 'UPCOMING_LESSONS',
  UPCOMING_LESSONS_MODAL_MESSAGE = 'UPCOMING_LESSONS_MODAL_MESSAGE',
  VIEW = 'VIEW',
  VIEW_ALL_ATTEMPTS = 'VIEW_ALL_ATTEMPTS',
  VIEW_DETAILS = 'VIEW_DETAILS',
  VIEW_ATTEMPT_RESULTS = 'VIEW_ATTEMPT_RESULTS',
  VIEW_FULLSCREEN = 'VIEW_FULLSCREEN',
  VIEW_NEW_LESSONS = 'VIEW_NEW_LESSONS',
  VIEW_SYSTEM_REQUIREMENTS = 'VIEW_SYSTEM_REQUIREMENTS',
  VIEW_UPCOMING_LESSONS = 'VIEW_UPCOMING_LESSONS',
  VISIBLE_ON_DATE_AT_TIME = 'VISIBLE_ON_DATE_AT_TIME',
  VISIBLE_UPON_RELEASE = 'VISIBLE_UPON_RELEASE',
  VIDEO_COMPLETED_RESULTS_MESSAGE = 'VIDEO_COMPLETED_RESULTS_MESSAGE',
  VIDEO_COMPLETION = 'VIDEO_COMPLETION',
  VIDEO_COMPLETION_BASED_ON_LATEST_VIEW = 'VIDEO_COMPLETION_BASED_ON_LATEST_VIEW',
  ESTIMATED_TIME = 'ESTIMATED_TIME',
  WANT_TO_PROCEED = 'WANT_TO_PROCEED',
  WARNING_MESSAGE_FOR_REMOVE_WHEN_FILTERED = 'WARNING_MESSAGE_FOR_REMOVE_WHEN_FILTERED',
  // eslint-disable-next-line max-len
  WARNING_MESSAGE_FOR_UNABLE_TO_REMOVE_WHEN_ASSIGNMENT_STARTED = 'WARNING_MESSAGE_FOR_UNABLE_TO_REMOVE_WHEN_ASSIGNMENT_STARTED',
  WARNING_MESSAGE_FOR_UNASSIGN_WHEN_FILTER = 'WARNING_MESSAGE_FOR_UNASSIGN_WHEN_FILTER',
  WARNING_MESSAGE_FOR_UNASSIGN_WHEN_THERE_IS_A_SKIPPED_ITEM = 'WARNING_MESSAGE_FOR_UNASSIGN_WHEN_THERE_IS_A_SKIPPED_ITEM',
  WARNING_MESSAGE_FOR_UPCOMING_LESSON = 'WARNING_MESSAGE_FOR_UPCOMING_LESSON',
  WE_NEED_TO_KNOW_A_LITTLE_MORE_ABOUT_HOW_TO_STRUCTURE_YOUR_COURSE = 'WE_NEED_TO_KNOW_A_LITTLE_MORE_ABOUT_HOW_TO_STRUCTURE_YOUR_COURSE',
  WELCOME_TO_YOUR_COURSE = 'WELCOME_TO_YOUR_COURSE',
  WELCOME_USER = 'WELCOME_USER',
  WOULD_YOU_LIKE_US_TO_AUTOMATICALLY_ADD_CONTENT_TO_YOUR_COURSE = 'WOULD_YOU_LIKE_US_TO_AUTOMATICALLY_ADD_CONTENT_TO_YOUR_COURSE',
  YOU_CAN_ADD_REMOVE_MOVE_CONTENT_AFTER_YOUR_COURSE_IS_CREATED = 'YOU_CAN_ADD_REMOVE_MOVE_CONTENT_AFTER_YOUR_COURSE_IS_CREATED',
  YOUR_COURSE_PLAN_IS_EMPTY = 'YOUR_COURSE_PLAN_IS_EMPTY',
  YOUR_FILTERED_RESULT_IS_EMPTY = 'YOUR_FILTERED_RESULT_IS_EMPTY',
  ASSIGNMENT_CONTENT_ID_IS_EMPTY = 'ASSIGNMENT_CONTENT_ID_IS_EMPTY',
  ASSIGNMENT_TITLE_IS_EMPTY = 'ASSIGNMENT_TITLE_IS_EMPTY',
  INVALID_AVAILABILITY_DATE = 'INVALID_AVAILABILITY_DATE',
  INVALID_DUE_DATE = 'INVALID_DUE_DATE',
  ENTRY_EXCEEDS_LENGTH_LIMIT = 'ENTRY_EXCEEDS_LENGTH_LIMIT',
  VALUE_REQUIRED = 'VALUE_REQUIRED',
  COURSE_COPY_START_DATE_TOOLTIP = 'COURSE_COPY_START_DATE_TOOLTIP',
  COPY_URL = 'COPY_URL',
  COPY_DEEP_LINK_INSTRUCTIONS = 'COPY_DEEP_LINK_INSTRUCTIONS',
  COPY_DEEP_COPY_SUCCESS_TOAST = 'COPY_DEEP_COPY_SUCCESS_TOAST',
  CLOSE = 'CLOSE',
  DEEP_LINK_CREATE_WARNING_TOAST_LINE_1 = 'DEEP_LINK_CREATE_WARNING_TOAST_LINE_1',
  DEEP_LINK_CREATE_WARNING_TOAST_LINE_2 = 'DEEP_LINK_CREATE_WARNING_TOAST_LINE_2',
  INVALID_URL_FORMAT = 'INVALID_URL_FORMAT',
  INVALID_URL_PROTOCOL = 'INVALID_URL_PROTOCOL',
  AUTHESS_MODAL_MESSAGE = 'AUTHESS_MODAL_MESSAGE',
  OKAY = 'OKAY',
  TRANSCRIPT = 'TRANSCRIPT',
  TOGGLE_TRANSCRIPT = 'TOGGLE_TRANSCRIPT',
  GRADE_POINTS = 'GRADE_POINTS',
  INCLUDES = 'INCLUDES',
  EXTERNAL_LINK_TOOLTIP = 'EXTERNAL_LINK_TOOLTIP',
  EXTERNAL_LINK_URL_NOTE = 'EXTERNAL_LINK_URL_NOTE',
  LAUNCH_STUDENT_VIEW = 'LAUNCH_STUDENT_VIEW',
  STUDENT_LAUNCH_WARNING = 'STUDENT_LAUNCH_WARNING',
  QUIZ_TYPE = 'QUIZ_TYPE',
  COMPLETED_DATE = 'COMPLETED_DATE',
  NOVICE = 'NOVICE',
  NOVICE_EXPLAINER = 'NOVICE_EXPLAINER',
  INTERMEDIATE = 'INTERMEDIATE',
  INTERMEDIATE_EXPLAINER = 'INTERMEDIATE_EXPLAINER',
  PROFICIENT = 'PROFICIENT',
  PROFICIENT_EXPLAINER = 'PROFICIENT_EXPLAINER',
  MASTERY = 'MASTERY',
  CUSTOM = 'CUSTOM',
  CUSTOM_QUESTIONS = 'CUSTOM_QUESTIONS',
  COOKIE_PAGE = 'COOKIE_PAGE',
  GOAL = 'GOAL',
  RESUME = 'RESUME',
  QUESTIONS = 'QUESTIONS',
  BRAIN_BUILDING_IMAGE = 'BRAIN_BUILDING_IMAGE',
  SELF_STUDY_PROGRESS = 'SELF_STUDY_PROGRESS',
  EAQ_STUDENT_SELF_STUDY_TITLE = 'EAQ_STUDENT_SELF_STUDY_TITLE',
  COOKIES_ARE_USED_BY_THIS_SITE = 'COOKIES_ARE_USED_BY_THIS_SITE',
  BELOW_IS_A_LIST_OF_EACH_SELF_STUDY_QUIZ_YOU_HAVE_CREATED = 'BELOW_IS_A_LIST_OF_EACH_SELF_STUDY_QUIZ_YOU_HAVE_CREATED',
  INCREASE_YOUR_CONFIDENCE_FOR_YOUR_UPCOMING_EXAMS_BY_PRACTICING_MORE_QUESTIONS = 'INCREASE_YOUR_CONFIDENCE_FOR_YOUR_UPCOMING_EXAMS_BY_PRACTICING_MORE_QUESTIONS',
  CREATE_A_NEW_QUIZ = 'CREATE_A_NEW_QUIZ',
  YOUVE_ANSWERED_NUM_SELF_STUDY_QUESTIONS_IN_THIS_COURSE = 'YOUVE_ANSWERED_NUM_SELF_STUDY_QUESTIONS_IN_THIS_COURSE',
  USER_INFO = 'USER_INFO',
  COURSE_INFO = 'COURSE_INFO',
  SUPPORT = 'SUPPORT',
  QUESTION_SET = 'QUESTION_SET',
  TOPIC_ORGANIZATION = 'TOPIC_ORGANIZATION',
  EAQ_STUDENT_TOPIC_REPORT = 'EAQ_STUDENT_TOPIC_REPORT',
  TOPIC = 'TOPIC',
  MASTERY_LEVEL = 'MASTERY_LEVEL',
  MASTERY_LEVELS = 'MASTERY_LEVELS',
  CORRECT_PERCENTAGE = 'CORRECT_PERCENTAGE',
  QUESTIONS_ANSWERED = 'QUESTIONS_ANSWERED',
  QUESTIONS_CORRECT = 'QUESTIONS_CORRECT',
  QUESTIONS_CONFIDENT = 'QUESTIONS_CONFIDENT',
  TOTAL_TIME = 'TOTAL_TIME',
  QUIZ_ME_ON_THIS_TOPIC = 'QUIZ_ME_ON_THIS_TOPIC',
  WHAT_ARE_MASTERY_LEVELS = 'WHAT_ARE_MASTERY_LEVELS',
  ALL_OPTION = 'ALL_OPTION',
  KEEP_UP_THE_GREAT_WORK = 'KEEP_UP_THE_GREAT_WORK',
  COMPLETION_STATUS = 'COMPLETION_STATUS',
  RESUME_QUIZ = 'RESUME_QUIZ',
  VIEW_RESULT = 'VIEW_RESULT',
  VIEWING = 'VIEWING',
  RESUME_QUIZ_OR_VIEW_RESULTS = 'RESUME_QUIZ_OR_VIEW_RESULTS',
  SELECT_A_QUESTION_SET = 'SELECT_A_QUESTION_SET',
  CHOOSE_A_FINITE_NUMBER_OF_QUESTIONS = 'CHOOSE_A_FINITE_NUMBER_OF_QUESTIONS',
  CASES_ITEMS_NEXT_GENERATION = 'CASES_ITEMS_NEXT_GENERATION',
  SET_OF_NGN_ITEMS_ALIGNED_TO_CLINICAL_CONTENT = 'SET_OF_NGN_ITEMS_ALIGNED_TO_CLINICAL_CONTENT',
  NUMBER_OF_QUESTIONS = 'NUMBER_OF_QUESTIONS',
  SELECT_AN_OPTION = 'SELECT_AN_OPTION',
  NOT_ENOUGH_QUESTIONS_SELECT_FEWER_QUESTIONS_OR_MORE_ASSESSMENTS = 'NOT_ENOUGH_QUESTIONS_SELECT_FEWER_QUESTIONS_OR_MORE_ASSESSMENTS',
  LIMITLESS = 'LIMITLESS',
  UNLIMITED_QUESTIONS_UNTIL_YOU_LEVEL_UP = 'UNLIMITED_QUESTIONS_UNTIL_YOU_LEVEL_UP',
  TOPIC_FILTER = 'TOPIC_FILTER',
  SHERPATH_AI_MATERIALS_ACCESS = 'SHERPATH_AI_MATERIALS_ACCESS',
  SHERPATH_AI_EBOOK_LABEL = 'SHERPATH_AI_EBOOK_LABEL',
  SHERPATH_AI_MATERIALS_LABEL = 'SHERPATH_AI_MATERIALS_LABEL',
  SHERPATH_AI_NO_USER_MATERIALS = 'SHERPATH_AI_NO_USER_MATERIALS',
  EMDASH = 'EMDASH'
}

const messages = Object.keys(LANGUAGE_KEY).reduce((acc, cur) => ({
  ...acc,
  [cur]: {
    id: cur,
    defaultMessage: cur,
  }
}), {}) as Record<LANGUAGE_KEY, MessageDescriptor>;

export const Translations = defineMessages<LANGUAGE_KEY>(messages);

// Define list of static messages that can be translated without passing any value in
export const StaticMessagesKeys: LANGUAGE_KEY[] = [
  LANGUAGE_KEY.UNLIMITED_QUESTIONS_UNTIL_YOU_LEVEL_UP,
  LANGUAGE_KEY.LIMITLESS,
  LANGUAGE_KEY.ACTION,
  LANGUAGE_KEY.ACTIONS,
  LANGUAGE_KEY.ACTIONS_RESULTS_MESSAGE,
  LANGUAGE_KEY.ADD,
  LANGUAGE_KEY.ADD_A_FOLDER,
  LANGUAGE_KEY.ADD_A_RESOURCE,
  LANGUAGE_KEY.ADD_DATE_RANGE_TO_EACH_UNIT,
  LANGUAGE_KEY.ADD_RESOURCES,
  LANGUAGE_KEY.ADD_MORE_RESOURCES,
  LANGUAGE_KEY.ADD_SINGLE_RESOURCE,
  LANGUAGE_KEY.ADDITIONAL_LESSONS_COMING_SOON,
  LANGUAGE_KEY.ALL_ATTEMPTS_TITLE,
  LANGUAGE_KEY.ALL_ITEMS,
  LANGUAGE_KEY.ALL_VIEWS,
  LANGUAGE_KEY.ALREADY_UNASSIGNED_SKIPPED,
  LANGUAGE_KEY.APPLICATION,
  LANGUAGE_KEY.ARE_YOU_SURE,
  LANGUAGE_KEY.AREA,
  LANGUAGE_KEY.ASSIGNMENT_CAN_NOT_BE_UNASSIGNED,
  LANGUAGE_KEY.ASSIGNMENT_PERFORMANCE,
  LANGUAGE_KEY.ASSIGNMENT_REPORT_TITLE,
  LANGUAGE_KEY.ASSIGNMENT_SUBMISSIONS_TITLE,
  LANGUAGE_KEY.ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_PASS_FAIL_GRADING,
  LANGUAGE_KEY.ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_SCORE_GRADING,
  LANGUAGE_KEY.ATTEMPT,
  LANGUAGE_KEY.AVERAGE_ACTION_SCORE_ON_BEST_ATTEMPT,
  LANGUAGE_KEY.AVERAGE_QUESTION_PERFORMANCE,
  LANGUAGE_KEY.AVERAGE_QUESTIONS_MESSAGE,
  LANGUAGE_KEY.AVERAGE_SCORE,
  LANGUAGE_KEY.BASED_ON_TOTAL_QUESTIONS,
  LANGUAGE_KEY.BATCH_ACTION_BAR_EDIT_CLOSE,
  LANGUAGE_KEY.BATCH_ACTION_BAR_EDIT_DATES_NO_ITEMS_SELECTED,
  LANGUAGE_KEY.BATCH_ACTIONS_BAR_DESELECT_ALL,
  LANGUAGE_KEY.BATCH_ACTIONS_BAR_EDIT_DATES,
  LANGUAGE_KEY.BATCH_ACTIONS_BAR_MAKE_VISIBLE_NOW,
  LANGUAGE_KEY.BATCH_ACTIONS_BAR_MOVE_REORDER,
  LANGUAGE_KEY.BATCH_ACTIONS_BAR_REMOVE,
  LANGUAGE_KEY.BATCH_ACTIONS_BAR_SELECT_ALL,
  LANGUAGE_KEY.BATCH_ACTIONS_BAR_UNASSIGN,
  LANGUAGE_KEY.BELOW_IS_A_LIST_OF_EACH_SELF_STUDY_QUIZ_YOU_HAVE_CREATED,
  LANGUAGE_KEY.BOOK_SECTION,
  LANGUAGE_KEY.BRAIN_BUILDING_IMAGE,
  LANGUAGE_KEY.BULK_ADD,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_AVAILABLE_FROM_COL,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_BY_DAYS,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_BY_HOURS,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_BY_MINUTES,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_BY_WEEKS,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_CANCEL_BTN,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_CHANGE_ALL_DATES_TAB,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_DUE_COL,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_EDIT_GRADING_STARTED_ASSIGNMENT_WARNING,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_UNPUBLISHED_LESSON_SKIPPED_WARNING,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_AVAILABLE_DATE,
  LANGUAGE_KEY.APPLY_TO_ALL,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_AVAILABLE_DATE_NO_DATE,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_DUE_DATE,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_DUE_DATE_NO_DATE,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_GRADING_STARTED_ASSIGNMENT_WARNING,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_HEADING,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_AVAILABLE_DATE,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_DUE_DATE,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_GRADING,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EXTEND_ALL_DATES_TAB,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_HEADER,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_INCLUDE_AVAILABLE_COL,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_INCLUDE_DUE_COL,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_PUSH_AVAILABILITY_DATES,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_PUSH_DUE_DATES,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_RESOURCE_NAME_COL,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_RESOURCE_TYPE_COL,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_SHIFT_DATES_TAB,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_SUBMIT_BTN,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_SUBMIT_TOAST_ERROR,
  LANGUAGE_KEY.BULK_EDIT_SETTINGS_TEACHING_RESOURCE_WARNING,
  LANGUAGE_KEY.BULK_SELECT,
  LANGUAGE_KEY.BUTTON_ADD,
  LANGUAGE_KEY.BUTTON_ADD_A_FOLDER,
  LANGUAGE_KEY.BUTTON_ADD_MORE_RESOURCES,
  LANGUAGE_KEY.CONFIRM,
  LANGUAGE_KEY.CANCEL,
  LANGUAGE_KEY.CANCEL_AND_RETURN_TO_PREVIOUS_PAGE,
  LANGUAGE_KEY.CANNOT_BE_UNASSIGNED,
  LANGUAGE_KEY.CASE_STUDY,
  LANGUAGE_KEY.CATALOG,
  LANGUAGE_KEY.CATALOG_ITEM_STATUS_VIEW_LESS_BTN,
  LANGUAGE_KEY.CHANGE_COURSE,
  LANGUAGE_KEY.CLASS_AVERAGE_ON_ALL_SUBMISSIONS,
  LANGUAGE_KEY.CLASS_AVERAGE_ON_VIDEO_COMPLETION_FOR_ALL_VIEWS,
  LANGUAGE_KEY.CLEAR_FILTERS,
  LANGUAGE_KEY.CLEAR_SEARCH,
  LANGUAGE_KEY.CLICK_HERE,
  LANGUAGE_KEY.COLLAPSE_ALL_FOLDERS,
  LANGUAGE_KEY.COMING_SOON,
  LANGUAGE_KEY.COMPLETE_STUDENTS_TITLE,
  LANGUAGE_KEY.COMPLETE,
  LANGUAGE_KEY.COMPLETED,
  LANGUAGE_KEY.COMPLETED_STUDENTS,
  LANGUAGE_KEY.CONFIRM_ACTION_ON_HIDDEN_SYLLABUS,
  LANGUAGE_KEY.CONFIRM_UNASSIGN_MODAL,
  LANGUAGE_KEY.COOKIE_PAGE,
  LANGUAGE_KEY.COOKIES_ARE_USED_BY_THIS_SITE,
  LANGUAGE_KEY.COPY_COURSE_HAS_CONTENT_MESSAGE,
  LANGUAGE_KEY.COPY_COURSE_NO_CONTENT_MESSAGE,
  LANGUAGE_KEY.CORRECT,
  LANGUAGE_KEY.COURSE_BUILDER_PREVIEW_DEFAULT_MESSAGE,
  LANGUAGE_KEY.COURSE_BUILDER_WHICH_CONTENT_TYPES,
  LANGUAGE_KEY.COURSE_COPY_UNMAPPED_ITEMS_TITLE,
  LANGUAGE_KEY.COURSE_COPY_WARN_MSG_PART_2A,
  LANGUAGE_KEY.COURSE_END_DATE,
  LANGUAGE_KEY.COURSE_LOCKED_NOTIFICATION,
  LANGUAGE_KEY.COURSE_NAME,
  LANGUAGE_KEY.COURSE_OWNER_LOCKED_NOTIFICATION,
  LANGUAGE_KEY.COURSE_START_DATE,
  LANGUAGE_KEY.CREATE_YOUR_COURSE,
  LANGUAGE_KEY.CUSTOM,
  LANGUAGE_KEY.CUSTOM_QUESTIONS,
  LANGUAGE_KEY.DATE_CREATED,
  LANGUAGE_KEY.COMPLETED_DATE,
  LANGUAGE_KEY.DEFAULT_TAB,
  LANGUAGE_KEY.DISMISS,
  LANGUAGE_KEY.DISMISS_ALL,
  LANGUAGE_KEY.DRAG_DROP_TOGGLE,
  LANGUAGE_KEY.DUE,
  LANGUAGE_KEY.DUE_BY,
  LANGUAGE_KEY.DUE_DATE,
  LANGUAGE_KEY.DUE_DATE_BEFORE_AVAILABLE_DATE_ERROR_MESSAGE,
  LANGUAGE_KEY.DUE_DATE_IS_REQUIRED_FOR_ALL_EAQ_ASSIGNMENTS,
  LANGUAGE_KEY.DUE_DATE_RESULTS_MESSAGE,
  LANGUAGE_KEY.DUE_DATE_RESULTS_MESSAGE_SIMULATION_LINE1,
  LANGUAGE_KEY.DUE_DATE_RESULTS_MESSAGE_SIMULATION_LINE2,
  LANGUAGE_KEY.DUE_ON_DATE_AT_TIME,
  LANGUAGE_KEY.DURATION,
  LANGUAGE_KEY.E_G_NURSING_CARE,
  LANGUAGE_KEY.E_G_SHERPATH_FOR_FUNDAMENTALS,
  LANGUAGE_KEY.EAQ_STUDENT_SELF_STUDY_TITLE,
  LANGUAGE_KEY.EDIT_SETTINGS_NO_ITEMS_SELECTED,
  LANGUAGE_KEY.EMAIL,
  LANGUAGE_KEY.ENTER_PAGE_NUMBER_AND_OR_PAGE_RANGES_SEPARATED_BY_COMMAS,
  LANGUAGE_KEY.ELSEVIER_ADAPTIVE_QUIZZING,
  LANGUAGE_KEY.EXPAND_ALL_FOLDERS,
  LANGUAGE_KEY.FAIL,
  LANGUAGE_KEY.FILTER_BY,
  LANGUAGE_KEY.FILTER_BY_APPLICATION,
  LANGUAGE_KEY.FILTER_BY_STATUS,
  LANGUAGE_KEY.FILTER_CONTENT_BY,
  LANGUAGE_KEY.FILTERS_ARE_ON,
  LANGUAGE_KEY.FINAL_SCORE,
  LANGUAGE_KEY.FIRST_FOLDER_TITLE,
  LANGUAGE_KEY.GOAL,
  LANGUAGE_KEY.GRADE,
  LANGUAGE_KEY.GRADE_TYPE,
  LANGUAGE_KEY.GRADING,
  LANGUAGE_KEY.GRADE_TYPE_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT,
  LANGUAGE_KEY.GRADE_TYPE_AND_GRADE_POINTS_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT,
  LANGUAGE_KEY.GROUP_ACTIVITY_TITLE,
  LANGUAGE_KEY.HESI_FILTER_TOOLTIP,
  LANGUAGE_KEY.HESI_FOCUS_CHAPTERS,
  LANGUAGE_KEY.HOW_DO_YOU_ORGANIZE_YOUR_SYLLABUS,
  LANGUAGE_KEY.HOW_DID_I_PERFORM_ON_THIS_QUIZ,
  LANGUAGE_KEY.HOW_MANY_UNIT_DO_YOU_NEED,
  LANGUAGE_KEY.INCORRECT,
  LANGUAGE_KEY.ICON_NO_PAST_ASSIGNMENTS,
  LANGUAGE_KEY.ICON_NO_UPCOMING_ASSIGNMENTS,
  LANGUAGE_KEY.UPCOMING_LESSONS,
  LANGUAGE_KEY.UPCOMING_LESSONS_MODAL_MESSAGE,
  LANGUAGE_KEY.IN_ORDER_TO_GET_STARTED_PLEASE_SELECT_HOW_YOU_WOULD_LIKE_TO_ORGANIZE_YOUR_COURSE_RESOURCE,
  LANGUAGE_KEY.IN_PROGRESS,
  LANGUAGE_KEY.IN_PROGRESS_OR_COMPLETED_GRADED_SKIPPED,
  LANGUAGE_KEY.INCOMPLETE_STUDENTS_TITLE,
  LANGUAGE_KEY.INDICATES_REQUIRED_FIELD,
  LANGUAGE_KEY.INFORMATION_TOOLTIP,
  LANGUAGE_KEY.INTERMEDIATE,
  LANGUAGE_KEY.INVALID_GRADE_POINTS,
  LANGUAGE_KEY.INVALID_PAGE_RANGE_ERROR,
  LANGUAGE_KEY.LAST_DATE_WATCHED,
  LANGUAGE_KEY.LATEST_DATE_WATCHED,
  LANGUAGE_KEY.LATE,
  LANGUAGE_KEY.LATEST_ATTEMPT_SCORE_TITLE,
  LANGUAGE_KEY.LATEST_SUBMISSION_TIME,
  LANGUAGE_KEY.LEARNING_DURATION,
  LANGUAGE_KEY.LOCKED,
  LANGUAGE_KEY.MAKE_VISIBLE_NOW_NO_ITEMS_SELECTED,
  LANGUAGE_KEY.MAKE_VISIBLE_NOW_ALREADY_AVAILABLE_WARNING_TOAST,
  LANGUAGE_KEY.MASTERY,
  LANGUAGE_KEY.MISSED_ACTIONS,
  LANGUAGE_KEY.MOVE_REORDER_ALL_ITEM_CANNOT_BE_SELECTED_TO_BE_MOVED_AT_ONCE,
  LANGUAGE_KEY.MOVE_REORDER_NO_ITEMS_SELECTED,
  LANGUAGE_KEY.MY_BOOKS,
  LANGUAGE_KEY.MY_COURSES,
  LANGUAGE_KEY.NEW_CONTENT,
  LANGUAGE_KEY.NEW_LESSONS,
  LANGUAGE_KEY.NEW_LESSONS_ARE_AVAILABLE,
  LANGUAGE_KEY.NEW_LESSONS_AVAILABLE_NOW,
  LANGUAGE_KEY.NO_ASSIGNMENTS,
  LANGUAGE_KEY.NO_ASSIGNMENTS_STU,
  LANGUAGE_KEY.NO_DUE_DATE,
  LANGUAGE_KEY.NO_ENROLL,
  LANGUAGE_KEY.NO_ITEM_TO_DISPLAY,
  LANGUAGE_KEY.NO_SUBMISSIONS,
  LANGUAGE_KEY.NO_SUBMISSIONS_BEFORE_DUE_DATE,
  LANGUAGE_KEY.NOT_AVAILABLE,
  LANGUAGE_KEY.NOT_DUE,
  LANGUAGE_KEY.NOT_GRADED,
  LANGUAGE_KEY.NOT_STARTED,
  LANGUAGE_KEY.NOT_VISIBLE,
  LANGUAGE_KEY.NOVICE,
  LANGUAGE_KEY.NUMBER_OF_ATTEMPTS,
  LANGUAGE_KEY.NUMBER_OF_STUDENTS_WITH_ASSIGNMENTS,
  LANGUAGE_KEY.ONLY_FOLDERS_CAN_BE_DRAGGED_TO_THE_ROOT_LEVEL,
  LANGUAGE_KEY.OPEN_COURSE_SETUP,
  LANGUAGE_KEY.OPEN_EBOOK,
  LANGUAGE_KEY.OSMOSIS_PILOT_SURVEY_PROMPT,
  LANGUAGE_KEY.OVERVIEW,
  LANGUAGE_KEY.PAGE_TITLE,
  LANGUAGE_KEY.PASS,
  LANGUAGE_KEY.PASS_FAIL,
  LANGUAGE_KEY.PAST_ASSIGNMENTS,
  LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS,
  LANGUAGE_KEY.PERCENT_OF_CLASS_PERFORMED_CORRECTLY,
  LANGUAGE_KEY.PLEASE_NOTE_THAT_UNASSIGNING,
  LANGUAGE_KEY.PLEASE_SELECT_AT_LEAST_ONE_CHAPTER_AND_PAGE,
  LANGUAGE_KEY.PRODUCT,
  LANGUAGE_KEY.PROFICIENT,
  LANGUAGE_KEY.PURCHASE_BINDER_READY_VERSION,
  LANGUAGE_KEY.QUESTIONS,
  LANGUAGE_KEY.QUESTION_PREVIEW_MODAL_TITLE,
  LANGUAGE_KEY.QUESTION_PREVIEW_MODAL_TYPE,
  LANGUAGE_KEY.QUIZ_TYPE,
  LANGUAGE_KEY.READING_SELECTION,
  LANGUAGE_KEY.REMOVE_NO_ITEMS_SELECTED,
  LANGUAGE_KEY.REPORT_CONTENT_ERROR,
  LANGUAGE_KEY.RESET,
  LANGUAGE_KEY.RESOURCE_LIBRARY,
  LANGUAGE_KEY.RESOURCE_TYPE,
  LANGUAGE_KEY.RESULTS_FILTERED,
  LANGUAGE_KEY.RESUME,
  LANGUAGE_KEY.SAVE_AND_COMPLETE,
  LANGUAGE_KEY.SAVE_AND_CONTINUE_TO_ADVANCED_SETTINGS,
  LANGUAGE_KEY.SCORE,
  LANGUAGE_KEY.SCORED,
  LANGUAGE_KEY.SCORES,
  LANGUAGE_KEY.SEARCH_BY_KEYWORD_CHAPTER_OR_RESOURCE_TYPE,
  LANGUAGE_KEY.SEARCH_BY_COURSE_NAME_PRODUCT_OR_ISBN,
  LANGUAGE_KEY.SEARCH_COURSE_SECTION,
  LANGUAGE_KEY.SEARCH_RESOURCES,
  LANGUAGE_KEY.SECTIONS,
  LANGUAGE_KEY.SELECT_A_COURSE_TO_COPY,
  LANGUAGE_KEY.SELECT_ALL,
  LANGUAGE_KEY.SELECT_A_MASTERY_GOAL,
  LANGUAGE_KEY.SELECT_EXISTING_COURSE_REQUIRED_ERROR,
  LANGUAGE_KEY.SET_AS_DEAFULT_TAB,
  LANGUAGE_KEY.SELF_STUDY_PROGRESS,
  LANGUAGE_KEY.SIM_ACTIONS_TOOLTIP,
  LANGUAGE_KEY.SIMULATION,
  LANGUAGE_KEY.SIMULATION_IS_LOADING,
  LANGUAGE_KEY.SKILL,
  LANGUAGE_KEY.SKILL_ATTEMPT_RESULT_CORRECT_TAB,
  LANGUAGE_KEY.SKILL_ATTEMPT_RESULT_HEADER,
  LANGUAGE_KEY.SKILL_ATTEMPT_RESULT_INCORRECT_TAB,
  LANGUAGE_KEY.START,
  LANGUAGE_KEY.START_A_QUIZ,
  LANGUAGE_KEY.START_QUIZ,
  LANGUAGE_KEY.START_YOUR_COURSE_BUILDING_BY_PROVIDING_A_NAME_FOR_YOUR_FIRST_FOLDER,
  LANGUAGE_KEY.STATUS,
  LANGUAGE_KEY.STANDARD,
  LANGUAGE_KEY.STUDENT,
  LANGUAGE_KEY.STUDENT_STUDY,
  LANGUAGE_KEY.STUDENT_ENGAGEMENT,
  LANGUAGE_KEY.STUDENT_SCORES_TITLE,
  LANGUAGE_KEY.STUDENTS_WATCHED_THIS_VIDEO,
  LANGUAGE_KEY.SUBMISSION_ATTEMPTS_TITLE,
  LANGUAGE_KEY.SUBMISSION_TIME,
  LANGUAGE_KEY.SYSTEM_REQUIREMENT_NOTE,
  LANGUAGE_KEY.THE_FOLDER_IS_EMPTY,
  LANGUAGE_KEY.THE_PAGE_NUMBERS_OUT_OF_SCOPE_ERROR,
  LANGUAGE_KEY.THE_PREVIOUS_CHANGES_YOU_MADE_ON_COURSE_PLAN_AND_THE_ASIGNMENTS_WILL_NOT_BE_PRESERVED,
  LANGUAGE_KEY.THIS_COURSE_INCLUDES_THE_FOLLOWING_PRODUCTS,
  LANGUAGE_KEY.THIS_LESSON_HAS_NOT_RELEASE_YET,
  LANGUAGE_KEY.THIS_TYPICALLY_MATCHES_YOUR_SYLLABUS_STRUCTURE,
  LANGUAGE_KEY.TIME_SPENT,
  LANGUAGE_KEY.TOAST_SUCCESS_ADD_SYLLABUS,
  LANGUAGE_KEY.TOAST_SUCCESS_MOVE_SYLLABUS,
  LANGUAGE_KEY.TOAST_SUCCESS_UPDATE_ASSIGNMENTS,
  LANGUAGE_KEY.TOAST_SUCCESS_UPDATE_SYLLABUS,
  LANGUAGE_KEY.TO_BE_UNASSIGNED,
  LANGUAGE_KEY.TOPICS,
  LANGUAGE_KEY.TOPIC_PERFORMANCE,
  LANGUAGE_KEY.TYPE,
  LANGUAGE_KEY.UNABLE_TO_DROP_EXCEED_LIMIT,
  LANGUAGE_KEY.UNABLE_TO_DROP_INTO_ITS_CHILDREN,
  LANGUAGE_KEY.UNABLE_TO_DROP_ITSELF,
  LANGUAGE_KEY.UNABLE_TO_UNASSIGN_BECAUSE_ALREADY_UNASSIGNED,
  LANGUAGE_KEY.UNABLE_TO_UNASSIGN_BECAUSE_GRADED_STARTED,
  LANGUAGE_KEY.UNASSIGN_NO_ITEMS_SELECTED,
  LANGUAGE_KEY.UPCOMING_ASSIGNMENTS,
  LANGUAGE_KEY.UPCOMING_TAB_MESSAGE,
  LANGUAGE_KEY.VIEW,
  LANGUAGE_KEY.VIEW_ALL_ATTEMPTS,
  LANGUAGE_KEY.VIEW_ATTEMPT_RESULTS,
  LANGUAGE_KEY.VIEW_DETAILS,
  LANGUAGE_KEY.VIEW_FULLSCREEN,
  LANGUAGE_KEY.VIEW_NEW_LESSONS,
  LANGUAGE_KEY.VIEW_SYSTEM_REQUIREMENTS,
  LANGUAGE_KEY.VIEW_UPCOMING_LESSONS,
  LANGUAGE_KEY.VISIBLE_ON_DATE_AT_TIME,
  LANGUAGE_KEY.VISIBLE_UPON_RELEASE,
  LANGUAGE_KEY.VIDEO_COMPLETED_RESULTS_MESSAGE,
  LANGUAGE_KEY.VIDEO_COMPLETION,
  LANGUAGE_KEY.VIDEO_COMPLETION_BASED_ON_LATEST_VIEW,
  LANGUAGE_KEY.ESTIMATED_TIME,
  LANGUAGE_KEY.WANT_TO_PROCEED,
  LANGUAGE_KEY.WARNING_MESSAGE_FOR_REMOVE_WHEN_FILTERED,
  LANGUAGE_KEY.WARNING_MESSAGE_FOR_UNABLE_TO_REMOVE_WHEN_ASSIGNMENT_STARTED,
  LANGUAGE_KEY.WARNING_MESSAGE_FOR_UNASSIGN_WHEN_FILTER,
  LANGUAGE_KEY.WARNING_MESSAGE_FOR_UNASSIGN_WHEN_THERE_IS_A_SKIPPED_ITEM,
  LANGUAGE_KEY.WARNING_MESSAGE_FOR_UPCOMING_LESSON,
  LANGUAGE_KEY.WE_NEED_TO_KNOW_A_LITTLE_MORE_ABOUT_HOW_TO_STRUCTURE_YOUR_COURSE,
  LANGUAGE_KEY.WELCOME_TO_YOUR_COURSE,
  LANGUAGE_KEY.WELCOME_USER,
  LANGUAGE_KEY.WOULD_YOU_LIKE_US_TO_AUTOMATICALLY_ADD_CONTENT_TO_YOUR_COURSE,
  LANGUAGE_KEY.YOU_CAN_ADD_REMOVE_MOVE_CONTENT_AFTER_YOUR_COURSE_IS_CREATED,
  LANGUAGE_KEY.YOUR_COURSE_PLAN_IS_EMPTY,
  LANGUAGE_KEY.YOUR_FILTERED_RESULT_IS_EMPTY,
  LANGUAGE_KEY.ASSIGNMENT_CONTENT_ID_IS_EMPTY,
  LANGUAGE_KEY.ASSIGNMENT_TITLE_IS_EMPTY,
  LANGUAGE_KEY.INVALID_AVAILABILITY_DATE,
  LANGUAGE_KEY.INVALID_DUE_DATE,
  LANGUAGE_KEY.ENTRY_EXCEEDS_LENGTH_LIMIT,
  LANGUAGE_KEY.VALUE_REQUIRED,
  LANGUAGE_KEY.COURSE_COPY_START_DATE_TOOLTIP,
  LANGUAGE_KEY.COPY_URL,
  LANGUAGE_KEY.COPY_DEEP_LINK_INSTRUCTIONS,
  LANGUAGE_KEY.COPY_DEEP_COPY_SUCCESS_TOAST,
  LANGUAGE_KEY.CLOSE,
  LANGUAGE_KEY.DEEP_LINK_CREATE_WARNING_TOAST_LINE_1,
  LANGUAGE_KEY.DEEP_LINK_CREATE_WARNING_TOAST_LINE_2,
  LANGUAGE_KEY.INVALID_URL_FORMAT,
  LANGUAGE_KEY.INVALID_URL_PROTOCOL,
  LANGUAGE_KEY.AUTHESS_MODAL_MESSAGE,
  LANGUAGE_KEY.OKAY,
  LANGUAGE_KEY.TRANSCRIPT,
  LANGUAGE_KEY.TOGGLE_TRANSCRIPT,
  LANGUAGE_KEY.GRADE_POINTS,
  LANGUAGE_KEY.EXTERNAL_LINK_TOOLTIP,
  LANGUAGE_KEY.EXTERNAL_LINK_URL_NOTE,
  LANGUAGE_KEY.LAUNCH_STUDENT_VIEW,
  LANGUAGE_KEY.STUDENT_LAUNCH_WARNING,
  LANGUAGE_KEY.INCREASE_YOUR_CONFIDENCE_FOR_YOUR_UPCOMING_EXAMS_BY_PRACTICING_MORE_QUESTIONS,
  LANGUAGE_KEY.CREATE_A_NEW_QUIZ,
  LANGUAGE_KEY.YOUVE_ANSWERED_NUM_SELF_STUDY_QUESTIONS_IN_THIS_COURSE,
  LANGUAGE_KEY.USER_INFO,
  LANGUAGE_KEY.COURSE_INFO,
  LANGUAGE_KEY.SUPPORT,
  LANGUAGE_KEY.QUESTION_SET,
  LANGUAGE_KEY.TOPIC_ORGANIZATION,
  LANGUAGE_KEY.NOVICE_EXPLAINER,
  LANGUAGE_KEY.INTERMEDIATE_EXPLAINER,
  LANGUAGE_KEY.PROFICIENT_EXPLAINER,
  LANGUAGE_KEY.EAQ_STUDENT_TOPIC_REPORT,
  LANGUAGE_KEY.TOPIC,
  LANGUAGE_KEY.MASTERY_LEVEL,
  LANGUAGE_KEY.CORRECT_PERCENTAGE,
  LANGUAGE_KEY.QUESTIONS_ANSWERED,
  LANGUAGE_KEY.QUESTIONS_CORRECT,
  LANGUAGE_KEY.QUESTIONS_CONFIDENT,
  LANGUAGE_KEY.TOTAL_TIME,
  LANGUAGE_KEY.QUIZ_ME_ON_THIS_TOPIC,
  LANGUAGE_KEY.WHAT_ARE_MASTERY_LEVELS,
  LANGUAGE_KEY.MASTERY_LEVELS,
  LANGUAGE_KEY.ALL_OPTION,
  LANGUAGE_KEY.KEEP_UP_THE_GREAT_WORK,
  LANGUAGE_KEY.COMPLETION_STATUS,
  LANGUAGE_KEY.RESUME_QUIZ,
  LANGUAGE_KEY.VIEW_RESULT,
  LANGUAGE_KEY.VIEWING,
  LANGUAGE_KEY.RESUME_QUIZ_OR_VIEW_RESULTS,
  LANGUAGE_KEY.SELECT_A_QUESTION_SET,
  LANGUAGE_KEY.CHOOSE_A_FINITE_NUMBER_OF_QUESTIONS,
  LANGUAGE_KEY.CASES_ITEMS_NEXT_GENERATION,
  LANGUAGE_KEY.SET_OF_NGN_ITEMS_ALIGNED_TO_CLINICAL_CONTENT,
  LANGUAGE_KEY.NUMBER_OF_QUESTIONS,
  LANGUAGE_KEY.SELECT_AN_OPTION,
  LANGUAGE_KEY.NOT_ENOUGH_QUESTIONS_SELECT_FEWER_QUESTIONS_OR_MORE_ASSESSMENTS,
  LANGUAGE_KEY.TOPIC_FILTER,
  LANGUAGE_KEY.MASTERY_LEVEL_ALREADY_ACHIEVED_FOR_THIS_TOPIC,
  LANGUAGE_KEY.SHERPATH_AI_MATERIALS_ACCESS,
  LANGUAGE_KEY.SHERPATH_AI_EBOOK_LABEL,
  LANGUAGE_KEY.SHERPATH_AI_MATERIALS_LABEL,
  LANGUAGE_KEY.SHERPATH_AI_NO_USER_MATERIALS,
  LANGUAGE_KEY.EMDASH
];
