import React, {
  useEffect,
  useState
} from 'react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import {
  ELSDropDown,
  ELSDropDownOption,
  ELSRadio
} from '@els/els-component-form-field-react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { orderBy } from 'lodash';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import {
  HesiExamRemediationSettingsDto,
  RecommendationDto,
  RemediationActivityTypeDto,
  RemRecContentTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import {
  ActivityTypeConfigMap,
  RemediationContentTypeConfigMap,
  RemediationGoalType,
} from './remediation-home.models';
import {
  getAssessmentGoalsTotals,
  getContentTypeDisplay,
  getContentTypesFromAssessmentGoal,
  getIncompleteRecommendationIds,
  getRecommendationsFromGoal,
  isGoalComplete,
  sortRecommendations,
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { ELSButtonIconPosition } from '../../models/button.models';
import RemediationGoalModal from './RemediationGoalModal.component';
import IsRender from '../../components/is-render/IsRender.component';
import { TotalsStatus } from './TotalsStatus.component';
import RecommendationOverview from './RecommendationOverview.component';
import { RemediationBaseState } from './RemediationBase.page';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import RemediationCompleteBox from './RemediationCompleteBox.component';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import RemediationRecommendationCard from './RemediationRecommendationCard.component';

export const RemediationActivityModalId = 'RemediationCategoryModalId';

enum ViewOptionValue {
  TOP_RECOMMENDATION = 'TOP_RECOMMENDATION',
  ALL_RECOMMENDATIONS = 'ALL_RECOMMENDATIONS'
}

const viewOptions: ELSDropDownOption[] = [{
  name: 'Top recommendation',
  value: ViewOptionValue.TOP_RECOMMENDATION
}, {
  name: 'All recommendations',
  value: ViewOptionValue.ALL_RECOMMENDATIONS
}];

enum SkipOptionValue {
  WANT_NEXT_REC = 'WANT_NEXT_REC',
  WANT_ALL_RECS = 'WANT_ALL_RECS',
  NOT_RELEVANT = 'NOT_RELEVANT',
  ALREADY_SEEN = 'ALREADY_SEEN',
  WANT_OTHER_TYPE = 'WANT_OTHER_TYPE',
  OTHER = 'OTHER',
}

const SkipOptionConfigMap: Record<SkipOptionValue, {
  label: string;
  sortOrder: number;
  hasReason: boolean;
}> = {
  [SkipOptionValue.WANT_NEXT_REC]: {
    label: 'I would like to see the next recommendation for this topic',
    sortOrder: 1,
    hasReason: false
  },
  [SkipOptionValue.WANT_ALL_RECS]: {
    label: 'I would like to see all of my content options for this topic',
    sortOrder: 2,
    hasReason: false
  },
  [SkipOptionValue.NOT_RELEVANT]: {
    label: 'The content suggested seems irrelevant to the exam I am completing review work for',
    sortOrder: 3,
    hasReason: true
  },
  [SkipOptionValue.ALREADY_SEEN]: {
    label: 'The content suggested is something I recently completed in one of my courses and do not feel the need to review it',
    sortOrder: 4,
    hasReason: false
  },
  [SkipOptionValue.WANT_OTHER_TYPE]: {
    label: 'I would prefer a different content type',
    sortOrder: 5,
    hasReason: false
  },
  [SkipOptionValue.OTHER]: {
    label: 'Other',
    sortOrder: 6,
    hasReason: true
  }
};

export type RemediationActivityModalPropsOnly = {
  baseState: RemediationBaseState;
  assessmentGoal: AssessmentGoalDto;
  handleUpdateRecommendation: (recommendation: RecommendationDto) => void;
}

const mapDispatchToProps = {
  trackAction: studyActions.trackAction,
  fetchCaseStudyAction: studyActions.fetchCaseStudyAction,
  redirect: locationActions.redirect,
  navigateToApp: studyActions.navigateToApp,
  putRemediationRecommendationAction: studyActions.putRemediationRecommendationAction,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationActivityModalProps = PropsFromRedux
  & RemediationActivityModalPropsOnly
  & ELSPropsFromToastService
  & ELSPropsFromModalService;

export type RemediationActivityModalState = {
  activeView: ViewOptionValue;
  activeRecommendationId: number;
  lastActiveNotPreferredRecommendation: number;
  preferredContentType: RemRecContentTypeDto;
  showSkipForm: boolean;
  goalRecommendations: RecommendationDto[]; // duplicate state from base state because we need to update it
  selectedRecommendation: RecommendationDto;
  selectedSkipReason: SkipOptionValue;
  selectedSkipExplanation: Partial<Record<SkipOptionValue, string>>;
}

const defaultState: RemediationActivityModalState = {
  activeView: ViewOptionValue.TOP_RECOMMENDATION,
  activeRecommendationId: null,
  lastActiveNotPreferredRecommendation: null,
  showSkipForm: false,
  preferredContentType: null,
  goalRecommendations: null,
  selectedRecommendation: null,
  selectedSkipReason: null,
  selectedSkipExplanation: {}
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const RemediationActivityModalComponent = (props: RemediationActivityModalProps) => {

  const statusCounts = getAssessmentGoalsTotals(
    {
      assessmentGoals: [props.assessmentGoal],
      activityType: props.assessmentGoal.type as RemediationActivityTypeDto,
      baseState: props.baseState
    }
  );

  const getNextRecommendations = (
    _props: {
      recommendations: RecommendationDto[];
      activeRecommendationId: number;
      nextPreferredContentType: RemRecContentTypeDto;
      direction: 'next' | 'prev';
    }
  ): {
    nextId: number;
    nextSkipReason: string;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  } => {

    const {
      recommendations,
      activeRecommendationId,
      nextPreferredContentType,
      direction
    } = _props;

    if (!recommendations || !recommendations.length) {
      return null;
    }

    const incompleteIds = getIncompleteRecommendationIds(props.baseState.recommendationStatusMap);

    const hasIncomplete = recommendations.some((rec) => {
      return incompleteIds.includes(rec.id);
    });

    const sorted = sortRecommendations(
      recommendations,
      props.baseState.assessment.assessmentGoals
    );

    if (direction === 'prev') {
      const validRecs = sorted.filter((rec) => {
        return !hasIncomplete || incompleteIds.includes(rec.id);
      });
      const idx = validRecs.findIndex((rec) => {
        return rec.id === activeRecommendationId;
      });
      const nextIdx = idx - 1 < 0 ? validRecs.length - 1 : idx - 1;
      return {
        nextId: validRecs[nextIdx].id,
        nextSkipReason: validRecs[nextIdx].skipReason,
      };
    }

    if (_props.activeRecommendationId && direction === 'next') {
      const validRecs = sorted.filter((rec) => {
        return !hasIncomplete || incompleteIds.includes(rec.id);
      });
      const idx = validRecs.findIndex((rec) => {
        return rec.id === activeRecommendationId;
      });
      if (idx !== -1) {
        const nextIdx = idx + 1 > validRecs.length - 1 ? 0 : idx + 1;
        const nextRec = validRecs[nextIdx];
        if (nextRec && nextRec.skipReason) {
          return {
            nextId: validRecs[nextIdx].id,
            nextSkipReason: validRecs[nextIdx].skipReason,
          };
        }
      }
    }

    const isValid = (rec: RecommendationDto) => {
      return (!activeRecommendationId || activeRecommendationId !== rec.id)
        && (!hasIncomplete || incompleteIds.includes(rec.id));
    };

    let nextRec = null;

    nextRec = sorted.find((rec) => {
      return isValid(rec)
        && rec.contentType === nextPreferredContentType
        && !rec.skipReason;
    });

    if (!nextRec) {
      // If nothing found, try again without the skip reason check
      nextRec = sorted.find((rec) => {
        return isValid(rec)
          && rec.contentType === nextPreferredContentType;
      });
    }

    if (!nextRec) {
      // If nothing found, try again without nextPreferredContentType and skip reason
      nextRec = sorted.find((rec) => {
        return isValid(rec)
          && !rec.skipReason;
      });
    }

    if (!nextRec) {
      // If nothing found, take the first available
      nextRec = sorted.find((rec) => {
        return isValid(rec);
      });
    }

    return {
      nextId: nextRec ? nextRec.id : null,
      nextSkipReason: nextRec ? nextRec.skipReason : null
    };
  };

  const [state, setState] = useState<RemediationActivityModalState>(defaultState);

  const mergeState = (newState: Partial<RemediationActivityModalState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const getRecommendation = (id: number) => {
    if (!state.goalRecommendations) {
      return null;
    }
    return state.goalRecommendations.find((rec) => {
      return rec.id === id;
    });
  };
  const getIsRecommendationPreferred = (recommendationId: number, contentType: RemRecContentTypeDto) => {
    const activeRec = getRecommendation(recommendationId);
    if (!activeRec) {
      return false;
    }
    return activeRec.contentType === contentType;
  };

  useEffect(() => {
    const goalRecommendations = getRecommendationsFromGoal(props.baseState.recommendations, props.assessmentGoal);
    setState((prevState) => {
      return {
        ...prevState,
        goalRecommendations,
        activeRecommendationId: getNextRecommendations({
          recommendations: goalRecommendations,
          activeRecommendationId: null,
          nextPreferredContentType: null,
          direction: 'next'
        }).nextId,
        activeView: (statusCounts.completed >= statusCounts.total || goalRecommendations.length === statusCounts.total)
          ? ViewOptionValue.ALL_RECOMMENDATIONS
          : ViewOptionValue.TOP_RECOMMENDATION
      };
    });
  }, []);

  const handleCancelClick = () => {
    props.modalService.closeModal(RemediationActivityModalId);
  };

  const handleSkipSubmit = () => {

    const {
      putRemediationRecommendationAction,
      toastService
    } = props;

    if (!state.selectedSkipReason) {
      toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: <div>Please select a skip reason</div>,
        type: ELSWithToastService.types.NEGATIVE
      });
      return;
    }

    const activeRecommendation = getRecommendation(state.activeRecommendationId);
    const updatedRecommendation = {
      ...activeRecommendation,
      skipReason: state.selectedSkipReason,
    };

    if (state.selectedSkipExplanation[state.selectedSkipReason]) {
      updatedRecommendation.skipExplanation = state.selectedSkipExplanation[state.selectedSkipReason];
    }

    let preferredContentType: RemRecContentTypeDto = null;
    if (state.selectedSkipReason === SkipOptionValue.WANT_OTHER_TYPE) {
      preferredContentType = state.selectedSkipExplanation[SkipOptionValue.WANT_OTHER_TYPE] as RemRecContentTypeDto;
    }

    putRemediationRecommendationAction(updatedRecommendation).then((recommendation) => {
      props.handleUpdateRecommendation(recommendation);
      setState((prevState) => {

        const updatedRecommendations = prevState.goalRecommendations.map((rec) => {
          if (rec.id === recommendation.id) {
            return { ...recommendation };
          }
          return rec;
        });

        const nextRecommendations = getNextRecommendations({
          recommendations: updatedRecommendations,
          activeRecommendationId: prevState.activeRecommendationId,
          nextPreferredContentType: preferredContentType,
          direction: 'next'
        });

        return {
          ...prevState,
          activeRecommendationId: nextRecommendations.nextId,
          preferredContentType,
          showSkipForm: false,
          selectedSkipReason: null,
          selectedSkipExplanation: {},
          activeView: updatedRecommendation.skipReason === SkipOptionValue.WANT_ALL_RECS
            ? ViewOptionValue.ALL_RECOMMENDATIONS
            : prevState.activeView,
          goalRecommendations: updatedRecommendations,
        };
      });
    });
  };

  const getAlternateContentTypesOptions = (activeRecommendation: RecommendationDto): ELSDropDownOption[] => {
    const hesiSettings = props.baseState.remediationAssignmentWithSources.remediationAssignment.settings as HesiExamRemediationSettingsDto;
    const activeActivityType: HesiExamRemediationSettingsDto['activityTypes'][0] = hesiSettings.activityTypes.find((activityType) => {
      return activityType.activityType === props.assessmentGoal.type;
    });

    if (!activeActivityType || !activeRecommendation) {
      return [];
    }

    const contentTypes = activeActivityType.contentSettings.filter((contentType) => {
      return contentType.isActive && activeRecommendation.contentType !== contentType.contentType;
    });

    return contentTypes.map((contentType) => {
      return {
        name: RemediationContentTypeConfigMap[contentType.contentType].displayName,
        value: contentType.contentType,
      };
    });
  };
  const handleNextRecClick = () => {

    if (!state.activeRecommendationId) {
      return;
    }

    const activeRecommendation = getRecommendation(state.activeRecommendationId);

    if (activeRecommendation && !activeRecommendation.skipReason) {
      const options = getAlternateContentTypesOptions(activeRecommendation);
      mergeState({
        showSkipForm: true,
        selectedSkipReason: null,
        selectedSkipExplanation: {
          [SkipOptionValue.WANT_OTHER_TYPE]: options.length > 0 ? options[0].value as string : null
        },
      });
      return;
    }

    const nextRecommendations = getNextRecommendations({
      recommendations: state.goalRecommendations,
      activeRecommendationId: state.activeRecommendationId,
      nextPreferredContentType: null,
      direction: 'next'
    });

    mergeState({
      activeRecommendationId: nextRecommendations.nextId,
      preferredContentType: null,
    });
  };

  const handlePrevRecClick = () => {
    const nextRecommendations = getNextRecommendations({
      recommendations: state.goalRecommendations,
      activeRecommendationId: state.activeRecommendationId,
      nextPreferredContentType: null,
      direction: 'prev'
    });

    mergeState({
      activeRecommendationId: nextRecommendations.nextId,
      preferredContentType: null,
    });
  };

  const handleLaunch = (selectedRecommendation: RecommendationDto) => {
    mergeState({ selectedRecommendation });
  };

  const getSkipOptions = (): ELSDropDownOption[] => {
    return orderBy(Object.keys(SkipOptionConfigMap), (key: SkipOptionValue) => {
      return SkipOptionConfigMap[key].sortOrder;
    }).map((key: SkipOptionValue) => {
      return {
        name: SkipOptionConfigMap[key].label,
        value: key,
      };
    });
  };

  const handleSkipExplanationChange = (skipReason: SkipOptionValue, explanation: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedSkipExplanation: {
          ...prevState.selectedSkipExplanation,
          [skipReason]: explanation
        }
      };
    });
  };

  const isPrevRecDisabled = (): boolean => {

    const nextRecommendation = getNextRecommendations({
      recommendations: state.goalRecommendations,
      activeRecommendationId: state.activeRecommendationId,
      nextPreferredContentType: null,
      direction: 'prev'
    });

    return !nextRecommendation
      || !nextRecommendation.nextId
      || !nextRecommendation.nextSkipReason;
  };

  const showPreferredError = () => {
    return state.preferredContentType
      && !getIsRecommendationPreferred(state.activeRecommendationId, state.preferredContentType);
  };

  const contentTypes = getContentTypesFromAssessmentGoal(props.assessmentGoal);
  const { goalType } = ActivityTypeConfigMap[props.assessmentGoal.type];
  const activeRecommendation = getRecommendation(state.activeRecommendationId);
  const alternativeContentTypeOptions = getAlternateContentTypesOptions(activeRecommendation);

  return (
    <RemediationGoalModal
      handleCancelClick={handleCancelClick}
      assessmentGoal={props.assessmentGoal}>

      {
        isGoalComplete(statusCounts) && (
          <RemediationCompleteBox activityType={props.assessmentGoal.type as RemediationActivityTypeDto} />
        )
      }

      {
        state.selectedRecommendation && (
          <RecommendationOverview
            baseState={props.baseState}
            recommendation={state.selectedRecommendation}
            handleReturnNavigation={
              () => {
                mergeState({ selectedRecommendation: null });
              }
            }
          />
        )
      }

      {
        !state.selectedRecommendation && (
          <div>

            <table className="c-ssa-remediation-goal-modal__goal-table">
              <tbody>
                <tr>
                  <td>
                    <IconWithText iconName="syllabus" iconPrefix="hmds">Instructions</IconWithText>
                  </td>
                  <td>

                    <IsRender isRender={statusCounts.completed < props.assessmentGoal.goal && goalType === RemediationGoalType.TAXON}>
                      Review <strong>{props.assessmentGoal.goal} {getContentTypeDisplay(contentTypes)}</strong> for this topic.
                      We have retrieved relevant review content based on the questions you have missed in this topic.
                    </IsRender>

                    <IsRender isRender={statusCounts.completed < props.assessmentGoal.goal && goalType === RemediationGoalType.CONTENT_TYPE}>
                      Complete <strong>{props.assessmentGoal.goal} {getContentTypeDisplay(contentTypes)}</strong> to complete requirements for this section.
                    </IsRender>

                    <IsRender isRender={statusCounts.completed >= props.assessmentGoal.goal}>
                      <h4>All available {ActivityTypeConfigMap[props.assessmentGoal.type].title.toLowerCase()} content</h4>
                    </IsRender>
                  </td>
                </tr>
                <tr>
                  <td>
                    <IconWithText iconName="statistics" iconPrefix="gizmo">Progress</IconWithText>
                  </td>
                  <td>
                    <FlexLayout modifiers={[
                      FlexLayoutModifier.LEFT,
                      FlexLayoutModifier.GUTTERS_1o2,
                      FlexLayoutModifier.MIDDLE
                    ]}>
                      <FlexItem>
                        <TotalsStatus completedGoal={props.assessmentGoal.goal} completed={statusCounts.completed} total={statusCounts.total} />
                      </FlexItem>
                      <FlexItem>
                        <IsRender isRender={goalType === RemediationGoalType.TAXON}>
                          {getContentTypeDisplay(contentTypes)} completed in this topic
                        </IsRender>

                        <IsRender isRender={goalType === RemediationGoalType.CONTENT_TYPE}>
                          {getContentTypeDisplay(contentTypes)} requirements met
                        </IsRender>
                      </FlexItem>
                    </FlexLayout>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="o-els-container c-els-divider u-els-color-n0" />

            {
              statusCounts.completed < statusCounts.total
              && state.goalRecommendations
              && state.goalRecommendations.length > statusCounts.total
              && (
                <div className="o-els-container">
                  <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.RIGHT]}>
                    <FlexItem>

                      <FlexLayout modifiers={[
                        FlexLayoutModifier.GUTTERS,
                        FlexLayoutModifier.MIDDLE,
                      ]}>
                        <FlexItem>
                          Currently viewing:
                        </FlexItem>
                        <FlexItem>
                          <ELSDropDown
                            value={state.activeView}
                            options={viewOptions}
                            changeHandler={() => {
                              mergeState({
                                showSkipForm: false,
                                activeView: state.activeView === ViewOptionValue.TOP_RECOMMENDATION
                                  ? ViewOptionValue.ALL_RECOMMENDATIONS
                                  : ViewOptionValue.TOP_RECOMMENDATION
                              });
                            }}
                          />
                        </FlexItem>
                      </FlexLayout>
                    </FlexItem>
                  </FlexLayout>
                  {
                    state.activeView === ViewOptionValue.TOP_RECOMMENDATION
                    && !state.showSkipForm
                    && (
                      <div className="o-els-container">
                        <h4>
                          We suggest you start by reviewing:
                        </h4>
                      </div>
                    )
                  }
                </div>
              )
            }

            {
              state.showSkipForm && state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                <div className="c-ssa-remediation-goal-modal__card-wrap">
                  <div className="c-ssa-remediation-goal-modal__skip-form">
                    <div className="o-els-container">Why did you skip the previous recommendation?</div>
                    {getSkipOptions().map((option) => {
                      const isChecked = state.selectedSkipReason === option.value;
                      return (
                        <div
                          className="o-els-container"
                          key={option.value}
                        >
                          <ELSRadio
                            changeHandler={(e, value) => {
                              setState((prevState) => {
                                return {
                                  ...prevState,
                                  selectedSkipReason: value,
                                };
                              });
                            }}
                            name={option.value as string}
                            value={option.value}
                            checked={isChecked}
                          >
                            {option.name}
                          </ELSRadio>

                          {
                            isChecked
                            && option.value === SkipOptionValue.WANT_OTHER_TYPE
                            && alternativeContentTypeOptions.length > 0
                            && (
                              <div className="o-els-container u-els-margin-left-2x">
                                <ELSDropDown
                                  value={activeRecommendation.skipExplanation}
                                  options={alternativeContentTypeOptions}
                                  changeHandler={(e, value) => {
                                    handleSkipExplanationChange(option.value as SkipOptionValue, value);
                                  }}
                                >
                                  Preferred content type:
                                </ELSDropDown>
                              </div>
                            )
                          }

                          {
                            isChecked && SkipOptionConfigMap[option.value].hasReason && (
                              <div className="o-els-container u-els-margin-left-2x">
                                <div className="c-els-field">
                                  <textarea
                                    value={state.selectedSkipExplanation[option.value] || ''}
                                    className="c-els-field__input"
                                    placeholder="Please explain..."
                                    onChange={(e) => {
                                      handleSkipExplanationChange(option.value as SkipOptionValue, e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          }
                        </div>
                      );
                    })}

                  </div>
                  <div className="u-els-margin-top">
                    <FlexLayout modifiers={[FlexLayoutModifier.RIGHT]}>
                      <FlexItem>
                        <button
                          type="button"
                          className="c-els-button c-els-button--small"
                          onClick={() => {
                            handleSkipSubmit();
                          }}
                        >
                          Submit
                        </button>
                      </FlexItem>
                    </FlexLayout>
                  </div>
                </div>
              )
            }

            {
              !state.showSkipForm
              && !showPreferredError()
              && state.activeView === ViewOptionValue.TOP_RECOMMENDATION
              && (
                <div className="c-ssa-remediation-goal-modal__card-wrap">

                  {activeRecommendation && (
                    <div className="o-els-container">
                      <RemediationRecommendationCard
                        recommendation={activeRecommendation}
                        recommendationAttempts={props.baseState.recommendationAttempts}
                        isExpanded
                        handleLaunch={handleLaunch}
                        launchButtonText="View details"
                        banner={null}
                      />
                    </div>
                  )}

                  <div className="o-els-container">
                    <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.MIDDLE]}>
                      <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                        {
                          !isPrevRecDisabled() && (
                            <button
                              type="button"
                              className="u-els-anchorize"
                              onClick={handlePrevRecClick}
                            >
                              <IconWithText
                                iconName="arrow-left"
                                iconPrefix="gizmo"
                                iconPosition={ELSButtonIconPosition.LEFT}
                              >
                                Previous recommendation
                              </IconWithText>
                            </button>
                          )
                        }
                      </FlexItem>
                      <FlexItem>
                        <button
                          type="button"
                          className="u-els-anchorize"
                          onClick={handleNextRecClick}
                        >
                          <IconWithText
                            iconName="arrow-right"
                            iconPrefix="gizmo"
                            iconPosition={ELSButtonIconPosition.RIGHT}
                          >
                            Next recommendation
                          </IconWithText>
                        </button>
                      </FlexItem>
                    </FlexLayout>
                  </div>
                </div>
              )
            }

            {
              showPreferredError()
              && state.activeView === ViewOptionValue.TOP_RECOMMENDATION
              && (
                <div className="c-ssa-remediation-goal-modal__card-wrap">
                  <div className="c-ssa-remediation-goal-modal__skip-form c-ssa-remediation-goal-modal__skip-form--warning">
                    We’re sorry, we do not have any relevant&nbsp;
                    {RemediationContentTypeConfigMap[state.preferredContentType].titlePlural.toLowerCase()}
                    &nbsp;in this topic.
                    <div className="u-els-margin-top">
                      <FlexLayout>
                        <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                          {
                            !isPrevRecDisabled() && (
                              <button
                                type="button"
                                className="u-els-anchorize"
                                onClick={handlePrevRecClick}
                              >
                                <IconWithText
                                  iconName="arrow-left"
                                  iconPrefix="gizmo"
                                  iconPosition={ELSButtonIconPosition.LEFT}
                                >
                                  Previous recommendation
                                </IconWithText>
                              </button>
                            )
                          }
                        </FlexItem>
                        <FlexItem>
                          <button
                            type="button"
                            className="u-els-anchorize"
                            onClick={() => {
                              mergeState({
                                preferredContentType: null
                              });
                            }}
                          >
                            <IconWithText
                              iconName="arrow-right"
                              iconPrefix="gizmo"
                              iconPosition={ELSButtonIconPosition.RIGHT}
                            >
                              Next recommendation
                            </IconWithText>
                          </button>
                        </FlexItem>
                      </FlexLayout>
                    </div>
                  </div>
                </div>
              )
            }

            {
              state.activeView === ViewOptionValue.ALL_RECOMMENDATIONS && (
                <div className="o-els-container o-els-container--2x">
                  <div className="c-ssa-remediation-goal-modal__list">
                    {state.goalRecommendations && sortRecommendations(state.goalRecommendations, props.baseState.assessment.assessmentGoals)
                      .map((recommendation) => {
                        return (
                          <div
                            key={recommendation.id}
                            className="c-ssa-remediation-goal-modal__list-item"
                          >
                            <RemediationRecommendationCard
                              recommendationAttempts={props.baseState.recommendationAttempts}
                              recommendation={recommendation}
                              isExpanded
                              handleLaunch={handleLaunch}
                              launchButtonText="View details"
                              banner={null}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )
            }

            {
              state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                <div className="u-els-margin-top-3x">
                  <IconWithText
                    iconName="information"
                    iconPrefix="gizmo">
                    Learn more about how we&apos;ve personalized your plan and review content suggestions.
                  </IconWithText>
                </div>
              )
            }

          </div>
        )
      }

    </RemediationGoalModal>
  );
};

const enhancers = [
  connector,
  ELSWithModalService,
  ELSWithToastService,
];

const RemediationActivityModal = compose<null, RemediationActivityModalPropsOnly>(...enhancers)(RemediationActivityModalComponent);

export default RemediationActivityModal;
