import React, { useState } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { ELSPageHeader } from '@els/els-component-shared-ts-react';
import cx from 'classnames';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import { RoutePath } from '../../components/app/app.constants';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { locationSelectors } from '../../redux/location/location.selectors';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { RemediationBaseState } from './RemediationBase.page';
import RemediationActivityProgress from './RemediationActivityProgress.component';
import {
  getAssessmentGoalFromRecommendation,
  getAssessmentGoalsItemsTotals,
  getAttemptById,
  getGoalFromRecommendation,
  getNextRecommendation,
  getRecommendationFromAttempt,
  getRecommendationTitle,
  getRemediationActivityTypeDtoFromRecommendation,
  getTotalProgress,
  handleResumeOrStartNewAttempt,
} from './remediation-home.utilities';
import {
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDto,
  RemediationActivityTypeDto,
  RemRecContentTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { ELSButtonIconPosition } from '../../models/button.models';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { RemediationContentTypeConfigMap } from './remediation-home.models';
import RecommendationPostPlayerNext from './RemediationPostPlayerNext.component';
import RemediationPostPlayerMessage from './RemediationPostPlayerMessage.component';
import RemediationQuiz from './RemediationQuiz.component';

type RecommendationPostPlayerPropsOnly = {
  baseState: RemediationBaseState;
}

const mapDispatchToProps = {
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  replaceURL: locationActions.replaceURL,
  navigateToApp: studyActions.navigateToApp,
  postAssessmentAction: studyActions.postAssessmentAction,
  postRemediationRecommendationAttemptAction: studyActions.postRemediationRecommendationAttemptAction,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  userId: studySelectors.getUserId(state),
  courseSectionId: studySelectors.getCourseSectionId(state),
  location: locationSelectors.getLocation(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

enum RecommendationPostPlayerStep {
  RETURN_STATUS = 'RETURN_STATUS',
  NEXT_RECOMMENDATION = 'NEXT_RECOMMENDATION',
  CONTINUE_QUIZZING = 'CONTINUE_QUIZZING',
}

type RecommendationPostPlayerState = {
  step: RecommendationPostPlayerStep;
}

const defaultState = {
  step: RecommendationPostPlayerStep.RETURN_STATUS
};

export type RecommendationPostPlayerProps = PropsFromRedux & RecommendationPostPlayerPropsOnly & ELSPropsFromModalService;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RecommendationPostPlayerComponent = (props: RecommendationPostPlayerProps) => {

  const [state, setState] = useState<RecommendationPostPlayerState>(defaultState);
  const mergeState = (newState: Partial<RecommendationPostPlayerState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const {
    redirect,
    baseState,
    location
  } = props;
  const handleReturnNavigation = () => {
    props.replaceURL(RoutePath.REMEDIATION_BASE);
  };

  const getNewAttemptBtnText = (recommendation: RecommendationDto, attempt: RecommendationAttemptDto) => {
    if (recommendation.contentType === RemRecContentTypeDto.RAAS_QUIZ) {
      return 'Continue quizzing';
    }
    if (
      [
        RecommendationAttemptStatusDto.COMPLETED_PASSED,
        RecommendationAttemptStatusDto.COMPLETED_FAILED
      ].includes(attempt.status)
    ) {
      return 'Start new attempt';
    }
    return 'Resume attempt';
  };

  const attempt = getAttemptById(baseState.recommendationAttempts, location.query.recommendationAttemptId);
  const recommendation = getRecommendationFromAttempt(baseState.recommendations, attempt);

  if (!recommendation) {
    return null;
  }

  const activityType = recommendation.assessmentGoalType;
  const assessmentGoal = getAssessmentGoalFromRecommendation(baseState.assessment, recommendation);
  const totalProgress = getTotalProgress(baseState);
  const nextRecommendation = getNextRecommendation(baseState, assessmentGoal);
  const statusCounts = getAssessmentGoalsItemsTotals(
    {
      assessmentGoals: [assessmentGoal],
      activityType,
      baseState: props.baseState,
    }
  );
  if (!recommendation) {
    return null;
  }
  const showNextRecBtn = totalProgress < 100 && !!nextRecommendation;

  return (
    <div className="c-ssa-remediation-post-player">
      <div className="c-ssa-remediation-post-player__header">
        <ELSPageHeader onCloseClick={handleReturnNavigation} title="Study Assignment" />
      </div>

      <div className="c-ssa-remediation-post-player__body">

        {
          totalProgress >= 100 && state.step === RecommendationPostPlayerStep.RETURN_STATUS && (
            <div className="c-ssa-remediation-post-player____complete_window">
              <div className="c-ssa-remediation-post-player____complete_window_progress">
                <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
                  <FlexItem>
                    <RemediationActivityProgress
                      assessmentGoals={props.baseState.assessment.assessmentGoals}
                      baseState={props.baseState}
                      activityType={RemediationActivityTypeDto.REVIEW_BY_TAXON}
                    />
                  </FlexItem>
                  <FlexItem>
                    <RemediationActivityProgress
                      assessmentGoals={props.baseState.assessment.assessmentGoals}
                      baseState={props.baseState}
                      activityType={RemediationActivityTypeDto.APPLY_BY_CONTENT_TYPE}
                    />
                  </FlexItem>
                  <FlexItem>
                    <RemediationActivityProgress
                      assessmentGoals={props.baseState.assessment.assessmentGoals}
                      baseState={props.baseState}
                      activityType={RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE}
                    />
                  </FlexItem>
                </FlexLayout>
              </div>
            </div>
          )
        }

        <div className="c-ssa-remediation-post-player__window">

          {
            state.step === RecommendationPostPlayerStep.RETURN_STATUS && (

              <div>

                {
                  totalProgress >= 100 && (
                    <>
                      <div className="u-els-text-center">
                        <div className="u-els-font-size-h2">&#127881;</div>
                        <div className="u-els-font-size-h2">Congratulations!</div>
                        <div className="u-els-font-size-h4">All required work for your Personalized Learning Plan is complete!</div>
                      </div>
                      <div className="o-els-container o-els-container--2x c-els-divider" />
                    </>

                  )
                }

                {
                  totalProgress < 100 && (
                    <div className="c-ssa-remediation-post-player__progress-bar">
                      <RemediationActivityProgress
                        assessmentGoals={props.baseState.assessment.assessmentGoals}
                        baseState={props.baseState}
                        activityType={activityType}
                      />
                    </div>
                  )
                }

                {
                  recommendation.contentType !== RemRecContentTypeDto.RAAS_QUIZ && (
                    <div className="u-els-text-center">
                      <div>
                        <strong>Activity details</strong>: {getRecommendationTitle(recommendation)} &bull; {RemediationContentTypeConfigMap[recommendation.contentType].displayName}
                      </div>
                      {getRemediationActivityTypeDtoFromRecommendation(recommendation) === RemediationActivityTypeDto.REVIEW_BY_TAXON && (
                        <div><strong>Topic</strong>: {getGoalFromRecommendation(baseState.assessment.assessmentGoals, recommendation).text}</div>
                      )}
                    </div>
                  )
                }

                <div className="u-els-text-center o-els-container o-els-container--2x">
                  <RemediationPostPlayerMessage
                    recommendation={recommendation}
                    attempt={attempt}
                    appLinkData={props.appLinkData}
                    baseState={baseState}
                  />
                </div>
              </div>
            )
          }

          {
            state.step === RecommendationPostPlayerStep.CONTINUE_QUIZZING && (
              <div>
                <div className="o-els-container">
                  <FlexLayout>
                    <FlexItem>
                      <button
                        type="button"
                        className="u-els-anchorize"
                        onClick={() => {
                          mergeState({ step: RecommendationPostPlayerStep.RETURN_STATUS });
                        }}
                      >
                        <IconWithText
                          iconName="arrow-left"
                          iconPrefix="gizmo"
                          iconPosition={ELSButtonIconPosition.LEFT}
                        >
                          Go back
                        </IconWithText>
                      </button>
                    </FlexItem>
                  </FlexLayout>
                </div>

                <div className="o-els-container">
                  <RemediationQuiz
                    assessmentGoal={assessmentGoal}
                    baseState={baseState}
                    recommendation={recommendation}
                    statusCounts={statusCounts}
                    showReviewQuizButton={false}
                  />
                </div>
              </div>
            )
          }

          {
            state.step === RecommendationPostPlayerStep.NEXT_RECOMMENDATION && (
              <div>
                <div className="o-els-container">
                  <FlexLayout>
                    <FlexItem>
                      <button
                        type="button"
                        className="u-els-anchorize"
                        onClick={() => {
                          mergeState({ step: RecommendationPostPlayerStep.RETURN_STATUS });
                        }}
                      >
                        <IconWithText
                          iconName="arrow-left"
                          iconPrefix="gizmo"
                          iconPosition={ELSButtonIconPosition.LEFT}
                        >
                          Go back
                        </IconWithText>
                      </button>
                    </FlexItem>
                  </FlexLayout>
                </div>
                <h3 className="u-els-text-center">Next recommendation</h3>
                <div className="o-els-container">
                  <RecommendationPostPlayerNext
                    baseState={baseState}
                    nextRecommendation={nextRecommendation}
                  />
                </div>
              </div>
            )
          }

          <div className="u-els-margin-top-2x">
            <FlexLayout modifiers={[
              FlexLayoutModifier.GUTTERS,
              FlexLayoutModifier.CENTER,
            ]}>
              <FlexItem>
                <button
                  type="button"
                  className="c-els-button c-els-button--secondary c-els-button--small"
                  onClick={() => redirect(RoutePath.REMEDIATION_BASE)}
                >
                  Back to Personalized Learning Plan
                </button>
              </FlexItem>
              <FlexItem isRender={state.step === RecommendationPostPlayerStep.RETURN_STATUS}>
                <button
                  type="button"
                  className={
                    cx({
                      'c-els-button c-els-button--small': true,
                      'c-els-button--secondary': showNextRecBtn,
                      'c-els-button--primary': !showNextRecBtn
                    })
                  }
                  onClick={() => {
                    if (recommendation.contentType === RemRecContentTypeDto.RAAS_QUIZ) {
                      mergeState({ step: RecommendationPostPlayerStep.CONTINUE_QUIZZING });
                      return;
                    }
                    handleResumeOrStartNewAttempt({
                      recommendation,
                      recommendationAttempt: attempt,
                      postAssessmentAction: props.postAssessmentAction,
                      postRemediationRecommendationAttemptAction: props.postRemediationRecommendationAttemptAction,
                      baseState,
                      redirect: props.redirect,
                      appLinkData: props.appLinkData,
                      appLinkCookies: props.appLinkCookies,
                      navigateToApp: props.navigateToApp,
                      userId: props.userId,
                    });
                  }}
                >
                  {
                    getNewAttemptBtnText(recommendation, attempt)
                  }
                </button>
              </FlexItem>
              <FlexItem isRender={
                state.step === RecommendationPostPlayerStep.RETURN_STATUS && showNextRecBtn
              }>
                <button
                  type="button"
                  className="c-els-button c-els-button--primary c-els-button--small"
                  onClick={() => {
                    mergeState({ step: RecommendationPostPlayerStep.NEXT_RECOMMENDATION });
                  }}
                >
                  See next recommendation
                </button>
              </FlexItem>
            </FlexLayout>
          </div>

        </div>
      </div>

    </div>
  );
};

const enhancers = [
  connector,
  ELSWithModalService
];

const RecommendationPostPlayer = compose<null, RecommendationPostPlayerPropsOnly>(...enhancers)(RecommendationPostPlayerComponent);

export default RecommendationPostPlayer;
