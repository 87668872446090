import { ReactNode } from 'react';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { IconPrefixes } from '../../constants/hmds.constants';
import { RecommendationAttemptStatusDto } from '../../apis/florence-facade/florence-facade.dtos';

export type TotalsStatusProps = {
  status: RecommendationAttemptStatusDto;
  children: ReactNode;
}
export const StatusPill = (props: TotalsStatusProps) => {

  if (props.status === RecommendationAttemptStatusDto.COMPLETED_PASSED) {
    return (
      <span className="u-els-display-inline-block c-els-pill u-els-color-confirm u-els-background-color-confirm-background">
        <IconWithText
          iconName="checkmark"
          iconPrefix={IconPrefixes.HMDS}
          iconSize="3o4"
        >
          {props.children}
        </IconWithText>
      </span>
    );
  }

  if (props.status === RecommendationAttemptStatusDto.COMPLETED_FAILED) {
    return (
      <span className="u-els-display-inline-block c-els-pill u-els-color-error u-els-background-color-error-background">
        {props.children}
      </span>
    );
  }

  if (props.status === RecommendationAttemptStatusDto.NOT_STARTED) {
    return (
      <span className="u-els-display-inline-block c-els-pill u-els-color-n9 u-els-background-color-n0">
        {props.children}
      </span>
    );
  }

  return (
    <span className="u-els-display-inline-block c-els-pill u-els-color-info u-els-background-color-info-background">
      {props.children}
    </span>
  );
};
