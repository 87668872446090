import {
  buildUrlFromBase,
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import {
  AuthessAssignmentDto,
  BookTaxonomyKindDto,
  CourseISBNDto,
  EaqQuestionSetDto,
  EaqRecommenderQuestionsDto,
  EaqStudentTopicPerformanceDto,
  EaqTaxonomyDto,
  EaqTopicDto,
  StudentStudyDto
} from './eaq-app-facade-service.dtos';

import { addSearchParams } from '../../utilities/app.utilities';
import {
  AssessmentDto,
  AssessmentPerformanceDetailsDto,
  AssignmentDto
} from '../eols-assessment-service/eols-assessment-service.dtos';
import { EaqAssessmentConfig } from '../../pages/eaq-student-topic-report/eaq-student-topic-report.models';

export const fetchStudentStudyAssessments = (courseSectionId?: string): Promise<StudentStudyDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<StudentStudyDto[]>(
      addSearchParams('/eaq/selfStudy/assessment', { courseSectionId })
    );
  });
};

export const fetchStudentPerformanceDetails = (assessmentId: number): Promise<AssessmentPerformanceDetailsDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/assessment/${assessmentId}/results`);
  });
};

export const fetchEaqAssignment = (assignmentId: number): Promise<AssignmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/assignment/${assignmentId}`);
  });
};

export const fetchEaqAssessment = (assessmentId: number, studentUserId?: string): Promise<AssessmentDto> => {
  // * studentUserId is used to override the userId in the token, allowing instructors to request assessments for their students
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/eaq/assessment/${assessmentId}`, { studentUserId }));
  });
};

export const fetchNumberOfQuestions = (eaqRecommenderQuestions: Partial<EaqRecommenderQuestionsDto>): Promise<number> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<number>(
      '/eaq/recommender/questions/search',
      {
        data: eaqRecommenderQuestions
      }
    );
  });
};

export const fetchQuestionSets = (userId: string, courseSectionId: string): Promise<EaqQuestionSetDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<EaqQuestionSetDto[]>(
      addSearchParams(`/eaq/eolsUser/${userId}/products/questionSets`, { courseSectionId })
    );
  });
};

export const fetchStudentTopicPerformances = (isbn: string): Promise<EaqStudentTopicPerformanceDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<EaqStudentTopicPerformanceDto[]>(`/eaq/assessment/mastery/${isbn}`);
  });
};

export const eaqTaxonomy = (isbn: string, bookTaxonomyKind = BookTaxonomyKindDto.ALL): Promise<EaqTaxonomyDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/recommender/isbn/${isbn}/taxonomies?bookTaxonomyKind=${bookTaxonomyKind}`);
  });
};

export const eaqTopicOrganization = (eaqISBN: string, topicId: string, courseId: number | null): Promise<EaqTopicDto> => {
  if (courseId === null) {
    return withApiErrorLoggingOnly(() => {
      return eolsBaseApi.get(`/eaq/assessment/mastery/${eaqISBN}/topic/${topicId}`);
    });
  }
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/assessment/mastery/${eaqISBN}/topic/${topicId}/${courseId}`);
  });
};

export const fetchIsbns = (courseSectionId: string, userId): Promise<CourseISBNDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/eaq/eolsUser/${userId}/products/questionSets`, { courseSectionId }));
  });
};

export const fetchAuthessAssignments = (isbn: string): Promise<AuthessAssignmentDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/eaq/authess/assignments', { isbn }));
  });
};

export const createAssessment = (assessmentConfig: EaqAssessmentConfig) => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/eaq/assessment', { data: assessmentConfig });
  });
};

// * - Assessment Player API
export const getEaqNextQuestionApi = (assessmentId: number): string =>
  buildUrlFromBase(`/eaq/assessment/${assessmentId}/question`);

// * - Assessment Player API
export const submitEaqQuestionApi = (assessmentId: number): string =>
  buildUrlFromBase(`/eaq/assessment/${assessmentId}/question`);
