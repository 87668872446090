import { orderBy } from 'lodash';
import { ELSCommonConfig } from '@els/els-ui-common-react';
import {
  HesiExamRemediationSettingsDto,
  QuestionSubmissionDetailsDto,
  QuizSessionDto,
  QuizSessionQuestionDto,
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDto,
  RecommendationItemDto,
  RemediationActivityTypeDto,
  RemediationAssignmentDto,
  RemRecContentTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  AssessmentPlayerAppLinkOutBody,
  CaseStudyAppLinkOutBody,
  RecommendationItemStatusMap,
  RecommendationStatusMap,
  RemediationContentTypeConfigMap,
  RemediationStatusCountLabel,
  RemediationStatusCounts,
  StatusMapConfig
} from './remediation-home.models';
import {
  AssessmentDto,
  AssessmentGoalDto,
  AssessmentSubmissionDto,
  AssessmentTypeDto,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { RemediationBaseState } from './RemediationBase.page';
import {
  AppLinkCookies,
  AppLinkData
} from '../../apis/eols-app-link/eols-app-link.dtos';
import { RoutePath } from '../../components/app/app.constants';
import {
  AppAction,
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import { NavigateToApp } from '../../redux/student-study/studentStudy.models';
import { addSearchParams, getParentLinkId } from '../../utilities/app.utilities';

export const isRecommendationPartOfGoal = (recommendation: RecommendationDto, assessmentGoal: AssessmentGoalDto): boolean => {
  if (!recommendation || !assessmentGoal) {
    return false;
  }
  return recommendation.assessmentGoalVtwId === assessmentGoal.vtwId && recommendation.assessmentGoalType === assessmentGoal.type;
};

export const getRecommendationsFromGoal = (recommendations: RecommendationDto[], assessmentGoal: AssessmentGoalDto): RecommendationDto[] => {
  return recommendations.filter((recommendation) => {
    return isRecommendationPartOfGoal(recommendation, assessmentGoal);
  });
};

export const getAttemptById = (recommendationAttempts: RecommendationAttemptDto[], id: number) => {
  if (!recommendationAttempts) {
    return null;
  }

  return recommendationAttempts.find((attempt) => {
    return attempt.id === Number(id);
  });
};

export const getIncompleteAttempts = (
  recommendationAttempts: RecommendationAttemptDto[],
  recommendation: RecommendationDto
) => {
  if (!recommendationAttempts || !recommendation) {
    return null;
  }
  return recommendationAttempts.filter((attempt) => {
    return attempt.remediationRecommendationId === recommendation.id
      && attempt.status !== RecommendationAttemptStatusDto.COMPLETED_PASSED
      && attempt.status !== RecommendationAttemptStatusDto.COMPLETED_FAILED;
  });
};

export const getAssessmentSubmissionByAttempt = (attempt: RecommendationAttemptDto, submissions: AssessmentSubmissionDto[]): AssessmentSubmissionDto => {
  if (!submissions) {
    return null;
  }
  return submissions.find((submission) => {
    return submission.assessmentId === attempt.assessmentId;
  });
};

export const getRecommendationFromAttempt = (recommendations: RecommendationDto[], attempt: RecommendationAttemptDto): RecommendationDto => {
  if (!recommendations || !attempt) {
    return null;
  }
  return recommendations.find((recommendation) => {
    return recommendation.id === attempt.remediationRecommendationId;
  });
};

const getItemsFromRecommendation = (recommendationId: number, config: StatusMapConfig): RecommendationItemDto[] => {
  return config.recommendationItems.filter((item) => {
    return item.remediationRecommendationId === recommendationId;
  });
};

export const getAttemptsFromRecommendation = (recommendationId: number, attempts: RecommendationAttemptDto[]): RecommendationAttemptDto[] => {
  if (!attempts) {
    return null;
  }
  return attempts.filter((item) => {
    return item.remediationRecommendationId === recommendationId;
  });
};

const getResultsFromRecommendationAttempt = (recommendationAttemptId: number, config: StatusMapConfig): QuestionSubmissionDetailsDto[] => {
  return config.quizSessionResults.filter((item) => {
    return item.recommendationAttemptId === recommendationAttemptId;
  });
};
export const getTotalCompletedPassed = (recommendation: RecommendationDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(recommendation.id, config.recommendationAttempts);
  return attempts.reduce((acc, attempt) => {
    if (
      attempt
      && attempt.status === RecommendationAttemptStatusDto.COMPLETED_PASSED
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const getTotalAttempted = (recommendation: RecommendationDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(recommendation.id, config.recommendationAttempts);
  return attempts.reduce((acc, attempt) => {
    if (
      attempt
      && [
        RecommendationAttemptStatusDto.COMPLETED_PASSED,
        RecommendationAttemptStatusDto.COMPLETED_FAILED
      ].includes(attempt.status)
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const generateRecommendationStatusMap = (
  config: StatusMapConfig
): RecommendationStatusMap => {

  if (!config) {
    return null;
  }

  return config.recommendations.reduce((acc, recommendation) => {
    return {
      ...acc,
      [recommendation.id]: {
        completed: getTotalCompletedPassed(recommendation, config),
        total: 1,
        attempted: getTotalAttempted(recommendation, config),
        label: RemediationStatusCountLabel.COMPLETED,
      }
    };
  }, {});

};

export const getTotalItemCorrect = (item: RecommendationItemDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(item.remediationRecommendationId, config.recommendationAttempts);

  if (!attempts) {
    return 0;
  }
  return attempts.reduce((acc, attempt) => {
    return getResultsFromRecommendationAttempt(attempt.id, config).reduce((_acc, result) => {
      return result.questions.reduce((__acc, question) => {
        if (question.questionVtwId === item.itemId && question.correct) {
          return __acc + 1;
        }
        return __acc;
      }, _acc);
    }, acc);
  }, 0);
};

export const getTotalItemAttempted = (item: RecommendationItemDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(item.remediationRecommendationId, config.recommendationAttempts);

  if (!attempts) {
    return 0;
  }
  return attempts.reduce((acc, attempt) => {
    return getResultsFromRecommendationAttempt(attempt.id, config).reduce((_acc, result) => {
      return result.questions.reduce((__acc, question: QuizSessionQuestionDto) => {
        if (question.questionVtwId === item.itemId) {
          return __acc + 1;
        }
        return __acc;
      }, _acc);
    }, acc);
  }, 0);
};

export const generateRecommendationItemStatusMap = (
  config: StatusMapConfig
): RecommendationItemStatusMap => {

  if (!config) {
    return null;
  }

  return config.recommendations.reduce((acc, recommendation) => {

    const items = getItemsFromRecommendation(recommendation.id, config);

    if (!items) {
      return {
        ...acc,
        [recommendation.id]: null
      };
    }

    return {
      ...acc,
      [recommendation.id]: items.reduce((_acc, item) => {

        const totals: RemediationStatusCounts = {
          completed: getTotalItemCorrect(item, config) > 0 ? 1 : 0,
          total: 1,
          attempted: getTotalItemAttempted(item, config) > 0 ? 1 : 0,
          label: RemediationStatusCountLabel.CORRECT,
          goal: null
        };

        return {
          ..._acc,
          [item.id]: totals
        };
      }, {})
    };
  }, {});

};

export const getHourAndMinuteFromSecond = (second: number): string => {

  if (second === 0) {
    return '0 min';
  }

  let minute = Math.floor(second / 60);
  let hour = 0;

  if (minute === 0) {
    return '1 min';
  }

  if (minute >= 60) {
    hour = Math.floor(minute / 60);
    minute -= 60 * hour;

    if (minute === 0) {
      return `${hour} hr`;
    }

    return `${hour} hr ${minute} min`;
  }

  return `${minute} min`;
};

export const getAssessmentGoalsTotals = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityTypeDto;
    baseState: RemediationBaseState;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    baseState,
  } = props;

  const defaultTotal: RemediationStatusCounts = {
    total: 0,
    completed: 0,
    attempted: 0,
    goal: 0,
    label: RemediationStatusCountLabel.COMPLETED
  };

  if (!baseState || !assessmentGoals) {
    return defaultTotal;
  }

  return assessmentGoals.reduce((acc, assessmentGoal: AssessmentGoalDto) => {

    if (!assessmentGoal) {
      return acc;
    }

    if (assessmentGoal.type !== activityType) {
      return acc;
    }

    return baseState.recommendations.reduce((_acc, rec) => {

      if (RemediationContentTypeConfigMap[rec.contentType].activityType !== activityType) {
        return _acc;
      }

      if (rec.assessmentGoalVtwId !== assessmentGoal.vtwId) {
        return _acc;
      }

      if (!baseState.recommendationStatusMap) {
        return _acc;
      }

      // const status = getStatusCounts(rec)
      const status = baseState.recommendationStatusMap[rec.id];

      if (!status) {
        return _acc;
      }

      const completedAdder = status.completed > 0 ? 1 : 0;

      const newCompleted = _acc.completed + completedAdder;
      const newAttempted = _acc.attempted + status.attempted;

      return {
        ..._acc,
        total: _acc.total,
        completed: newCompleted < _acc.total ? newCompleted : _acc.total,
        attempted: newAttempted,
        label: RemediationStatusCountLabel.COMPLETED
      };
    }, {
      ...acc,
      total: acc.total + assessmentGoal.goal,
      goal: acc.total + assessmentGoal.goal
    });
  }, defaultTotal);
};

export const getQuizPassNumber = (remediationAssignment: RemediationAssignmentDto, items: RecommendationItemDto[]): number => {

  if (!items || !items.length) {
    return null;
  }

  if (!remediationAssignment || !remediationAssignment.settings || !remediationAssignment.settings.activityTypes) {
    return items.length;
  }

  const assessActivityTypeConfig = remediationAssignment.settings.activityTypes.find((activityType: HesiExamRemediationSettingsDto['activityTypes'][0]) => {
    return activityType.activityType === RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE;
  });

  if (!assessActivityTypeConfig) {
    return items.length;
  }

  const contentSettingConfig = assessActivityTypeConfig.contentSettings.find((contentSetting) => {
    return contentSetting.contentType === RemRecContentTypeDto.RAAS_QUIZ;
  });

  if (!contentSettingConfig) {
    return items.length;
  }

  return Math.round(contentSettingConfig.minScoreToPass * items.length);

};

export const getContentTypePassNumber = (recommendation: RecommendationDto, baseState: RemediationBaseState): number => {

  const {
    remediationAssignmentWithSources,
    recommendationItems
  } = baseState;

  if (!recommendation) {
    return null;
  }

  if (
    !remediationAssignmentWithSources
    || !remediationAssignmentWithSources.remediationAssignment
    || !remediationAssignmentWithSources.remediationAssignment.settings
    || !remediationAssignmentWithSources.remediationAssignment.settings.activityTypes
  ) {
    return null;
  }

  const { remediationAssignment } = remediationAssignmentWithSources;

  if (recommendation.contentType === RemRecContentTypeDto.RAAS_QUIZ) {
    return getQuizPassNumber(remediationAssignment, recommendationItems);
  }

  const activityTypeConfig = remediationAssignment.settings.activityTypes.find((_activityTypeConfig: HesiExamRemediationSettingsDto['activityTypes'][0]) => {
    // TODO: fix this after https://elsevier.atlassian.net/browse/FLOR-1615
    return _activityTypeConfig.activityType.includes(RemediationContentTypeConfigMap[recommendation.contentType].activityType);
  });

  if (!activityTypeConfig) {
    return null;
  }

  const contentSettingConfig = activityTypeConfig.contentSettings.find((contentSetting) => {
    return contentSetting.contentType === recommendation.contentType;
  });

  if (!contentSettingConfig) {
    return null;
  }

  return contentSettingConfig.minScoreToPass;

};

export const getAssessmentGoalsItemsTotals = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityTypeDto;
    baseState: RemediationBaseState;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    baseState
  } = props;

  const defaultTotal: RemediationStatusCounts = {
    total: 0,
    completed: 0,
    attempted: 0,
    goal: 0,
    label: RemediationStatusCountLabel.CORRECT
  };

  if (!assessmentGoals) {
    return defaultTotal;
  }

  return assessmentGoals.reduce((acc, assessmentGoal) => {

    if (!assessmentGoal) {
      return acc;
    }

    if (assessmentGoal.type !== activityType) {
      return acc;
    }

    return baseState.recommendations.reduce((_acc, rec) => {

      if (!rec) {
        return _acc;
      }

      if (RemediationContentTypeConfigMap[rec.contentType].activityType !== activityType) {
        return _acc;
      }

      if (!isRecommendationPartOfGoal(rec, assessmentGoal)) {
        return _acc;
      }

      if (!baseState.recommendationItems) {
        return _acc;
      }

      const items = baseState.recommendationItems.filter((item) => {
        return item.remediationRecommendationId && item.remediationRecommendationId === rec.id;
      });

      return items.reduce((__acc, item) => {

        const status = baseState.recommendationItemStatusMap[rec.id][item.id];

        if (!status) {
          return __acc;
        }

        const newCompleted = __acc.completed + status.completed;
        const newAttempted = __acc.attempted + status.attempted;

        return {
          ...__acc,
          total: __acc.total,
          completed: newCompleted < __acc.total ? newCompleted : __acc.total,
          attempted: newAttempted,
          label: RemediationStatusCountLabel.CORRECT
        };
      }, {
        ..._acc,
        total: _acc.total + items.length,
        goal: getQuizPassNumber(baseState.remediationAssignmentWithSources.remediationAssignment, baseState.recommendationItems)
      });

    }, acc);
  }, defaultTotal);
};

export const getAssessmentGoalsTotalsCombined = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    baseState: RemediationBaseState;
    activityType: RemediationActivityTypeDto;
  }
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    baseState,
    activityType
  } = props;

  if (props.activityType === RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE) {
    return getAssessmentGoalsItemsTotals(
      {
        assessmentGoals,
        baseState,
        activityType,
      }
    );
  }

  return getAssessmentGoalsTotals({
    assessmentGoals,
    activityType,
    baseState
  });
};

export const getProgressDisplay = (totals: RemediationStatusCounts): string => {
  return `${totals.completed}/${totals.total} ${totals.label}`;
};

export const getContentTypesFromActivityType = (activityType: RemediationActivityTypeDto): RemRecContentTypeDto[] => {
  return Object.keys(RemRecContentTypeDto).filter((contentType: RemRecContentTypeDto) => {
    return RemediationContentTypeConfigMap[contentType] && RemediationContentTypeConfigMap[contentType].activityType === activityType;
  }) as RemRecContentTypeDto[];
};

export const getContentTypeDisplay = (contentTypes: RemRecContentTypeDto[]) => {
  return orderBy(contentTypes, (contentType) => RemediationContentTypeConfigMap[contentType].sortOrder)
    .map((contentType: RemRecContentTypeDto) => {
      return RemediationContentTypeConfigMap[contentType].titlePlural;
    }).join(' & ');
};

export const getContentTypesFromAssessmentGoal = (assessmentGoal: AssessmentGoalDto): RemRecContentTypeDto[] => {
  return getContentTypesFromActivityType(assessmentGoal.type as RemediationActivityTypeDto);
};

export const getRecommendationStatus = (props: {
  recommendationAttempts: RecommendationAttemptDto[];
  recommendation: RecommendationDto;
}): RecommendationAttemptStatusDto => {
  const {
    recommendationAttempts,
    recommendation
  } = props;
  if (!recommendation || !recommendationAttempts) {
    return RecommendationAttemptStatusDto.NOT_STARTED;
  }
  const attempts = recommendationAttempts
    .filter((attempt) => {
      return attempt.remediationRecommendationId === recommendation.id;
    });

  if (!attempts.length) {
    return RecommendationAttemptStatusDto.NOT_STARTED;
  }

  const completedAttempt = attempts
    .find((attempt) => {
      return attempt.status === RecommendationAttemptStatusDto.COMPLETED_PASSED;
    });
  if (completedAttempt) {
    return RecommendationAttemptStatusDto.COMPLETED_PASSED;
  }
  return RecommendationAttemptStatusDto.IN_PROGRESS;
};

export const getQuizSessionResults = (
  baseState: RemediationBaseState,
  outPostBody: AssessmentPlayerAppLinkOutBody
): {
  totalQuestions: number;
  totalCorrect: number;
} => {

  if (!baseState || !baseState.quizSessionResults) {
    return null;
  }

  if (!outPostBody.quizSessionId) {
    return null;
  }

  const {
    quizSessionId
  } = outPostBody;

  const { quizSessionResults } = baseState;

  if (!quizSessionResults || !quizSessionResults.length) {
    return null;
  }

  const quizSessionResult = quizSessionResults.find((result) => {
    return result.quizSessionId === quizSessionId;
  });

  if (!quizSessionResult) {
    return null;
  }
  const totalQuestions = quizSessionResult.questions.length;
  const totalCorrect = quizSessionResult.questions.filter((question) => question.correct).length;
  return {
    totalQuestions,
    totalCorrect
  };
};

export const isQuizComplete = (statusCounts: RemediationStatusCounts): boolean => {
  if (!statusCounts) {
    return false;
  }
  return statusCounts.goal <= statusCounts.completed && statusCounts.total <= statusCounts.attempted;
};

export const isGoalComplete = (statusCounts: RemediationStatusCounts) => {
  if (!statusCounts || !statusCounts.goal) {
    return false;
  }
  return statusCounts.completed >= statusCounts.goal;
};

export const getGoalFromRecommendation = (assessmentGoals: AssessmentGoalDto[], recommendation: RecommendationDto): AssessmentGoalDto => {
  if (!recommendation || !assessmentGoals) {
    return null;
  }
  return assessmentGoals.find((goal) => {
    return goal.vtwId === recommendation.assessmentGoalVtwId && goal.type === recommendation.assessmentGoalType;
  });
};

export const getAssessmentGoalFromRecommendation = (assessment: AssessmentDto, recommendation: RecommendationDto): AssessmentGoalDto => {
  if (!assessment) {
    return null;
  }
  return getGoalFromRecommendation(assessment.assessmentGoals, recommendation);
};

type NavigateToContentProps = {
  navigateToApp: NavigateToApp;
  recommendation: RecommendationDto;
  recommendationAttempt: RecommendationAttemptDto;
  appLinkData: AppLinkData;
  appLinkCookies: AppLinkCookies;
  assessment: AssessmentDto;
  quizSession: QuizSessionDto;
  redirect: (path: string) => void;
}
const handleNavigateToCaseStudyApp = (props: NavigateToContentProps) => {

  const {
    navigateToApp,
    recommendation,
    appLinkData,
    appLinkCookies,
    recommendationAttempt,
    assessment
  } = props;

  const outPostBody: CaseStudyAppLinkOutBody = {
    assessmentVtwId: recommendation.contentId,
    instanceId: `RAAS_ATTEMPT_${recommendationAttempt.id}`,
    ref: RoutePath.REMEDIATION_BASE,
    raasAssessmentId: assessment.id,
    recommendationId: props.recommendation.id,
    recommendationAttemptId: recommendationAttempt.id,
  };

  navigateToApp({
    action: AppAction.ASSESSMENT_START,
    app: Application.CASE_STUDY_APP,
    body: outPostBody,
    altSrcApp: Application.STUDENT_STUDY,
    includeLinkHash: true,
    parentLinkId: getParentLinkId(appLinkData, appLinkCookies)
  });
};

const handleNavigateToQuizApp = (props: NavigateToContentProps) => {
  const {
    quizSession,
    recommendation,
    recommendationAttempt,
    navigateToApp,
    appLinkData,
    appLinkCookies,
    assessment
  } = props;

  const outPostBody: AssessmentPlayerAppLinkOutBody = {
    ref: RoutePath.REMEDIATION_BASE,
    quizSessionId: quizSession.id,
    assessmentId: quizSession.eolsAssessmentId,
    raasAssessmentId: assessment.id,
    recommendationId: recommendation.id,
    recommendationAttemptId: recommendationAttempt.id,
    getNextQuestionAPI: `${ELSCommonConfig.buildUrl}/api/florence/remediation/quiz-session/next-question?assessmentId=${quizSession.eolsAssessmentId}`,
    submitQuestionAPI: `${ELSCommonConfig.buildUrl}/api/florence/remediation/quiz-session/submit-question`,
    assessmentPlayerConfig: {
      navigationType: 'Submit-Continue', // 'Next-Back',
      strictStudyMode: true
    }
  };

  navigateToApp({
    action: AppAction.ASSESSMENT_START,
    app: Application.NHE_ASSESSMENT_PLAYER,
    body: outPostBody,
    includeLinkHash: true,
    parentLinkId: getParentLinkId(appLinkData, appLinkCookies)
  });
};
export const handleNavigateToContent = (props: NavigateToContentProps) => {
  const {
    recommendation,
    recommendationAttempt,
    redirect
  } = props;

  if (recommendation.contentType === RemRecContentTypeDto.RAAS_QUIZ) {
    handleNavigateToQuizApp(props);
    return;
  }

  if (recommendation.contentType === RemRecContentTypeDto.HESI_CASE_STUDY) {
    handleNavigateToCaseStudyApp(props);
  }

  if (recommendation.contentType === RemRecContentTypeDto.OSMOSIS_VIDEO) {
    redirect(addSearchParams(RoutePath.OSMOSIS_PLAYER, {
      recommendationAttemptId: recommendationAttempt.id,
      recommendationId: recommendationAttempt.remediationRecommendationId,
    }));
  }

  if (recommendation.contentType === RemRecContentTypeDto.EBOOK_READING) {
    redirect(addSearchParams(RoutePath.EBOOK_PLAYER, {
      recommendationAttemptId: recommendationAttempt.id,
      recommendationId: recommendationAttempt.remediationRecommendationId,
    }));
  }

};

export const sortAssessmentGoals = (assessmentGoals: AssessmentGoalDto[]): AssessmentGoalDto[] => {
  const goalTypeOrder: Record<RemediationActivityTypeDto, number> = {
    [RemediationActivityTypeDto.REVIEW_BY_TAXON]: 1,
    [RemediationActivityTypeDto.APPLY_BY_CONTENT_TYPE]: 2,
    [RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE]: 3
  };
  return orderBy(assessmentGoals, [(goal) => {
    return goalTypeOrder[goal.type];
  }, 'goal', 'text'], ['asc', 'desc', 'asc']);
};

export const sortRecommendations = (recommendations: RecommendationDto[], assessmentGoals: AssessmentGoalDto[]): RecommendationDto[] => {
  const sortedGoals = sortAssessmentGoals(assessmentGoals);
  return orderBy(recommendations, [(recommendation) => {
    return sortedGoals.findIndex((goal) => {
      return isRecommendationPartOfGoal(recommendation, goal);
    });
  }, 'sortOrder', 'id'], ['asc', 'asc', 'asc']);
};

export const getIncompleteRecommendationIds = (
  recommendationStatusMap: RecommendationStatusMap,
): number[] => {
  if (!recommendationStatusMap) {
    return null;
  }
  return Object.keys(recommendationStatusMap).reduce((acc, key) => {
    if (recommendationStatusMap[key].completed >= recommendationStatusMap[key].total) {
      return acc;
    }
    return [...acc, parseInt(key, 10)];
  }, []);
};

export const getIncompleteRecommendations = (
  recommendationStatusMap: RecommendationStatusMap,
  recommendations: RecommendationDto[]
): RecommendationDto[] => {
  const incompleteRecommendationKeys: number[] = getIncompleteRecommendationIds(recommendationStatusMap);

  if (!incompleteRecommendationKeys || !incompleteRecommendationKeys.length) {
    return null;
  }
  return recommendations.filter((recommendation) => {
    return incompleteRecommendationKeys.includes(recommendation.id);
  });
};

export const getNextRecommendation = (baseState: RemediationBaseState, assessmentGoal: AssessmentGoalDto): RecommendationDto => {
  if (!baseState || !baseState.recommendations || !baseState.assessment) {
    return null;
  }

  const incompleteGoals = baseState.assessment.assessmentGoals.filter((goal) => {
    const statusCounts = getAssessmentGoalsTotalsCombined({
      assessmentGoals: [goal],
      activityType: goal.type as RemediationActivityTypeDto,
      baseState,
    });

    if (goal.type === RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE) {
      return !isQuizComplete(statusCounts);
    }
    return !isGoalComplete(statusCounts);
  });

  if (!incompleteGoals || !incompleteGoals.length) {
    return null;
  }

  const incompleteRecommendations = getIncompleteRecommendations(baseState.recommendationStatusMap, baseState.recommendations);

  if (!incompleteRecommendations || !incompleteRecommendations.length) {
    return null;
  }

  const incompleteRecommendationsByGoal = incompleteRecommendations.filter((recommendation) => {
    return getGoalFromRecommendation(incompleteGoals, recommendation);
  });

  if (!incompleteRecommendationsByGoal || !incompleteRecommendationsByGoal.length) {
    return null;
  }

  if (assessmentGoal) {
    const currentGoalRecommendations = incompleteRecommendationsByGoal.filter((recommendation) => {
      return isRecommendationPartOfGoal(recommendation, assessmentGoal);
    });
    if (currentGoalRecommendations && currentGoalRecommendations.length) {
      return sortRecommendations(currentGoalRecommendations, baseState.assessment.assessmentGoals)[0];
    }
  }

  return sortRecommendations(incompleteRecommendationsByGoal, baseState.assessment.assessmentGoals)[0];
};

export const getRecommendationContentId = (recommendation: RecommendationDto): string => {
  if (!recommendation) {
    return null;
  }
  return recommendation.contentId;
};

export const getRecommendationTitle = (recommendation: RecommendationDto): string => {
  if (!recommendation) {
    return null;
  }
  if (!recommendation.title) {
    return `Missing Title: Recommendation ${recommendation.id}`;
  }
  return recommendation.title;
};

export const getNewAttemptAssessment = (
  recommendation: RecommendationDto
): Partial<AssessmentDto> => {
  const contentId = getRecommendationContentId(recommendation);
  if (!recommendation || !contentId) {
    return null;
  }
  return {
    assessmentGoals: [{
      goal: 1,
      text: recommendation.contentType,
      vtwId: contentId
    }],
    assessmentTopics: [],
    courseSectionId: recommendation.courseSectionId,
    type: AssessmentTypeDto.STUDENT_STUDY,
    contentId
  };
};

export const getTotalProgress = (baseState: RemediationBaseState): number => {
  if (!baseState || !baseState.assessment) {
    return 0;
  }
  const percents = [
    RemediationActivityTypeDto.REVIEW_BY_TAXON,
    RemediationActivityTypeDto.APPLY_BY_CONTENT_TYPE,
    RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE
  ].map((activityType) => {
    const activityTotals = getAssessmentGoalsTotalsCombined({
      assessmentGoals: baseState.assessment.assessmentGoals,
      activityType,
      baseState
    });

    if (!activityTotals.total) {
      return 0;
    }

    return Math.round((activityTotals.completed / activityTotals.total) * 100);
  });

  const total = percents.reduce((acc, percent) => {
    return acc + percent;
  }, 0);

  return Math.round(total / percents.length);
};

export const getRemediationActivityTypeDtoFromRecommendation = (recommendation: RecommendationDto): RemediationActivityTypeDto => {
  if (!recommendation || !recommendation.assessmentGoalType) {
    return null;
  }
  return recommendation.assessmentGoalType.replace('::', '_BY_') as RemediationActivityTypeDto;
};

export const handleResumeOrStartNewAttempt = (
  props: {
    recommendation: RecommendationDto;
    recommendationAttempt: RecommendationAttemptDto;
    postAssessmentAction: (userId: string, assessment: Partial<AssessmentDto>) => Promise<AssessmentDto>;
    postRemediationRecommendationAttemptAction: (recommendationAttempt: Partial<RecommendationAttemptDto>) => Promise<RecommendationAttemptDto>;
    baseState: RemediationBaseState;
    userId: string;
    redirect: (path: string) => void;
    appLinkData: AppLinkData;
    appLinkCookies: AppLinkCookies;
    navigateToApp: NavigateToApp;
  }
) => {

  const {
    postAssessmentAction,
    userId,
    postRemediationRecommendationAttemptAction,
    recommendationAttempt,
    baseState,
    recommendation,
    redirect,
    appLinkData,
    appLinkCookies,
    navigateToApp
  } = props;

  if (recommendationAttempt && [
    RecommendationAttemptStatusDto.NOT_STARTED,
    RecommendationAttemptStatusDto.IN_PROGRESS
  ].includes(recommendationAttempt.status)) {
    handleNavigateToContent({
      recommendationAttempt,
      recommendation,
      assessment: baseState.assessment,
      navigateToApp: props.navigateToApp,
      appLinkCookies: props.appLinkCookies,
      appLinkData: props.appLinkData,
      quizSession: null,
      redirect
    });
    return;
  }

  const incompleteAttempts = getIncompleteAttempts(baseState.recommendationAttempts, recommendation);

  if (incompleteAttempts && incompleteAttempts.length) {
    handleNavigateToContent({
      recommendationAttempt: incompleteAttempts[0],
      recommendation,
      assessment: baseState.assessment,
      navigateToApp: props.navigateToApp,
      appLinkCookies: props.appLinkCookies,
      appLinkData: props.appLinkData,
      quizSession: null,
      redirect
    });
    return;
  }

  const newAssessment = getNewAttemptAssessment(recommendation);

  if (!newAssessment) {
    // eslint-disable-next-line no-alert
    alert('unable to construct new assessment');
    return;
  }

  postAssessmentAction(userId, newAssessment).then((assessment) => {
    postRemediationRecommendationAttemptAction({
      assessmentId: assessment.id,
      remediationRecommendationId: recommendation.id,
      status: RecommendationAttemptStatusDto.IN_PROGRESS
    }).then((newAttempt) => {
      handleNavigateToContent({
        recommendationAttempt: newAttempt,
        recommendation,
        assessment: baseState.assessment,
        navigateToApp,
        appLinkCookies,
        appLinkData,
        quizSession: null,
        redirect
      });
    });
  });
};
