import React, { ReactNode } from 'react';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  RecommendationAttemptDto,
  RecommendationDto
} from '../../apis/florence-facade/florence-facade.dtos';
import { TimeEstimateDisplay } from './TimeEstimateDisplay.component';
import { ContentTypeDisplay } from './ContentTypeDisplay.component';
import { getRecommendationStatus } from './remediation-home.utilities';
import { RecommendationAttemptStatusDisplayMap } from './remediation-home.constants';
import { StatusPill } from './StatusPill.component';
import { RemediationContentTypeConfigMap } from './remediation-home.models';

export type RemediationRecommendationCardProps = {
  recommendation: RecommendationDto;
  recommendationAttempts: RecommendationAttemptDto[];
  isExpanded: boolean;
  handleLaunch: (recommendation: RecommendationDto) => void;
  launchButtonText: string;
  banner: ReactNode;
};

const RemediationRecommendationCard = (props: RemediationRecommendationCardProps) => {

  const {
    recommendation
  } = props;

  if (!recommendation) {
    return null;
  }

  const getTopics = () => {
    return null;
  };

  const getSubtitle = () => {
    return null;
  };

  const status = getRecommendationStatus({
    recommendation: props.recommendation,
    recommendationAttempts: props.recommendationAttempts
  });

  const contentTypeConfig = RemediationContentTypeConfigMap[recommendation.contentType];

  return (
    <div className={`c-ssa-recommendation-card c-ssa-recommendation-card--${contentTypeConfig.activityType.toLowerCase()}`}>

      {
        props.banner && (
          <div className="c-ssa-recommendation-card__banner">
            {props.banner}
          </div>
        )
      }

      <div className="c-ssa-recommendation-card__main">
        <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
          <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
            <h4>{recommendation.title}</h4>
          </FlexItem>
          <FlexItem classes={['u-els-nowrap', 'u-els-color-n7']}>
            <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.LEFT]}>
              <FlexItem>
                <ContentTypeDisplay contentType={recommendation.contentType} />
              </FlexItem>
              <FlexItem>
                <TimeEstimateDisplay timeEstimateDuration={recommendation.estimatedTime} />
              </FlexItem>
            </FlexLayout>
          </FlexItem>
        </FlexLayout>

        {props.isExpanded && (
          <div className="u-els-margin-top">

            {getSubtitle() && (
              <div className="o-els-container">
                {getSubtitle()}
              </div>
            )}

            <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.BOTTOM]}>
              <FlexItem>
                <StatusPill status={status}>
                  {RecommendationAttemptStatusDisplayMap[status]}
                </StatusPill>
              </FlexItem>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                {getTopics() && (
                  <div>
                    <div>Topics covered:</div>
                    {
                      getTopics().map((topic) => {
                        return (
                          <div key={topic}>{topic}</div>
                        );
                      })
                    }
                  </div>
                )}
              </FlexItem>
              <FlexItem>
                <button
                  type="button"
                  className="c-els-button c-els-button--small"
                  onClick={() => props.handleLaunch(props.recommendation)}
                >
                  {props.launchButtonText}
                </button>
              </FlexItem>
            </FlexLayout>
          </div>
        )}
      </div>
    </div>
  );
};
export default RemediationRecommendationCard;
