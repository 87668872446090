import React, {} from 'react';
import { compose } from 'recompose';
import { ELSPageHeader } from '@els/els-component-shared-ts-react';
import { ELSButton } from '@els/els-component-button-react';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import {
  RecommendationDataBookDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { RoutePath } from '../../components/app/app.constants';
import { addSearchParams } from '../../utilities/app.utilities';
import withRecommendationPlayer, { RecommendationPlayerProps } from '../../hocs/with-recommendation-player/withRecommendationPlayer.hoc';

export type EbookPlayerProps = RecommendationPlayerProps;

export const EbookPlayerPage = (props: EbookPlayerProps) => {

  const handleReturnNavigation = () => {
    return props.redirect(addSearchParams(RoutePath.REMEDIATION_BASE, {
      isReturn: true,
      recommendationAttemptId: props.recommendationAttempt.id
    }));
  };

  const {
    recommendation,
    recommendationAttempt
  } = props;

  if (!recommendation || !recommendationAttempt) {
    return null;
  }

  const ebookData = recommendation.recommendationData as unknown as RecommendationDataBookDto;

  return (
    <div className="c-scm-ebook-player">
      <header className="c-scm-ebook-player__header">
        <ELSPageHeader onCloseClick={handleReturnNavigation} title="Ebook Player" />
      </header>

      <div className="c-scm-ebook-player__body">
        <div className="c-scm-ebook-player__page">
          <div className="o-els-container">
            <ELSButton
              onClick={() => props.handleSubmitAttempt(0)}
            >
              Complete readings
            </ELSButton>
          </div>
          <div className="o-els-container o-els-container--2x">
            <div className="o-els-container">
              <h3>Title</h3>
              <h4>{ebookData.source.title}</h4>
            </div>
            <div className="o-els-container">
              <h3>ISBN</h3>
              {ebookData.source.source_id}
            </div>
            <div className="o-els-container">
              <h3>Path</h3>
              {
                ebookData.source.bread_crumbs.map((item) => {
                  return item.title;
                }).join(' > ')
              }
            </div>
            <div className="o-els-container">
              <h3>Page(s)</h3>
              {ebookData.source.content_props.page.start_page.number} - {ebookData.source.content_props.page.end_page.number}
            </div>
            <div className="o-els-container">
              <h3>Chunk text</h3>
              {ebookData.source.chunk_text}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

const enhancers = [
  withHTMLHeadSEO({ title: 'Ebook Player' }),
  withRecommendationPlayer,
];

const EbookPlayer = compose<null, null>(...enhancers)(EbookPlayerPage);

export default EbookPlayer;
