import moment from 'moment';
import { getHourAndMinuteFromSecond } from './remediation-home.utilities';
import IconWithText from '../../components/icon-with-text/IconWithText.component';

export type TimeEstimateDisplayProps = {
  timeEstimateDuration: string;
}
export const TimeEstimateDisplay = (props: TimeEstimateDisplayProps) => {
  return (
    <IconWithText
      iconName="clock"
      iconPrefix="gizmo"
    >
      <span>
        est.
        {getHourAndMinuteFromSecond(moment.duration(props.timeEstimateDuration).asSeconds())}
      </span>
    </IconWithText>
  );
};
