import React, {
  useEffect,
  useState
} from 'react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import {
  ELSDropDown,
  ELSDropDownOption,
} from '@els/els-component-form-field-react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { ELSCommonConfig } from '@els/els-ui-common-react';
import {
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDto,
  RemediationActivityTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  getAssessmentGoalsItemsTotals,
  getAttemptsFromRecommendation,
  handleNavigateToContent,
  isQuizComplete,
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  getParentLinkId,
  SELECT_OPTION
} from '../../utilities/app.utilities';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { TotalsStatus } from './TotalsStatus.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { RemediationBaseState } from './RemediationBase.page';
import { RemediationStatusCounts } from './remediation-home.models';
import RemediationCompleteBox from './RemediationCompleteBox.component';
import {
  AppAction,
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import { RoutePath } from '../../components/app/app.constants';

type RemediationQuizPropsOnly = {
  baseState: RemediationBaseState;
  assessmentGoal: AssessmentGoalDto;
  recommendation: RecommendationDto;
  statusCounts: RemediationStatusCounts;
  showReviewQuizButton: boolean;
}

const mapDispatchToProps = {
  postRemediationRecommendationAttemptAction: studyActions.postRemediationRecommendationAttemptAction,
  postRaaSQuizSessionAction: studyActions.postRaaSQuizSessionAction,
  navigateToApp: studyActions.navigateToApp,
  redirect: locationActions.redirect,
  trackAction: studyActions.trackAction,
};
const mapStateToProps = state => ({
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  appLinkData: studySelectors.getLinkData(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationQuizProps = PropsFromRedux
  & RemediationQuizPropsOnly
  & ELSPropsFromModalService
  & ELSPropsFromToastService;

export type RemediationQuizState = {
  selectedQuizOption: string | number;
  recommendationAttempt: RecommendationAttemptDto;
}

const RemediationQuizComponent = (props: RemediationQuizProps) => {

  const [state, setState] = useState<RemediationQuizState>({
    selectedQuizOption: SELECT_OPTION.value,
    recommendationAttempt: null
  });

  useEffect(() => {
    const recommendationAttemptsForQuiz = getAttemptsFromRecommendation(props.recommendation.id, props.baseState.recommendationAttempts);

    if (!recommendationAttemptsForQuiz || !recommendationAttemptsForQuiz.length) {
      props.postRemediationRecommendationAttemptAction({
        assessmentId: -1, // TODO: Update this to null
        remediationRecommendationId: props.recommendation.id,
        status: RecommendationAttemptStatusDto.IN_PROGRESS
      }).then((response) => {
        setState((prevState) => {
          return {
            ...prevState,
            recommendationAttempt: response
          };
        });
      });
      return;
    }

    setState((prevState) => {
      return {
        ...prevState,
        recommendationAttempt: recommendationAttemptsForQuiz[0]
      };
    });
  }, []);

  const handleSelectQuizOption = (selectedOption: string | number) => {
    setState({
      ...state,
      selectedQuizOption: selectedOption
    });
  };

  const handleViewPerformance = () => {
    const {
      navigateToApp,
      appLinkData,
      appLinkCookies
    } = props;

    navigateToApp({
      action: AppAction.ASSESSMENT_PERFORMANCE_VIEW,
      app: Application.NHE_ASSESSMENT_PLAYER,
      body: {
        targetPage: 'RAAS_QUIZ_RESULTS',
        resultsConfig: {
          getResultsAPI: `${ELSCommonConfig.buildUrl}/api/florence/remediation/attempt/${state.recommendationAttempt.id}/assess/results`,
          title: 'Personalized Learning Plan Quizzing',
          subTitle: 'If you attempted a quiz question multiple times it will be listed multiple times in the report below.',
        },
        raasAssessmentId: props.baseState.assessment.id,
        recommendationAttemptId: state.recommendationAttempt.id,
        recommendationId: state.recommendationAttempt.remediationRecommendationId,
        ref: RoutePath.REMEDIATION_BASE,
      },
      altSrcApp: Application.STUDENT_STUDY,
      parentLinkId: getParentLinkId(appLinkData, appLinkCookies),
      includeLinkHash: true
    });
  };

  const handleStartQuiz = () => {

    if (!state.recommendationAttempt) {
      // eslint-disable-next-line no-console
      console.log('No recommendation attempt found');
      return;
    }

    let sessionQuestionCount = state.selectedQuizOption as number;

    if (sessionQuestionCount === SELECT_OPTION.value) {
      props.toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: (
          <div>
            <p>Please select number of questions.</p>
          </div>
        ),
        type: ELSWithToastService.types.NEGATIVE
      });
      return;
    }

    if (sessionQuestionCount === -1) {
      const recTotals = getAssessmentGoalsItemsTotals(
        {
          assessmentGoals: [props.assessmentGoal],
          activityType: RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE,
          baseState: props.baseState,
        }
      );

      sessionQuestionCount = recTotals.completed >= recTotals.total ? recTotals.total : recTotals.total - recTotals.completed;
    }

    props.postRaaSQuizSessionAction({
      recommendationAttemptId: state.recommendationAttempt.id,
      totalQuestions: sessionQuestionCount
    }).then((quizSession) => {
      handleNavigateToContent({
        recommendationAttempt: state.recommendationAttempt,
        recommendation: props.recommendation,
        assessment: props.baseState.assessment,
        navigateToApp: props.navigateToApp,
        appLinkCookies: props.appLinkCookies,
        appLinkData: props.appLinkData,
        quizSession,
        redirect: props.redirect
      });
    });
  };

  const { statusCounts } = props;

  const getMaxQuestions = (): number => {
    if (!props.baseState.recommendationItems) {
      return 0;
    }
    const items = props.baseState.recommendationItems.filter((item) => {
      return item.remediationRecommendationId === props.recommendation.id;
    });
    if (!state.recommendationAttempt) {
      return items.length;
    }
    const maxQuestions = props.baseState.quizSessionResults.reduce((acc, cur) => {
      if (cur.recommendationAttemptId !== state.recommendationAttempt.id) {
        return acc;
      }
      return cur.questions.reduce((_acc, _cur) => {
        if (_cur.correct) {
          return _acc - 1;
        }
        return _acc;
      }, acc);
    }, items.length);

    if (maxQuestions <= 0) {
      return items.length;
    }
    return maxQuestions;
  };

  const generateQuestionObjects = (maxNumber: number, stopAtExactMax: boolean): ELSDropDownOption[] => {
    const result = [];

    for (let i = 5; i < maxNumber; i += 5) {
      result.push({ name: `${i} Questions`, value: i });
    }

    if (stopAtExactMax) {
      result.push({ name: `${maxNumber} Questions (all)`, value: maxNumber });
    } else if (maxNumber % 5 !== 0) {
      result.push({ name: `${maxNumber} Questions`, value: maxNumber });
    }

    return result;
  };

  const getQuestionNumberOptions = (): ELSDropDownOption[] => {
    const maxQuestions = getMaxQuestions();
    return [
      SELECT_OPTION,
      ...generateQuestionObjects(maxQuestions, true)
    ];
  };

  const isComplete = isQuizComplete(statusCounts);

  return (
    <div className="c-ssa-remediation-quiz-modal">

      {
        isComplete && (
          <RemediationCompleteBox activityType={RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE} />
        )
      }

      <div className="o-els-container">

        <table className="c-ssa-remediation-goal-modal__goal-table">
          <tbody>
            <tr>
              <td>
                <IconWithText iconName="syllabus" iconPrefix="hmds">Instructions</IconWithText>
              </td>
              <td>You must attempt all <strong>{statusCounts.total}</strong> questions and answer at least <strong>{statusCounts.goal}</strong> correctly.</td>
            </tr>
            <tr>
              <td>
                <IconWithText iconName="statistics" iconPrefix="gizmo">Questions Attempted</IconWithText>
              </td>
              <td>
                <TotalsStatus completedGoal={statusCounts.total} completed={statusCounts.attempted} total={statusCounts.total} />
              </td>
            </tr>
            <tr>
              <td>
                <IconWithText iconName="statistics" iconPrefix="gizmo">Questions Correct</IconWithText>
              </td>
              <td>
                <TotalsStatus completedGoal={statusCounts.goal} completed={statusCounts.completed} total={statusCounts.total} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="o-els-container c-els-divider u-els-color-n0" />

      <div className="o-els-container o-els-container--1x1o2">

        <ELSDropDown
          name="select-quiz-questions"
          options={getQuestionNumberOptions()}
          changeHandler={(e, value: string) => handleSelectQuizOption(value)}
          value={state.selectedQuizOption}
        >
          I&apos;d like to complete:
        </ELSDropDown>
      </div>

      <div className="o-els-container">
        <FlexLayout modifiers={[FlexLayoutModifier.LEFT, FlexLayoutModifier.GUTTERS]}>
          <FlexItem>
            <button
              type="button"
              className="c-els-button c-els-button--primary c-els-button--small"
              onClick={handleStartQuiz}
            >
              Start quiz
            </button>
          </FlexItem>
          <FlexItem isRender={props.showReviewQuizButton && isComplete}>
            <button
              type="button"
              className="c-els-button c-els-button--secondary c-els-button--small"
              onClick={handleViewPerformance}
            >
              Review quiz
            </button>
          </FlexItem>
        </FlexLayout>

      </div>
    </div>
  );
};

const enhancers = [
  connector,
  ELSWithModalService,
  ELSWithToastService
];

const RemediationQuiz = compose<null, RemediationQuizPropsOnly>(...enhancers)(RemediationQuizComponent);

export default RemediationQuiz;
