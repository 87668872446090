import React, {
  useEffect,
  useState
} from 'react';
import ReactMarkdown from 'react-markdown';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { ELSPageHeader } from '@els/els-component-shared-ts-react';
import { ELSIcon } from '@els/els-component-form-field-react';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import IsRender from '../../components/is-render/IsRender.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import {
  getRecommendationContentId,
  getRecommendationTitle
} from '../remediation/remediation-home.utilities';
import { RoutePath } from '../../components/app/app.constants';
import { addSearchParams } from '../../utilities/app.utilities';
import { osmosisVideoUrl } from './osmosis-player.constants';
import withRecommendationPlayer, {
  RecommendationPlayerProps,
} from '../../hocs/with-recommendation-player/withRecommendationPlayer.hoc';

const mapStateToProps = state => ({
  osmosisTokenDto: studySelectors.getOsmosisToken(state),
});

const mapDispatchToProps = {
  fetchOsmosisTokenAction: studyActions.fetchOsmosisTokenAction,
  fetchOsmosisTranscriptAction: studyActions.fetchOsmosisTranscriptAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type OsmosisPlayerPropsOnly = {}

export interface OsmosisPlayerState {
  transcript: string;
  isSidebarExpanded: boolean;
}

const defaultState: OsmosisPlayerState = {
  transcript: null,
  isSidebarExpanded: false,
};

export type OsmosisPlayerProps = PropsFromRedux
  & RecommendationPlayerProps
  & OsmosisPlayerPropsOnly

// eslint-disable-next-line sonarjs/cognitive-complexity
export const OsmosisPlayerComponent = (props: OsmosisPlayerProps) => {

  const [state, setState] = useState<OsmosisPlayerState>(defaultState);

  const mergeState = (newState: Partial<OsmosisPlayerState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const osmosisEventListener = (e) => {
    if (e.origin !== osmosisVideoUrl) {
      return;
    }

    if (!e.data) {
      return;
    }

    if (!e.data.type) {
      return;
    }

    if (e.data.type === 'video_finished') {
      props.handleSubmitAttempt(e.data.timepoint);
    }

  };

  useEffect(() => {
    const contentId = getRecommendationContentId(props.recommendation);
    if (!contentId) {
      return;
    }
    props.fetchOsmosisTokenAction()
      .then((response) => {
        props.fetchOsmosisTranscriptAction(contentId, response.authToken)
          .then((_response) => {
            mergeState({
              transcript: _response.transcript,
            });
          });
      });

  }, [props.recommendation]);

  useEffect(() => {
    window.addEventListener('message', osmosisEventListener, false);
    return () => {
      window.removeEventListener('message', osmosisEventListener, false);
    };

  }, [props.recommendationAttempt]);

  const handleReturnNavigation = () => {
    return props.redirect(addSearchParams(RoutePath.REMEDIATION_BASE, {
      isReturn: true,
      recommendationAttemptId: props.recommendationAttempt.id
    }));
  };

  const handleSidebarToggle = () => {
    mergeState({
      isSidebarExpanded: !state.isSidebarExpanded
    });
  };

  const {
    osmosisTokenDto,
    recommendation,
  } = props;

  if (!osmosisTokenDto || !recommendation) {
    return null;
  }

  const {
    transcript,
    isSidebarExpanded
  } = state;

  const {
    messages
  } = props;

  const sideBarClasses = ['c-scm-osmosis-player__sidebar'];

  if (isSidebarExpanded) {
    sideBarClasses.push('c-scm-osmosis-player__sidebar--expanded');
  }

  const sidebarControlsIcon = isSidebarExpanded ? 'nav-collapse' : 'nav-expand';
  const contentId = getRecommendationContentId(recommendation);

  return (
    <div className="c-scm-osmosis-player">
      <header className="c-scm-osmosis-player__header">
        <ELSPageHeader onCloseClick={handleReturnNavigation} title="Osmosis Video Player" />
      </header>

      <FlexLayout modifiers={[FlexLayoutModifier.WRAP_AT_MOBILE]} classes={['c-scm-osmosis-player__body']}>
        <FlexItem
          isRender={!!transcript}
          tag="aside"
          classes={sideBarClasses}
        >
          <div className="c-scm-osmosis-player__sidebar-content">
            <h4 className="u-els-margin-bottom">{messages.TRANSCRIPT}</h4>
            <ReactMarkdown>
              {transcript}
            </ReactMarkdown>
          </div>
        </FlexItem>
        <FlexItem modifiers={[FlexLayoutModifier.GROW]}
                  classes={['c-scm-osmosis-player__page']}>
          <FlexLayout>
            <FlexItem
              classes={['c-scm-osmosis-player__sidebar-controls']}
            >
              <button
                type="button"
                className="u-els-debuttonize u-els-anchorize"
                onClick={handleSidebarToggle}
              >
                <ELSIcon
                  prefix="hmds"
                  name={sidebarControlsIcon}
                  align="middle"
                  size="1x1o2"
                  color="secondary"
                  aria-label="test"
                />
                <span className="u-els-hide-visually">{messages.TOGGLE_TRANSCRIPT}</span>
              </button>
            </FlexItem>
            <FlexItem
              modifiers={[FlexLayoutModifier.GROW]}
            >
              <div className="c-scm-osmosis-player__title">
                <h2>{getRecommendationTitle(recommendation)}</h2>
              </div>
              {
                contentId && (
                  <iframe
                    title="osmosis-video-iframe"
                    src={`${osmosisVideoUrl}/embed/${encodeURIComponent(contentId)}?auth=${osmosisTokenDto.authToken}`}
                    className="c-scm-osmosis-player__iframe"
                    allowFullScreen />
                )
              }
              <IsRender isRender={!!transcript}>
                <div className="c-scm-osmosis-player__sidebar-content-mobile">
                  <h4 className="u-els-margin-bottom">{messages.TRANSCRIPT}</h4>
                  <ReactMarkdown>
                    {transcript}
                  </ReactMarkdown>
                </div>
              </IsRender>
            </FlexItem>
          </FlexLayout>
        </FlexItem>
      </FlexLayout>
    </div>
  );
};

const enhancers = [
  withHTMLHeadSEO({ title: 'Osmosis Player' }),
  connector,
  withRecommendationPlayer,
];

const OsmosisPlayer = compose<null, OsmosisPlayerPropsOnly>(...enhancers)(OsmosisPlayerComponent);

export default OsmosisPlayer;
