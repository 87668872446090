export const eaqStudentStudyProgressReportPageTitle = 'Self Study Progress';
export const eaqStudentTopicReportPageTitle = 'Student Topic Report';
export const eaqSelfStudyAssessmentCreator = 'EAQ Self Study';
export const eaqStudentStudyPerformanceDetailsPageTitle = 'EAQ Performance Details';

export const selfStudyProgress = 'self-study-progress';

export const topicPerformance = 'topic-performance';

export enum masteryLevel {
  NOVICE = 'NOVICE',
  INTERMEDIATE = 'INTERMEDIATE',
  PROFICIENT = 'PROFICIENT'
}
export const masteryLevelToValueMap: Map<masteryLevel, number> = new Map([
  [masteryLevel.NOVICE, 1],
  [masteryLevel.INTERMEDIATE, 2],
  [masteryLevel.PROFICIENT, 3],
]);
