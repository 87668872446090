import React from 'react';
import { ELSProgressBar, } from '../../components/els.components';
import { ActivityTypeConfigMap, } from './remediation-home.models';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  getAssessmentGoalsTotalsCombined,
  getContentTypeDisplay,
  getContentTypesFromActivityType,
  getProgressDisplay
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { RemediationBaseState } from './RemediationBase.page';
import { RemediationActivityTypeDto } from '../../apis/florence-facade/florence-facade.dtos';

export type RemediationActivityProgressProps = {
  assessmentGoals: AssessmentGoalDto[];
  baseState: RemediationBaseState;
  activityType: RemediationActivityTypeDto;
};

const RemediationActivityProgress = (props: RemediationActivityProgressProps) => {

  const {
    assessmentGoals,
    baseState,
    activityType
  } = props;

  const statusCounts = getAssessmentGoalsTotalsCombined({
    assessmentGoals,
    baseState,
    activityType
  });

  const progressDisplay = getProgressDisplay(statusCounts);
  const contentTypes = getContentTypesFromActivityType(activityType);
  const contentTypesDisplay = getContentTypeDisplay(contentTypes);

  return (
    <div className={`c-ssa-remediation-activity-progress c-ssa-remediation-activity-progress--${activityType.toLowerCase()}`}>
      <div className="c-ssa-remediation-activity-progress__header">
        <div>
          <FlexLayout
            modifiers={[
              FlexLayoutModifier.BOTTOM,
              FlexLayoutModifier.GUTTERS
            ]}
          >
            <FlexItem
              modifiers={[
                FlexLayoutModifier.GROW,
              ]}>
              <strong>{ActivityTypeConfigMap[activityType].title}: {contentTypesDisplay}</strong>
            </FlexItem>
            <FlexItem>
              <div><span className="u-els-color-n7">{progressDisplay}</span></div>
            </FlexItem>
          </FlexLayout>

        </div>

        <div className="o-els-container o-els-container--1o2">
          <ELSProgressBar progressNum={statusCounts.completed} totalNum={statusCounts.total} />
        </div>
      </div>
    </div>
  );
};
export default RemediationActivityProgress;
