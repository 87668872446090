import { ELSDropDownOption } from '@els/els-component-form-field-react';
import {
  get,
  isNull,
  orderBy
} from 'lodash';
import {
  AuthessAssignmentDto,
  CourseISBNDto,
  EaqStudentTopicPerformanceDto,
  EaqTaxonomyDto
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import { EaqSelfStudyAssessmentEditorState } from './EaqSelfStudyAssessmentEditor.page';
import {
  TopicPerformanceTableRow,
  TopicWithLineage
} from '../eaq-student-topic-report/eaq-student-topic-report.models';
import {
  flattenTreeWithLineage
} from '../eaq-student-topic-report/eaq-student-topic-report.utilities';
import { AssessmentTypeDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { FeatureFlagDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { ALL_OPTION } from '../eaq-student-topic-report/eaq-student-topic-report.constants';

export const getQuestionSetOptions = (
  questionSet: CourseISBNDto[]
): ELSDropDownOption[] => {

  if (!questionSet || !questionSet.length) {
    return [];
  }

  return questionSet.map((item) => {
    return {
      value: item.isbn,
      name: item.title
    };
  });
};

export const questionSetOptions = (state: EaqSelfStudyAssessmentEditorState): ELSDropDownOption[] => {
  return getQuestionSetOptions(state.questionSets);
};

export const getSelectedQuestionSetIsbn = (selectedQuestionSetIsbn: string): string => {
  return (isNull(selectedQuestionSetIsbn) ? '' : selectedQuestionSetIsbn);
};

export const filterTopicsOnTaxonomyDto = (eaqTaxonomyDto: EaqTaxonomyDto[],
  topicPerformances: EaqStudentTopicPerformanceDto[],
  activeTopicOrgVtwId: string): EaqStudentTopicPerformanceDto[] => {
  return topicPerformances.filter((performance: EaqStudentTopicPerformanceDto) => {
    return eaqTaxonomyDto.some((taxonomy: EaqTaxonomyDto): boolean => {
      if (!activeTopicOrgVtwId || activeTopicOrgVtwId === ALL_OPTION.value as string) {
        return performance.vtwId === taxonomy.vtwId;
      }
      return performance.vtwId === taxonomy.vtwId && performance.vtwId === activeTopicOrgVtwId;
    });
  });
};

export const flattenedAssessmentEditorTopics = (
  eaqTaxonomyDto: EaqTaxonomyDto[],
  topicPerformances: EaqStudentTopicPerformanceDto[],
  activeTopicOrgVtwId: string
): TopicWithLineage[] => {
  if (!eaqTaxonomyDto || !eaqTaxonomyDto.length || !topicPerformances || !topicPerformances.length) {
    return null;
  }

  return flattenTreeWithLineage(
    filterTopicsOnTaxonomyDto(eaqTaxonomyDto, topicPerformances, activeTopicOrgVtwId).filter((): boolean => {
      return true;
    })
  );
};

const getSortValue = (item: EaqStudentTopicPerformanceDto) => {
  return item.displayOrder + (item.displayName || item.text);
};

export const getTopicOrgFilterOptions = (eaqTaxonomyDto: EaqTaxonomyDto[]): ELSDropDownOption[] => {
  const options = orderBy(eaqTaxonomyDto, getSortValue).map((item: EaqTaxonomyDto) => {
    return {
      value: item.vtwId,
      name: item.displayName || item.text
    };
  });

  return [
    ALL_OPTION,
    ...options
  ];
};

export const isTopicChecked = (row: TopicPerformanceTableRow, assessment: EaqSelfStudyAssessmentEditorState['assessment']): boolean => {
  if (!assessment
    || !assessment.assessmentTopics
    || !assessment.assessmentTopics.length) {
    return false;
  }

  return assessment.assessmentTopics.some((item): boolean => {
    return item.vtwId === row.topic.item.vtwId;
  });
};

export const isMasteryLevelValid = (state: EaqSelfStudyAssessmentEditorState): boolean => {
  const { assessment } = state;
  const { assessmentGoals } = assessment;

  if (!assessmentGoals
      || !assessmentGoals.length
      || !assessmentGoals[0].goal
      || !state.topicPerformances
  ) {
    return true;
  }

  const selectedTopic = assessmentGoals[0].vtwId;
  const targetMasteryGoal = assessmentGoals[0].goal;

  if (!state.topicPerformances) {
    return false;
  }

  const flattenedTopics = flattenTreeWithLineage(state.topicPerformances);

  const filteredTopic = flattenedTopics.filter((topic) => topic.item.id === selectedTopic);

  if (!filteredTopic.length) {
    return false;
  }

  const masteryLevelForSelectedTopic = get(filteredTopic, '[0].item.studentPerformances[0].masteryLevel', 0);

  if (masteryLevelForSelectedTopic > targetMasteryGoal) {
    return false;
  }

  return true;
};

export const validateAssessment = (state: EaqSelfStudyAssessmentEditorState): boolean => {
  const { assessment } = state;

  if (!assessment
      || !assessment.assessmentGoals
      || !assessment.assessmentGoals.length
      || !assessment.assessmentTopics
      || !assessment.assessmentTopics.length) {
    return false;
  }

  if ((AssessmentTypeDto.MASTERY === assessment.type || AssessmentTypeDto.STANDARD === assessment.type)
      && (isNaN(assessment.assessmentGoals[0].goal) || isNull(assessment.assessmentGoals[0].goal))) {
    return false;
  }

  if (AssessmentTypeDto.STANDARD === assessment.type
      && !isNull(state.maxQuestionsCount)
      && assessment.assessmentGoals[0].goal > state.maxQuestionsCount) {
    return false;
  }

  if (AssessmentTypeDto.MASTERY === assessment.type
  && !isMasteryLevelValid(state)) {
    return false;
  }

  return true;
};

export const showAuthessCaseStudyAssessmentType = (featureFlag: FeatureFlagDto): boolean => {
  if (!featureFlag) {
    return false;
  }
  return featureFlag.featureValue === 'true';
};

export const isAuthessRadioChecked = (authessAssignment: AuthessAssignmentDto, state: EaqSelfStudyAssessmentEditorState): boolean => {
  const { assessment } = state;
  if (!assessment || !assessment.assessmentGoals || !assessment.assessmentGoals.length) {
    return false;
  }
  return state.assessment.assessmentGoals[0].vtwId === authessAssignment.key;
};
