import React, {
  useState
} from 'react';

import { ELSButton, ELSIcon } from '../../components/els.components';
import {
  ELSButtonSize,
  ELSButtonType
} from '../../models/button.models';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import IsRender from '../../components/is-render/IsRender.component';
import { AnalyticsAction, AnalyticsActionProps } from '../../models/analytics.models';
import { ChatEntryDto } from '../../apis/florence-facade/florence-facade.dtos';

export type AiChatFollowUpQueriesProps = {
  botEntry: ChatEntryDto;
  followUpQueries: string[];
  handleSubmit: (followUpQuery: string) => void;
  trackAction: (props: AnalyticsActionProps) => void;
}

export type AiChatFollowUpQueriesState = {
  isFollowUpQueriesActive: boolean;
}

const defaultState: AiChatFollowUpQueriesState = {
  isFollowUpQueriesActive: true
};

export const AiChatFollowUpQueries = (props: AiChatFollowUpQueriesProps) => {

  const [state, setState] = useState<AiChatFollowUpQueriesState>(defaultState);

  const {
    botEntry,
    followUpQueries,
    handleSubmit,
    trackAction
  } = props;

  if (!followUpQueries || !followUpQueries.length) {
    return null;
  }

  const handleFollowUpQueriesExpandToggle = () => {
    setState({
      ...state,
      isFollowUpQueriesActive: !state.isFollowUpQueriesActive
    });
  };

  const handleClick = (followUpQuery: string) => {
    trackAction({
      action: AnalyticsAction.AI_CHAT_FOLLOW_UP_QUERY_SUBMIT,
      props: {
        entryId: botEntry.id,
        idx: botEntry.index
      }
    });

    handleSubmit(followUpQuery);
  };

  return (
    <div className="u-els-margin-bottom-1o2">
      <button
        type="button"
        className="u-els-debuttonize u-els-display-block u-els-width-1o1 u-els-color-secondary"
        onClick={handleFollowUpQueriesExpandToggle}
        aria-expanded={state.isFollowUpQueriesActive ? 'true' : 'false'}
      >
        <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS, FlexLayoutModifier.MIDDLE]}>
          <FlexItem>
            <ELSIcon
              size="1x"
              prefix="hmds"
              name={state.isFollowUpQueriesActive ? 'chevron-up' : 'chevron-down'}
              customClass="u-els-display-block"
            />
          </FlexItem>
          <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
            Follow-up questions
          </FlexItem>
        </FlexLayout>
      </button>
      <IsRender isRender={state.isFollowUpQueriesActive}>
        <div className="u-els-margin-top">
          <FlexLayout modifiers={[
            FlexLayoutModifier.GUTTERS,
            FlexLayoutModifier.WRAP,
            FlexLayoutModifier.LEFT
          ]}>
            {followUpQueries.map((followUpQuery) => {
              return (
                <FlexItem key={followUpQuery}>
                  <ELSButton type={ELSButtonType.PRIMARY}
                             size={ELSButtonSize.SMALL}
                             onClick={() => handleClick(followUpQuery)}
                             className="c-els-ai-chat__follow-up-query">
                    {followUpQuery}
                  </ELSButton>
                </FlexItem>
              );
            })}
          </FlexLayout>
        </div>
      </IsRender>
    </div>
  );
};
