import Cookie from 'js-cookie';
import {
  ELSURLHelper,
  ELSCommonConfig
} from '@els/els-ui-common-react';
import { AppLinkCookie } from './app-link-redirect.constants';
import { ServerConstants } from '../../components/app/server.constants';
import { AppLinkCookies } from '../../apis/eols-app-link/eols-app-link.dtos';

export const removeCookies = () => {
  const domain = ServerConstants[ELSCommonConfig.appProfile].cookiesDomain;
  Cookie.remove(AppLinkCookie.X_LINK_ID, { path: '/', domain });
  Cookie.remove(AppLinkCookie.X_TOKEN, { path: '/', domain });
};

export const pickCookies = (): AppLinkCookies => {

  const tokenFromUrl = ELSURLHelper.getParameterByName('token');
  const linkIdFromUrl = ELSURLHelper.getParameterByName('linkId');

  if (tokenFromUrl && linkIdFromUrl) {

    removeCookies();

    return {
      token: tokenFromUrl,
      linkId: linkIdFromUrl
    };
  }

  const token = Cookie.get(AppLinkCookie.X_TOKEN);
  const linkId = Cookie.get(AppLinkCookie.X_LINK_ID);

  removeCookies();

  return { token, linkId };
};
