import React from 'react';
import OsmosisPlayer from './OsmosisPlayer.component';

const OsmosisPlayerPage = () => {
  return (
    <OsmosisPlayer />
  );
};

export default OsmosisPlayerPage;
