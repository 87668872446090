import React from 'react';
import { RemediationBaseState } from './RemediationBase.page';
import {
  getAssessmentGoalFromRecommendation,
  getAssessmentGoalsTotalsCombined,
  getAssessmentSubmissionByAttempt,
  getContentTypePassNumber,
  getQuizSessionResults,
  getTotalProgress,
  isGoalComplete,
} from './remediation-home.utilities';
import {
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDto,
  RemRecContentTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  ActivityTypeConfigMap,
  RemediationContentTypeConfigMap
} from './remediation-home.models';
import { AppLinkData } from '../../apis/eols-app-link/eols-app-link.dtos';

export type RecommendationPostPlayerMessageProps = {
  baseState: RemediationBaseState;
  recommendation: RecommendationDto;
  attempt: RecommendationAttemptDto;
  appLinkData: AppLinkData;
};

const ReturnMessage = (props: {
  attempt: RecommendationAttemptDto;
  baseState: RemediationBaseState;
  recommendation: RecommendationDto;
}) => {

  const {
    attempt,
    baseState,
    recommendation
  } = props;

  const submission = getAssessmentSubmissionByAttempt(attempt, baseState.attemptAssessmentSubmissions);
  const assessmentGoal = getAssessmentGoalFromRecommendation(baseState.assessment, recommendation);
  const contentTypeDisplay = RemediationContentTypeConfigMap[recommendation.contentType].displayName.toLowerCase();
  const scoreToPass = getContentTypePassNumber(recommendation, baseState);
  const totalProgress = getTotalProgress(baseState);

  const goalStatusCounts = getAssessmentGoalsTotalsCombined({
    assessmentGoals: [assessmentGoal],
    activityType: recommendation.assessmentGoalType,
    baseState,
  });
  const activityTypeStatusCounts = getAssessmentGoalsTotalsCombined({
    assessmentGoals: baseState.assessment ? baseState.assessment.assessmentGoals : null,
    activityType: recommendation.assessmentGoalType,
    baseState,
  });

  const parts = [];

  if (!submission) {
    parts.push((
      <div>
        You closed your {contentTypeDisplay} before completing it,
        you can resume this {contentTypeDisplay} by using the option below or choose to resume it at a different time.
      </div>
    ));
  } else if (submission.score < scoreToPass) {
    parts.push((
      <div>
        Nice try! You completed this {contentTypeDisplay} scoring <strong>{Math.round(submission.score * 100)}%</strong>.
        Unfortunately, you are required to score <strong>{Math.round(scoreToPass * 100)}%</strong> to receive credit.
        You can choose to reattempt using the option below or reattempt this {contentTypeDisplay} at a different time.
      </div>
    ));
  } else if (RemediationContentTypeConfigMap[recommendation.contentType].isScored) {
    parts.push((
      <div>
        Great job! You completed this {contentTypeDisplay} scoring <strong>{Math.round(submission.score * 100)}%</strong>.
      </div>
    ));
  } else {
    parts.push((
      <div>
        Great job! You completed this {contentTypeDisplay}.
      </div>
    ));
  }

  if (totalProgress >= 100) {
    parts.push((
      <div>
        You may review any additional content if you would like.
      </div>
    ));
  } else if (isGoalComplete(activityTypeStatusCounts)) {
    parts.push((
      <div>
        All of your required work for the {ActivityTypeConfigMap[recommendation.assessmentGoalType].title.toLowerCase()} section is complete!
      </div>
    ));
  } else if (isGoalComplete(goalStatusCounts)) {
    parts.push((
      <div>
        All of your required work for {assessmentGoal.text} is complete!
      </div>
    ));
  }

  if (parts.length === 1) {
    return <div className="">{parts[0]}</div>;
  }

  return (
    <>
      <div className="">{parts[0]}</div>
      <div className="">{parts[1]}</div>
    </>
  );
};

const QuizReturnMessage = (props: {
  attempt: RecommendationAttemptDto;
  baseState: RemediationBaseState;
  quizSessionResults: {
    totalQuestions: number;
    totalCorrect: number;
  };
  recommendation: RecommendationDto;
}) => {

  const {
    attempt,
    baseState,
    quizSessionResults,
    recommendation,
  } = props;

  const progress = getTotalProgress(baseState);

  if (!attempt || !quizSessionResults) {
    return null;
  }

  const parts = [];

  if (quizSessionResults.totalQuestions === 0) {
    parts.push((
      <div>
        You closed the quiz before answering any questions.
      </div>
    ));
  } else if (quizSessionResults.totalCorrect === 0) {
    parts.push((
      <div>
        Nice try! You attempted {quizSessionResults.totalQuestions} question(s) but unfortunately did not get any correct.
        You will be able to reattempt these incorrect questions once you have attempted all quiz questions.
      </div>
    ));
  } else {
    parts.push((
      <div>
        Great job! you completed <strong>{quizSessionResults.totalQuestions} questions</strong> and
        got <strong>{quizSessionResults.totalCorrect} correct</strong>.
      </div>
    ));
  }

  if (progress >= 100) {
    parts.push((
      <div>
        You may review any additional content if you would like.
      </div>
    ));
  } else if (attempt.status === RecommendationAttemptStatusDto.COMPLETED_PASSED) {
    parts.push((
      <div>
        <div>
          All of your required work for the {ActivityTypeConfigMap[recommendation.assessmentGoalType].title.toLowerCase()} section is complete!
        </div>
        <div>
          You can continue to practice these quiz questions or continue to the next recommendation.
        </div>
      </div>
    ));
  } else {
    parts.push((
      <div>
        You may continue your quizzing now or at a later time.
      </div>
    ));
  }

  if (parts.length === 1) {
    return <div className="">{parts[0]}</div>;
  }

  return (
    <>
      <div className="">{parts[0]}</div>
      <div className="">{parts[1]}</div>
    </>
  );
};

export const RecommendationPostPlayerMessage = (props: RecommendationPostPlayerMessageProps) => {
  const {
    recommendation,
    baseState,
    appLinkData,
    attempt
  } = props;
  if (recommendation.contentType !== RemRecContentTypeDto.RAAS_QUIZ) {
    return (
      <ReturnMessage
        attempt={attempt}
        baseState={baseState}
        recommendation={recommendation}
      />
    );
  }

  const { outPostBody } = appLinkData;
  const quizSessionResults = getQuizSessionResults(
    props.baseState,
    outPostBody
  );

  return (
    <QuizReturnMessage
      attempt={props.attempt}
      quizSessionResults={quizSessionResults}
      baseState={baseState}
      recommendation={recommendation}
    />
  );
};

export default RecommendationPostPlayerMessage;
