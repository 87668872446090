import React from 'react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { RemediationBaseState } from './RemediationBase.page';
import {
  getAssessmentGoalFromRecommendation,
  getAssessmentGoalsItemsTotals,
  handleResumeOrStartNewAttempt,
} from './remediation-home.utilities';
import {
  RecommendationDto,
  RemediationActivityTypeDto,
  RemRecContentTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import RemediationQuiz from './RemediationQuiz.component';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import {
  ActivityTypeConfigMap,
  RemediationContentTypeConfigMap
} from './remediation-home.models';
import RemediationRecommendationCard from './RemediationRecommendationCard.component';

type RecommendationPostPlayerNextPropsOnly = {
  baseState: RemediationBaseState;
  nextRecommendation: RecommendationDto;
}

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  replaceURL: locationActions.replaceURL,
  navigateToApp: studyActions.navigateToApp,
  postAssessmentAction: studyActions.postAssessmentAction,
  postRemediationRecommendationAttemptAction: studyActions.postRemediationRecommendationAttemptAction,
};
const mapStateToProps = state => ({
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  userId: studySelectors.getUserId(state),
  courseSectionId: studySelectors.getCourseSectionId(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RecommendationPostPlayerNextProps = PropsFromRedux & RecommendationPostPlayerNextPropsOnly;

export const RecommendationPostPlayerNextComponent = (props: RecommendationPostPlayerNextProps) => {

  const {
    baseState,
    nextRecommendation,
  } = props;

  if (!nextRecommendation) {
    return null;
  }

  if (nextRecommendation.contentType !== RemRecContentTypeDto.RAAS_QUIZ) {

    const goal = getAssessmentGoalFromRecommendation(props.baseState.assessment, nextRecommendation);
    const contentTypeConfig = RemediationContentTypeConfigMap[nextRecommendation.contentType];
    const activityTypeConfig = ActivityTypeConfigMap[contentTypeConfig.activityType];

    let banner = null;

    if (contentTypeConfig.activityType === RemediationActivityTypeDto.REVIEW_BY_TAXON) {
      banner = (
        <div>
          <strong>{activityTypeConfig.title}</strong> your focus topic, <strong>{goal.text}:</strong>
        </div>
      );
    }

    if (contentTypeConfig.activityType === RemediationActivityTypeDto.APPLY_BY_CONTENT_TYPE) {
      banner = (
        <div>
          <strong>{activityTypeConfig.title}</strong> your knowledge with a related <strong>{contentTypeConfig.displayName}</strong>
        </div>
      );
    }

    return (
      <RemediationRecommendationCard
        banner={banner}
        handleLaunch={() => {
          handleResumeOrStartNewAttempt({
            recommendation: nextRecommendation,
            recommendationAttempt: null,
            postAssessmentAction: props.postAssessmentAction,
            postRemediationRecommendationAttemptAction: props.postRemediationRecommendationAttemptAction,
            baseState,
            redirect: props.redirect,
            appLinkData: props.appLinkData,
            appLinkCookies: props.appLinkCookies,
            navigateToApp: props.navigateToApp,
            userId: props.userId,
          });
        }}
        recommendation={nextRecommendation}
        isExpanded
        recommendationAttempts={baseState.recommendationAttempts}
        launchButtonText="Launch"
      />

    );
  }

  const quizAssessmentGoal = getAssessmentGoalFromRecommendation(baseState.assessment, nextRecommendation);
  const statusCounts = getAssessmentGoalsItemsTotals(
    {
      assessmentGoals: [quizAssessmentGoal],
      activityType: RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE,
      baseState: props.baseState,
    }
  );

  return (

    <RemediationQuiz
      assessmentGoal={quizAssessmentGoal}
      baseState={baseState}
      recommendation={nextRecommendation}
      statusCounts={statusCounts}
      showReviewQuizButton={false}
    />

  );
};

const enhancers = [
  connector,
];

const RecommendationPostPlayerNext = compose<null, RecommendationPostPlayerNextPropsOnly>(...enhancers)(RecommendationPostPlayerNextComponent);

export default RecommendationPostPlayerNext;
