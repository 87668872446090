import React, { useEffect } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import { ELSPageLoader } from '@els/els-ui-common-react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import FocusTrap from 'focus-trap-react';
import moment from 'moment';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { ELSProgressBar } from '../../components/els.components';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { locationActions } from '../../redux/location/location.actions';
import RemediationHeader from './RemediationHeader.component';
import {
  getTotalProgress,
  isRecommendationPartOfGoal
} from './remediation-home.utilities';
import {
  RecommendationDto,
  RecommendationGenerationStatusDto,
  RemediationActivityTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import RemediationQuizModal, { RemediationQuizModalId } from './RemediationQuizModal.component';
import RemediationActivityProgress from './RemediationActivityProgress.component';
import RemediationActivitySection from './RemediationActivitySection.component';
import {
  AssessmentGoalDto,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { DATE_TIME_PRIMARY } from '../../constants/date.constants';
import RemediationActivityModal, { RemediationActivityModalId } from './RemediationActivityModal.component';
import { locationSelectors } from '../../redux/location/location.selectors';
import { RoutePath } from '../../components/app/app.constants';
import { RemediationBaseState } from './RemediationBase.page';
import { getParentLinkId } from '../../utilities/app.utilities';

type RemediationHomePropsOnly = {
  baseState: RemediationBaseState;
  handleUpdateRecommendation: (recommendation: RecommendationDto) => void;
};

const mapDispatchToProps = {
  returnAppLink: studyActions.returnAppLink,
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  navigateToApp: studyActions.navigateToApp,
  replaceURL: locationActions.replaceURL,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  userId: studySelectors.getUserId(state),
  location: locationSelectors.getLocation(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationHomeProps = PropsFromRedux
  & RemediationHomePropsOnly
  & ELSPropsFromModalService
  & ELSPropsFromToastService;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RemediationHomeComponent = (props: RemediationHomeProps) => {

  const handleAssessLaunch = (
    fnProps: {
      recommendation: RecommendationDto;
      assessmentGoal: AssessmentGoalDto;
      baseState: RemediationBaseState;
    }
  ) => {

    const {
      recommendation,
      baseState,
    } = fnProps;

    const {
      modalService,
    } = props;

    if (!recommendation) {
      return;
    }

    modalService.openCustomModal({
      modalId: RemediationQuizModalId,
      modal: (
        <FocusTrap>
          <RemediationQuizModal
            assessmentGoal={fnProps.assessmentGoal}
            recommendation={recommendation}
            baseState={baseState}
          />
        </FocusTrap>
      )
    });
  };

  const goToAssessmentPostScreen = () => {

    const {
      location,
      replaceURL,
      baseState,
      appLinkData
    } = props;

    // Need all this before opening the quiz modal
    if (
      !location
      || !location.query
      || !location.query.isOpenAssessModal
      || !appLinkData
      || !appLinkData.outPostBody
      || !baseState.recommendations
      || !baseState.assessment
      || !baseState.recommendationAttempts
    ) {
      return;
    }

    const recommendation = baseState.recommendations.find((_recommendation: RecommendationDto) => {
      return _recommendation.id === appLinkData.outPostBody.recommendationId;
    });

    if (!recommendation) {
      return;
    }
    const assessmentGoal = baseState.assessment.assessmentGoals.find((goal) => {
      return isRecommendationPartOfGoal(recommendation, goal);
    });

    if (!assessmentGoal) {
      return;
    }

    replaceURL(RoutePath.REMEDIATION_BASE);
    handleAssessLaunch({
      recommendation,
      assessmentGoal,
      baseState
    });
  };

  useEffect(() => {
    goToAssessmentPostScreen();
  }, [props.location.query, props.baseState]);

  const handleReturnNavigation = () => {
    props.returnAppLink({
      linkId: getParentLinkId(props.appLinkData, props.appLinkCookies),
      returnPostBody: null
    });
  };

  const handleTaxonClick = (assessmentGoal: AssessmentGoalDto) => {

    if (assessmentGoal.type === RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE) {

      const goalRecommendation = props.baseState.recommendations.find((recommendation) => {
        return isRecommendationPartOfGoal(recommendation, assessmentGoal);
      });

      if (!goalRecommendation) {
        // eslint-disable-next-line no-console
        console.log('No recommendation found for goal');
        return;
      }

      handleAssessLaunch({
        recommendation: goalRecommendation,
        assessmentGoal,
        baseState: props.baseState
      });
      return;
    }

    const {
      modalService,
    } = props;

    modalService.openCustomModal({
      modalId: RemediationActivityModalId,
      modal: (
        <FocusTrap>
          <RemediationActivityModal
            assessmentGoal={assessmentGoal}
            baseState={props.baseState}
            handleUpdateRecommendation={props.handleUpdateRecommendation}
          />
        </FocusTrap>
      )
    });

  };

  const getAssessmentGoals = () => {
    return props.baseState.assessment ? props.baseState.assessment.assessmentGoals : null;
  };

  const getDueDisplay = (dueDate: string) => {
    if (!dueDate) {
      return 'No due date';
    }
    const daysUntil = moment(dueDate).diff(moment(), 'days');
    return (
      <>
        <span>Due {moment(dueDate).format(DATE_TIME_PRIMARY)}</span>
        <span>&nbsp;</span>
        <span>({daysUntil <= 0 ? 'Due today' : `In ${daysUntil} days`})</span>
      </>
    );
  };

  if (!props.baseState) {
    return null;
  }

  const renderStatusMessage = (status: RecommendationGenerationStatusDto) => {
    if (status === RecommendationGenerationStatusDto.IN_PROGRESS) {
      return (
        <div className="u-els-font-size-h3">
          Your Personalized Learning Plan is being created. Please check back later.
        </div>
      );
    }

    return (
      <div className="u-els-font-size-h3">
        We weren&apos;t able to find a Personalized Learning Plan for you at this time. Please check back later or contact support.
      </div>
    );
  };

  if (props.baseState.showPendingAssessmentMessage) {
    return (
      <div className="c-ssa-remediation-home">
        <RemediationHeader handleReturnNavigation={handleReturnNavigation} />

        <FlexLayout modifiers={[FlexLayoutModifier.CENTER]}>
          <FlexItem>
            <div className="c-ssa-remediation-home__main u-els-margin-top-3x">
              <div className="c-ssa-remediation-home__center">
                {renderStatusMessage(props.baseState.recommendationGenerationStatus)}
              </div>
            </div>
          </FlexItem>
        </FlexLayout>
      </div>
    );
  }

  return (
    <>
      {props.baseState.pendingRequestCount > 0 && (<ELSPageLoader />)}
      <div className="c-ssa-remediation-home">
        <RemediationHeader handleReturnNavigation={handleReturnNavigation} />

        <div className="c-ssa-remediation-home__main">
          <div className="c-ssa-remediation-home__center">
            {
              props.baseState.recommendations
              && props.baseState.recommendations.length > 0
              && props.baseState.remediationAssignmentWithSources
              && (
                <>
                  <div className="c-ssa-remediation-home__main-header">

                    <FlexLayout modifiers={[
                      FlexLayoutModifier.GUTTERS_2X,
                      FlexLayoutModifier.MIDDLE,
                    ]}>
                      <FlexItem modifiers={[FlexLayoutModifier.GROW, FlexLayoutModifier.TOP]}>
                        <div className="u-els-color-secondary">{props.baseState.remediationAssignmentWithSources.eolsAssignment.title}</div>
                        <h3><strong>Personalized Learning Plan</strong></h3>
                      </FlexItem>
                      <FlexItem>

                        <div className="u-els-margin-bottom">
                          <div className="c-ssa-remediation-home__progress">
                            <div>{getDueDisplay(props.baseState.remediationAssignmentWithSources.eolsAssignment.dueDate)}</div>
                            <FlexLayout modifiers={[
                              FlexLayoutModifier.GUTTERS_1o2,
                              FlexLayoutModifier.MIDDLE,
                            ]}>
                              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                                <ELSProgressBar progressNum={getTotalProgress(props.baseState)} totalNum={100} />
                              </FlexItem>
                              <FlexItem>
                                <span className="u-els-font-size-meta">{getTotalProgress(props.baseState)}%</span>
                              </FlexItem>
                            </FlexLayout>
                          </div>
                        </div>
                      </FlexItem>
                    </FlexLayout>

                    <div>
                      You took the exam, and now its time to make sure you’re on top of your game for next time.
                      We&apos;ve analyzed your results and have put together a learning plan personalized to your specific
                      needs.
                    </div>

                  </div>

                  <div className="o-els-container o-els-container--2x">
                    <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
                      <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                        <RemediationActivityProgress
                          activityType={RemediationActivityTypeDto.REVIEW_BY_TAXON}
                          assessmentGoals={getAssessmentGoals()}
                          baseState={props.baseState}
                        />
                      </FlexItem>
                      <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                        <RemediationActivityProgress
                          activityType={RemediationActivityTypeDto.APPLY_BY_CONTENT_TYPE}
                          assessmentGoals={getAssessmentGoals()}
                          baseState={props.baseState}
                        />
                      </FlexItem>
                      <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                        <RemediationActivityProgress
                          activityType={RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE}
                          assessmentGoals={getAssessmentGoals()}
                          baseState={props.baseState}
                        />
                      </FlexItem>
                    </FlexLayout>

                  </div>

                  <div>

                    <div className="c-ssa-remediation-home__tab">
                      <div className="c-ssa-remediation-home__tab-content">
                        <RemediationActivitySection
                          baseState={props.baseState}
                          activityType={RemediationActivityTypeDto.REVIEW_BY_TAXON}
                          onTaxonClick={handleTaxonClick}
                        />
                      </div>
                    </div>
                    <div className="c-ssa-remediation-home__tab">
                      <div className="c-ssa-remediation-home__tab-content">
                        <RemediationActivitySection
                          baseState={props.baseState}
                          activityType={RemediationActivityTypeDto.APPLY_BY_CONTENT_TYPE}
                          onTaxonClick={handleTaxonClick}
                        />
                      </div>
                    </div>
                    <div className="c-ssa-remediation-home__tab">
                      <div className="c-ssa-remediation-home__tab-content">
                        <RemediationActivitySection
                          baseState={props.baseState}
                          activityType={RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE}
                          onTaxonClick={handleTaxonClick}
                        />
                      </div>
                    </div>

                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

const enhancers = [
  connector,
  ELSWithModalService,
  ELSWithToastService
];

const RemediationHome = compose<null, RemediationHomePropsOnly>(...enhancers)(RemediationHomeComponent);

export default RemediationHome;
