import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'recompose';
import { ELSCheckBox, ELSDropDown, ELSRadio } from '@els/els-component-form-field-react';
import { ELSPropsFromModalService, ELSWithModalService } from '@els/els-component-modal-react';
import { ELSButton } from '@els/els-component-button-react';
import { ELSPageHeader } from '@els/els-component-shared-ts-react';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import {
  getSelectedQuestionSetIsbn,
  getTopicOrgFilterOptions,
  isAuthessRadioChecked,
  isTopicChecked,
  questionSetOptions,
  showAuthessCaseStudyAssessmentType,
  validateAssessment,
  flattenedAssessmentEditorTopics
} from './eaq-self-study-assessment-editor.utilities';
import {
  AuthessAssignmentDto,
  BookTaxonomyKindDto,
  CourseISBNDto,
  EaqStudentTopicPerformanceDto,
  EaqTaxonomyDto,
  EaqTopicDto
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';
import {
  eaqTaxonomy,
  fetchAuthessAssignments,
  fetchIsbns,
  fetchNumberOfQuestions,
  fetchStudentTopicPerformances,
  createAssessment
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.utilities';
import { AssessmentDto, AssessmentTypeDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import IsRender from '../../components/is-render/IsRender.component';
import { FeatureFlagDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { fetchGroupFeatureFlag } from '../../apis/eols-features-api/eols-features-api.utilities';
import { ELSDataTable } from '../../components/els.components';
import { EaqAssessmentConfig, TopicPerformanceTableRow, TopicWithLineage } from '../eaq-student-topic-report/eaq-student-topic-report.models';
import MasteryChart from '../eaq-student-topic-report/MasteryChart.component';
import {
  convertMasteryScoreToPercentage,
  flattenTreeWithLineage,
  handleExplainerClick
} from '../eaq-student-topic-report/eaq-student-topic-report.utilities';
import NumberOfQuestions from './number-of-questions/NumberOfQuestions.component';
import * as constants from '../eaq-student-study/eaq-student-study.constants';
import { AppAction, Application } from '../../apis/eols-app-link/eols-app-link.constants';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { locationActions } from '../../redux/location/location.actions';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { getParentLinkId, SELECT_OPTION } from '../../utilities/app.utilities';
import SelectMasteryGoal from './select-mastery-goal/SelectMasteryGoal.component';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { ALL_OPTION } from '../eaq-student-topic-report/eaq-student-topic-report.constants';
import { EntitlementDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import { EvolveProductType } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { EAQ_PRODUCT_TYPES } from '../../constants/eaq.constants';
import { getTopicTableRows, navigateToAssessmentPlayer } from '../../utilities/eaq-self-study.utilities';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';

const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  course: studySelectors.getCourse(state),
  userId: studySelectors.getUserId(state),
  appLinkData: studySelectors.getLinkData(state),
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  courseSectionId: studySelectors.getCourseSectionId(state),
  featureFlagsGrouped: studySelectors.getFeatureFlagsGrouped(state)
});

const mapDispatchToProps = {
  redirect: locationActions.redirect,
  returnAppLink: studyActions.returnAppLink,
  navigateToApp: studyActions.navigateToApp,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type EaqSelfStudyAssessmentEditorProps = PropsFromRedux & ELSPropsFromModalService;

export type EaqSelfStudyAssessmentEditorState = {
  questionSets: CourseISBNDto[];
  selectedQuestionSetIsbn: string;
  taxonomyTopicsMap: Record<string, EaqTopicDto>;
  assessment: AssessmentDto;
  eaqTaxonomyDto: EaqTaxonomyDto[];
  authessAssignmentsDto: AuthessAssignmentDto[];
  authessFeatureDto: FeatureFlagDto;
  topicPerformances: EaqStudentTopicPerformanceDto[];
  maxQuestionsCount: number;
  activeTopicOrgVtwId: string;
}

export const eaqSelfStudyAssessmentEditorDefaultState: EaqSelfStudyAssessmentEditorState = {
  questionSets: null,
  selectedQuestionSetIsbn: null,
  taxonomyTopicsMap: {},
  assessment: {} as AssessmentDto,
  eaqTaxonomyDto: null,
  authessAssignmentsDto: null,
  authessFeatureDto: null,
  topicPerformances: null,
  maxQuestionsCount: null,
  activeTopicOrgVtwId: null,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const EaqSelfStudyAssessmentEditorComponent = (props: EaqSelfStudyAssessmentEditorProps) => {
  const [state, setState] = useState<EaqSelfStudyAssessmentEditorState>(eaqSelfStudyAssessmentEditorDefaultState);

  const mergeState = (newState: Partial<EaqSelfStudyAssessmentEditorState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const mergeAssessment = (assessment: Partial<AssessmentDto>) => {
    setState((prevState) => {
      return {
        ...prevState,
        assessment: {
          ...prevState.assessment,
          ...assessment
        }
      };
    });
  };

  const fetchAndStoreMaxNumberCount = (): Promise<number> => {
    return fetchNumberOfQuestions({
      isbnTopicMap: {
        [state.selectedQuestionSetIsbn]: state.assessment.assessmentTopics.map((item) => {
          return item.vtwId;
        })
      },
      courseISBNS: [state.selectedQuestionSetIsbn],
      size: 20,
      questionCount: true
    }).then((maxNumber) => {
      mergeState(
        {
          maxQuestionsCount: maxNumber,
        }
      );
      return maxNumber;
    });
  };

  const validateNumberOfQuestions = (): Promise<boolean> => {
    const { assessment } = state;
    if (assessment.type !== AssessmentTypeDto.STANDARD) {
      return Promise.resolve(true);
    }
    return fetchAndStoreMaxNumberCount().then((maxNumber) => {
      return assessment.assessmentGoals[0].goal <= maxNumber;
    });
  };

  const fetchTaxonomyAndTopics = (isbn: string) => {
    mergeState({ selectedQuestionSetIsbn: isbn });

    return Promise.all([
      fetchGroupFeatureFlag(Application.EAQ, FEATURE_FLAG.IS_NCLEX_SELF_STUDY_OPTION_ENABLED, isbn)
        .catch(() => {
          return null;
        }),
      fetchStudentTopicPerformances(isbn)
    ])
      .then((response) => {

        const [authessFeatureDto, topicPerformances] = response;

        mergeState({ authessFeatureDto, topicPerformances });

        return ({ taxonomyTopicsMap: topicPerformances });
      })
      .then((response) => {
        const { taxonomyTopicsMap } = response;
        mergeState({ taxonomyTopicsMap });
      });
  };

  const handleQuestionSetChange = (isbn: string) => {
    mergeState({
      selectedQuestionSetIsbn: isbn,
      maxQuestionsCount: null,
      assessment: {} as AssessmentDto,
      activeTopicOrgVtwId: null
    });

    eaqTaxonomy(isbn, BookTaxonomyKindDto.PRIMARY)
      .then(
        (taxonomies) => {
          mergeState({ eaqTaxonomyDto: taxonomies });
          mergeAssessment({
            type: AssessmentTypeDto.MASTERY,
            assessmentGoals: [],
            assessmentTopics: [],
            studyMode: true,
            isbn,
            courseSectionId: (props.course) ? props.course.id : null,
          });
        }
      );

    return fetchTaxonomyAndTopics(isbn);
  };

  const handleAuthessAssignmentChange = (authessAssignment: AuthessAssignmentDto): void => {
    mergeAssessment({
      assessmentGoals: [{
        goal: authessAssignment.numberOfQuestions,
        text: authessAssignment.title,
        vtwId: authessAssignment.key,
      }],
      assessmentTopics: [{
        assessmentTopicLevelUps: null,
        text: authessAssignment.title,
        vtwId: authessAssignment.key,
      }]
    });
  };

  const handleTopicOrgChange = (vtwId: string) => {
    mergeState({ activeTopicOrgVtwId: vtwId });
  };

  const handleMasteryTopicChange = (row: TopicPerformanceTableRow): void => {
    mergeAssessment({
      assessmentGoals: [{
        goal: null,
        ...state.assessment.assessmentGoals[0],
        text: row.topicName,
        vtwId: row.topic.item.vtwId,
      }],
      assessmentTopics: [{
        assessmentTopicLevelUps: null,
        text: row.topicName,
        vtwId: row.topic.item.vtwId,
      }]
    });
  };

  const handleMasteryGoalChange = (selectedGoal: string): void => {
    const { assessment } = state;
    const goal = selectedGoal === SELECT_OPTION.value ? null : parseInt(selectedGoal, 10);

    mergeAssessment({
      ...assessment,
      assessmentGoals: [{
        ...assessment.assessmentGoals[0],
        goal
      }]
    });
  };

  const handleStandardTopicChange = (row: TopicPerformanceTableRow) => {
    setState((prevState) => {
      const prevAssessment = prevState.assessment || {} as AssessmentDto;
      let assessmentTopics = prevAssessment.assessmentTopics || [];
      const isSelected = isTopicChecked(row, prevState.assessment);

      if (isSelected) {
        assessmentTopics = assessmentTopics.filter((item) => {
          return item.vtwId !== row.topic.item.vtwId;
        });
      } else {
        assessmentTopics.push({
          assessmentTopicLevelUps: null,
          text: row.topicName,
          vtwId: row.topic.item.vtwId,
        });
      }

      mergeAssessment({
        assessmentTopics
      });

      return {
        ...prevState,
        maxQuestionsCount: null,
        assessment: {
          ...prevAssessment,
          assessmentTopics
        }
      };
    });
  };

  useEffect(() => {
    const {
      course,
      userId
    } = props;

    const courseId = course ? course.id.toString() : null;
    const courseEntitlements: EntitlementDto[] = course && course.entitlements ? course.entitlements
      .filter(x =>
        x.type === EvolveProductType.EPRODUCT.toUpperCase() && EAQ_PRODUCT_TYPES.includes(x.evolveProductTypeKey))
      : null;

    fetchIsbns(courseId, userId).then(
      (questionSets) => {
        const currentCourseQuestionSets: CourseISBNDto[] = courseEntitlements ? questionSets
          .filter(set => courseEntitlements.some(x => x.isbn === set.isbn)) : null;

        // * - Will show all ISBNs when student is using direct access since there is no course id
        const questionSetArr = courseId ? currentCourseQuestionSets : questionSets;
        mergeState({ questionSets: questionSetArr });
        if (questionSetArr && questionSetArr.length) {
          eaqTaxonomy(questionSetArr[0].isbn, BookTaxonomyKindDto.PRIMARY)
            .then(
              (taxonomies) => {
                mergeState({ eaqTaxonomyDto: taxonomies });
                mergeAssessment({
                  type: AssessmentTypeDto.MASTERY,
                  assessmentGoals: [],
                  assessmentTopics: [],
                  studyMode: true,
                  isbn: questionSetArr[0].isbn,
                  courseSectionId: (props.course) ? props.course.id : null,
                });
              }
            );
          return fetchTaxonomyAndTopics(questionSetArr[0].isbn);
        }
        return Promise.resolve(null);
      }
    );
  }, []);

  const sharedRadioProps = {
    size: 'large',
    name: 'EAQ_ASSESSMENT_TYPE',
    isDisabled: false,
    changeHandler: (e, value) => {
      mergeAssessment({
        type: value,
        assessmentGoals: [],
        assessmentTopics: [],
        studyMode: true,
        isbn: state.selectedQuestionSetIsbn,
        courseSectionId: (props.course) ? props.course.id : null,
      });
      mergeState({
        maxQuestionsCount: null,
        eaqTaxonomyDto: null,
        activeTopicOrgVtwId: ALL_OPTION.value as string
      });
      if (value === AssessmentTypeDto.AUTHESS) {
        fetchAuthessAssignments(state.selectedQuestionSetIsbn)
          .then(
            (assignments) => {
              mergeState({ authessAssignmentsDto: assignments });
            }
          );
      }
      if (value === AssessmentTypeDto.MASTERY) {
        eaqTaxonomy(state.selectedQuestionSetIsbn, BookTaxonomyKindDto.PRIMARY)
          .then(
            (taxonomies) => {
              mergeState({ eaqTaxonomyDto: taxonomies });
            }
          );
      }
      if (value === AssessmentTypeDto.STANDARD) {
        eaqTaxonomy(state.selectedQuestionSetIsbn)
          .then(
            (taxonomies) => {
              mergeState({ eaqTaxonomyDto: taxonomies });
            }
          );
      }
    }
  };

  const handlePageClose = () => {
    const {
      appLinkData,
      returnAppLink,
      appLinkCookies,
    } = props;

    const noAppLinkApps = [
      Application.EAQ,
      Application.SHER_EVOL,
      Application.SHERPATH,
      Application.STUDENT_STUDY
    ];

    if (noAppLinkApps.includes(appLinkData.srcApp)) {
      history.back();
    } else {
      returnAppLink({
        linkId: getParentLinkId(appLinkData, appLinkCookies),
        returnPostBody: null
      });
    }
  };

  const {
    messages,
    modalService
  } = props;

  const flattenedTopics: TopicWithLineage[] = flattenedAssessmentEditorTopics(state.eaqTaxonomyDto, state.topicPerformances, state.activeTopicOrgVtwId);
  const tableRows: TopicPerformanceTableRow[] = getTopicTableRows(flattenedTopics);

  const navigateToQuizPlayer = (assessmentConfig: EaqAssessmentConfig): Promise<void> | void => {
    const { navigateToApp, featureFlagsGrouped, courseSectionId, appLinkData, appLinkCookies } = props;
    const isEaqAssessmentPlayerRedirect: boolean = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
      featureFlagsGrouped, FEATURE_FLAG.EAQ_ASSESSMENT_PLAYER_REDIRECT, courseSectionId
    );

    if (isEaqAssessmentPlayerRedirect) {
      return createAssessment(assessmentConfig).then((assessmentRes: AssessmentDto) => {
        const parentLinkId = getParentLinkId(appLinkData, appLinkCookies);
        return navigateToAssessmentPlayer(navigateToApp, parentLinkId, assessmentRes.id);
      });
    }

    return navigateToApp({
      includeLinkHash: true,
      altSrcApp: Application.STUDENT_STUDY,
      action: AppAction.ASSESSMENT_START,
      app: Application.EAQ,
      body: {
        assessmentConfig: state.assessment,
        isSelfStudy: true,
      },
      parentLinkId: getParentLinkId(appLinkData, appLinkCookies)
    });
  };

  const handleStartQuizClick = () => {
    const { assessment } = state;
    const { courseSectionId, appLinkData, appLinkCookies, navigateToApp } = props;
    const assessmentConfig: EaqAssessmentConfig = {
      assessmentGoals: assessment.assessmentGoals,
      assessmentTopics: assessment.assessmentTopics,
      isbn: state.selectedQuestionSetIsbn,
      studyMode: true,
      type: assessment.type,
      courseSectionId: Number(courseSectionId) || null
    };

    if (!validateAssessment(state)) {
      return;
    }

    validateNumberOfQuestions().then((isValid: boolean) => {
      if (!isValid) {
        return;
      }

      if (assessment.type === AssessmentTypeDto.AUTHESS) {
        createAssessment(assessmentConfig).then((response: AssessmentDto) => {
          navigateToApp({
            includeLinkHash: true,
            altSrcApp: Application.STUDENT_STUDY,
            action: AppAction.ASSESSMENT_START,
            app: Application.AUTHESS,
            body: {
              assessmentId: response.id
            },
            parentLinkId: getParentLinkId(appLinkData, appLinkCookies),
          });
        });
      } else {
        navigateToQuizPlayer(assessmentConfig);
      }
    });
  };

  return (
    <div className="c-ssa-self-study-assessment-editor-page">
      <header className="c-ssa-self-study-assessment-editor-page__header">
        <ELSPageHeader
          onCloseClick={handlePageClose}
          title={constants.eaqSelfStudyAssessmentCreator}
        />
      </header>
      <div className="c-ssa-self-study-assessment-editor-page__body">
        <div className="c-ssa-self-study-assessment-editor-page__body-content">

          <div className="o-els-container o-els-container--2x">
            <h3 className="o-els-container o-els-container--1o2">{messages.QUESTION_SET}</h3>

            <ELSDropDown
              options={questionSetOptions(state)}
              value={getSelectedQuestionSetIsbn(state.selectedQuestionSetIsbn)}
              changeHandler={(e, value: string) => handleQuestionSetChange(value)}
             >
              <span>{messages.SELECT_A_QUESTION_SET}</span>
            </ELSDropDown>

            <div className="o-els-container o-els-container--2x">
              <div className="o-els-container">
                <ELSRadio
                  {...sharedRadioProps}
                  id={`assignment-type-option-${AssessmentTypeDto.MASTERY}`}
                  value={AssessmentTypeDto.MASTERY}
                  checked={state.assessment.type === AssessmentTypeDto.MASTERY}
                >
                  {messages.MASTERY}
                </ELSRadio>
                <p className="o-els-container o-els-container--1o2">{messages.UNLIMITED_QUESTIONS_UNTIL_YOU_LEVEL_UP}</p>
              </div>

              <div className="o-els-container">
                <ELSRadio
                  {...sharedRadioProps}
                  id={`assignment-type-option-${AssessmentTypeDto.STANDARD}`}
                  value={AssessmentTypeDto.STANDARD}
                  checked={state.assessment.type === AssessmentTypeDto.STANDARD}
                >
                  {messages.CUSTOM}
                </ELSRadio>
                <p className="o-els-container o-els-container--1o2">{messages.CHOOSE_A_FINITE_NUMBER_OF_QUESTIONS}</p>
              </div>

              <IsRender isRender={showAuthessCaseStudyAssessmentType(state.authessFeatureDto)}>
                <div className="o-els-container">
                  <ELSRadio
                    {...sharedRadioProps}
                    id={`assignment-type-option-${AssessmentTypeDto.AUTHESS}`}
                    value={AssessmentTypeDto.AUTHESS}
                    checked={state.assessment.type === AssessmentTypeDto.AUTHESS}
                  >
                    {messages.CASES_ITEMS_NEXT_GENERATION}
                  </ELSRadio>
                  <p className="o-els-container o-els-container--1o2">{messages.SET_OF_NGN_ITEMS_ALIGNED_TO_CLINICAL_CONTENT}</p>
                </div>
              </IsRender>
            </div>
            <IsRender isRender={[AssessmentTypeDto.MASTERY, AssessmentTypeDto.STANDARD].includes(state.assessment.type)}>
              <div>
                <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                  {state.topicPerformances
                    && (
                      <>
                        <h4>{messages.TOPIC_FILTER}</h4>
                        <div className="o-els-container">
                          <ELSDropDown
                            value={state.activeTopicOrgVtwId}
                            options={getTopicOrgFilterOptions(state.eaqTaxonomyDto)}
                            isDisabled={!state.topicPerformances || !state.topicPerformances.length}
                            changeHandler={(e, value) => handleTopicOrgChange(value)}
                          />
                        </div>
                      </>
                    )}
                </FlexItem>
              </div>
              <div className="o-els-container o-els-container--2x">
                {tableRows && (
                  <ELSDataTable
                    data={tableRows}
                    noWrap
                    defaultSortField="lineageDisplayOrder"
                    id="topic-performance-table"
                    defaultSortDirection="asc"
                    sortIconSize="1o2">
                    <column
                      field="selection"
                      header=" "
                      sortable={false}
                      customRender={
                        (row: TopicPerformanceTableRow) => {
                          if (state.assessment.type === AssessmentTypeDto.STANDARD) {
                            return (
                              <ELSCheckBox
                                name="topic"
                                changeHandler={() => handleStandardTopicChange(row)}
                                checked={isTopicChecked(row, state.assessment)}
                              />
                            );
                          }
                          return (
                            <ELSRadio
                                changeHandler={() => {
                                  handleMasteryTopicChange(row);
                                }}
                                checked={isTopicChecked(row, state.assessment)}
                                name="topic"
                                value={row.topic.item.vtwId}
                              >
                              {null}
                            </ELSRadio>
                          );
                        }
                      }
                    />
                    <column
                      field="lineageDisplay"
                      sortField="lineageDisplayOrder"
                      header={messages.TOPIC}
                      sortable
                    />
                    <column
                      sortField="masteryLevel"
                      header={messages.MASTERY_LEVEL}
                      sortable
                      customRender={(item: TopicPerformanceTableRow) => {
                        return (
                          <button
                            type="button"
                            className="u-els-debuttonize"
                            onClick={() => {
                              handleExplainerClick({
                                messages,
                                modalService
                              });
                            }}
                          >
                            <div className="u-ssa-min-width-2x">
                              <MasteryChart scoreAsPercentage={convertMasteryScoreToPercentage(item.masteryLevel)} />
                            </div>
                          </button>
                        );
                      }}
                    />
                    <column field="questionsAnswered" header={messages.QUESTIONS_ANSWERED} sortable />
                    <column field="questionsCorrect" header={messages.QUESTIONS_CORRECT} sortable />
                    <column
                      sortField="percentCorrect"
                      header={messages.CORRECT_PERCENTAGE}
                      sortable
                      customRender={(item: TopicPerformanceTableRow) => {
                        return `${item.percentCorrect}%`;
                      }}
                    />
                    <column field="questionsConfident" header={messages.QUESTIONS_CONFIDENT} sortable />
                    <column
                      field="timeSpentDisplay"
                      sortField="timeSpent"
                      header={messages.TOTAL_TIME}
                      sortable
                    />
                  </ELSDataTable>
                )}
              </div>
            </IsRender>

            <IsRender isRender={state.assessment.type === AssessmentTypeDto.AUTHESS}>
              <div className="o-els-container o-els-container--2x">
                {
                  state.authessAssignmentsDto && state.authessAssignmentsDto.length && (
                    state.authessAssignmentsDto.map((authessAssignment) => {
                      return (
                        <ELSRadio
                          key={authessAssignment.id}
                          changeHandler={() => handleAuthessAssignmentChange(authessAssignment)}
                          name="authessAssignment"
                          id={`authess-assignment-option-${authessAssignment.key}`}
                          value={authessAssignment.key}
                          checked={isAuthessRadioChecked(authessAssignment, state)}
                        >
                          <div className="o-els-container o-els-container--1o2x">
                            {authessAssignment.title}
                          </div>
                        </ELSRadio>
                      );
                    })
                  )
                }

              </div>
            </IsRender>
          </div>
        </div>
      </div>
      <div className="c-ssa-self-study-assessment-editor-page__footer">
        <div className="c-ssa-self-study-assessment-editor-page__footer-content">
          <FlexLayout modifiers={[
            FlexLayoutModifier.RIGHT,
            FlexLayoutModifier.BOTTOM,
            FlexLayoutModifier.GUTTERS,
            FlexLayoutModifier.WRAP_AT_MOBILE
          ]}>
            <FlexItem isRender={state.assessment.type === AssessmentTypeDto.STANDARD}
                      classes={['u-els-width-1o1@mobile', 'u-els-margin-bottom@mobile']}
            >
              <NumberOfQuestions
                assessment={state.assessment}
                onAssessmentChange={(assessment: AssessmentDto) => {
                  mergeAssessment(assessment);
                }}
                maxNumber={state.maxQuestionsCount}
              />
            </FlexItem>

            <FlexItem isRender={state.assessment.type === AssessmentTypeDto.MASTERY}
                      classes={['u-els-width-1o1@mobile', 'u-els-margin-bottom@mobile']}
            >
              <SelectMasteryGoal
                handleMasteryGoalChange={handleMasteryGoalChange}
                flattenedTopics={flattenTreeWithLineage(state.topicPerformances)}
                state={state}
              />
            </FlexItem>
            <FlexItem modifiers={[FlexLayoutModifier.MIDDLE]}>
              <ELSButton
                onClick={handleStartQuizClick}
                isDisabled={!validateAssessment(state)}
              >
                {messages.START_QUIZ}
              </ELSButton>
            </FlexItem>
          </FlexLayout>
        </div>

      </div>
    </div>
  );
};

const enhancers = [
  withHTMLHeadSEO({ title: constants.eaqStudentStudyProgressReportPageTitle }),
  connector,
  ELSWithModalService
];

const EaqSelfStudyAssessmentEditor = compose<null, EaqSelfStudyAssessmentEditorProps>(...enhancers)(EaqSelfStudyAssessmentEditorComponent);

export default EaqSelfStudyAssessmentEditor;
